import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
          light: {
            primary: '#00bc70',
            secondary: '#004a98',
            accent: '#66e9f1',
            circaLightGray: '#E6E7E8',
            circaMediumGray: '#939598',
          },
        },
      },
  })
