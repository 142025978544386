<template>
  <div>
    <v-app v-if="isAuthenticated && getSelectedCompany">
      <v-overlay v-if="this.isRedirectingToOriginal">
        <v-progress-circular indeterminate size="64"
          >Redirecting</v-progress-circular
        >
      </v-overlay>
      <v-navigation-drawer
        app
        clipped
        fixed
        v-model="drawer.isOpen"
        width="310"
      >
        <v-list class="mt-n1">
          <template v-for="entry in routes()">
            <v-list-item
              v-if="!entry.children && entry.meta.show && !entry.meta.external"
              :key="entry.name"
              :to="entry.path"
              @click="toggleMenuItemExpand(entry.name, false)"
            >
              <v-list-item-action style="margin-right: 18px">
                <v-icon v-if="entry.meta">{{ entry.meta.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left font-weight-bold"
                  v-text="entry.name"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!entry.children && entry.meta.show && entry.meta.external"
              :key="entry.name"
              :href="entry.meta.external"
              target="_blank"
            >
              <v-list-item-action style="margin-right: 18px">
                <v-icon v-if="entry.meta">{{ entry.meta.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left font-weight-bold"
                  v-text="entry.name"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              class="top-menu-item"
              v-if="entry.children && drawRoutes(entry)"
              :key="entry.name"
              :value="entry.meta.isOpen"
              @click="toggleMenuItemExpand(entry.name, true)"
            >
              <v-icon slot="prependIcon">{{ entry.meta.icon }}</v-icon>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-left font-weight-bold"
                    v-text="entry.name"
                  />
                </v-list-item-content>
              </template>
              <template v-for="sub in entry.children">
                <v-list-item
                  :key="sub.name"
                  :to="sub"
                  v-if="drawRoutes(sub) && !sub.meta.external"
                  router
                  exact-path
                >
                  <v-list-item-content class="ml-13">
                    <v-list-item-title
                      class="text-left font-weight-bold"
                      v-text="sub.name"
                    />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="sub.icon">{{ sub.icon }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  :key="sub.name"
                  :href="sub.meta.external"
                  target="_blank"
                  v-if="sub.meta.show && sub.meta.external"
                >
                  <v-list-item-content class="ml-13">
                    <v-list-item-title
                      class="text-left font-weight-bold"
                      v-text="sub.name"
                    />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon small v-if="sub.meta.icon">{{
                      sub.meta.icon
                    }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list>
        <template v-slot:append v-if="!menuItem.expanded">
          <v-card color="secondary">
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-avatar class="ml-6 mt-2" size="45" color="white">
                    <v-icon large>
                      {{ profileMenu.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="text-left">
                  <v-card-title class="white--text pa-0 my-n2 contact-title">
                    {{
                      getSelectedCompany.amName
                        ? "Account Manager"
                        : "Contact Us"
                    }}
                  </v-card-title>
                  <v-card-subtitle
                    v-if="getSelectedCompany.amName"
                    class="white--text contact-info pa-0 my-1"
                  >
                    {{ getSelectedCompany.amName }}
                  </v-card-subtitle>
                  <v-card-subtitle
                    class="white--text contact-info pa-0 mt-n2 mb-1"
                  >
                    {{ getDefaultAMContactNum }}
                  </v-card-subtitle>
                  <v-btn
                    rounded
                    class="text-capitalize white--text mb-3 justify-start"
                    color="circaMediumGray"
                    :href="
                      'mailto:' +
                      (getSelectedCompany.amEmail
                        ? getSelectedCompany.amEmail
                        : getDefaultAMEmail)
                    "
                  >
                    <v-icon class="mr-1">
                      {{ icons.email }}
                    </v-icon>
                    Email Me
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>
      </v-navigation-drawer>
      <v-app-bar app clipped-left>
        <v-app-bar-nav-icon
          aria-label="navigation drawer"
          :aria-expanded="drawer.isOpen"
          @click.stop="drawer.isOpen = !drawer.isOpen"
        ></v-app-bar-nav-icon>
        <v-img
          src="~@/assets/logo.png"
          max-height="60"
          max-width="80"
          contain
        ></v-img>
        <v-spacer />
        <v-row justify="end" class="d-none d-md-flex">
          <div align-self="end" class="mr-5">
            <div class="font-weight-bold" v-if="fullName">
              {{ fullName }}
            </div>
            <div v-if="getSelectedCompany" class="mt-n2 font-weight-bold">
              {{ getSelectedCompanyName }}
            </div>
          </div>
        </v-row>
        <v-menu offset-y right nudge-bottom="10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              aria-label="profile menu"
              v-bind="attrs"
              v-on="on"
              height="40"
              width="40"
              color="primary"
              fab
              ><v-icon>{{ profileMenu.icon }}</v-icon></v-btn
            >
          </template>
          <v-list class="profile-menu">
            <v-list-item
              @click="showChangeCompanyModal = true"
              v-if="getCompanies && getCompanies.length > 1"
            >
              <v-list-item-title
                id="change-company"
                class="text-left custom-transform-class text-none"
                >Change Company</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="handleMyProfileClick">
              <v-list-item-title
                class="text-left custom-transform-class text-none"
                >My Profile</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/notification/preferences" router exact-path>
              <v-list-item-title
                class="text-left custom-transform-class text-none"
                >Notification Preferences</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="userLogOut">
              <v-list-item-title
                class="text-left custom-transform-class text-none"
                >Log Out</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <notification-menu></notification-menu>
        <a id="notificationMenuAnchor" />
      </v-app-bar>
      <v-main>
        <v-container id="app-container" fluid>
          <router-view />
        </v-container>
        <br />
      </v-main>
      <v-footer app>
        <v-container fluid>
          <v-row justify="space-between" no-gutters>
            <v-col sm="6" md="5" lg="3" xl="3">
              <v-btn
                x-small
                plain
                href="https://circaworks.com/terms-of-use/"
                target="_blank"
                >Terms of Use</v-btn
              >
              <v-btn
                x-small
                plain
                href="https://www.circaworks.com/privacy-policy"
                target="_blank"
                >Privacy Policy</v-btn
              >
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                x-small
                plain
                href="https://www.circaworks.com/accessibility"
                target="_blank"
                >Accessibility</v-btn
              >
            </v-col>

            <v-col lg="5" xl="4" v-if="$vuetify.breakpoint.mdAndUp">
              <v-btn
                x-small
                class="text-left  pb-2"
                plain
                href="https://circaworks.com/legal"
                target="_blank"
                >Copyright © 1994-{{ new Date().getFullYear() }}
                All Rights Reserved
              </v-btn>
              <v-divider vertical></v-divider>
              <v-btn
                x-small
                class="text-left pb-2"
                plain
                href="https://mitratech.com/"
                target="_blank">
                &nbsp;
                <img src="~@/assets/Mitrabadge.svg"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

      </v-footer>
      <change-company-modal
        v-model="showChangeCompanyModal"
        @close="showChangeCompanyModal = false"
      ></change-company-modal>
      <user-modal
        v-model="showEditUserModal"
        :user="currentUserToEdit"
        @close="showEditUserModal = false"
      ></user-modal>
      <app-messages></app-messages>
    </v-app>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mdiAccount, mdiEmailOutline, mdiPhone } from "@mdi/js";
import _ from "lodash";
import { appcues_account_id, heap_account_id } from "@/config/app-config";

import ChangeCompanyModal from "./components/modals/ChangeCompanyModal.vue";
import UserModal from "./components/Account/Users/UserModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner";
import AppMessages from "@/components/AppMessages";
import NotificationMenu from "@/components/Notifications/NotificationMenu";
import UserRoles from "@/components/Account/Users/UserRoles";
import { addHeapToApp, getProductAuthsForAnalytics } from "@/utilities";

export default {
  components: {
    ChangeCompanyModal,
    UserModal,
    LoadingSpinner,
    AppMessages,
    NotificationMenu,
  },
  data() {
    return {
      drawer: {
        isOpen: true,
      },
      menuItem: {
        expanded: false,
        name: undefined,
      },
      icons: {
        email: mdiEmailOutline,
        phone: mdiPhone,
      },
      profileMenu: {
        isOpen: false,
        icon: mdiAccount,
        items: [
          {
            title: "Change Company",
            to: "/company",
          },
          {
            title: "Logout",
            action: "userLogOut",
          },
        ],
      },
      isRedirectingToOriginal: false,
      showChangeCompanyModal: false,
      showEditUserModal: false,
      currentUserToEdit: null,
      fullName: undefined,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "getUserId",
      "getUserFirstName",
      "getUserLastName",
      "getUserFullName",
      "getUserEmail",
      "getCompanies",
      "getSelectedCompany",
      "getSelectedCompanyName",
      "getDefaultAMEmail",
      "getDefaultAMContactNum",
      "getFeatureFlag",
      "getUser",
      "getImpersonating",
    ]),
  },
  methods: {
    ...mapActions("auth", [
      "fetchCompanies",
      "userLogOut",
      "fetchQLoginLink",
      "recordUserActivity",
    ]),
    ...mapActions("users", ["fetchLoggedInUserComp"]),
    async handleMyProfileClick() {
      this.loggedInUserInfo = await this.fetchLoggedInUserComp();
      this.currentUserToEdit = {
        id: this.getUserId,
        firstName: this.loggedInUserInfo.firstName,
        lastName: this.loggedInUserInfo.lastName,
        email: this.loggedInUserInfo.emailAddress,
        userRole: this.loggedInUserInfo.userRole,
      };
      this.showEditUserModal = true;
    },
    ...mapMutations("app-messages", ["displayErrorMessage"]),
    openMenuItem(name) {
      for (let i = 0; i < this.routes().length; i++) {
        this.$router.options.routes[i].meta.isOpen = false;
        const index = _.findIndex(
          this.$router.options.routes[i].children,
          (m) => {
            return m.name === name;
          }
        );

        if (index !== -1) {
          this.$router.options.routes[i].meta.isOpen = true;
          this.menuItem.expanded = true;
          this.menuItem.name = this.$router.options.routes[i].name;
        }
      }
    },
    drawRoutes(route) {
      if ("redirectWithQLogin" in route.meta) {
        route.meta.redirectWithQLogin().then((resp) => {
          route.meta.external = resp;
        });
      }
      if ("featureFlag" in route.meta) {
        if (!route.meta.hidden) {
          let feature = route.meta.featureFlag;
          route.meta.show = this.getFeatureFlag(feature);
        }
      }
      return route.meta.show;
    },
    routes() {
      return this.$router.options.routes;
    },
    toggleMenuItemExpand(menuItemName, isGroup) {
      if (isGroup) {
        if (this.menuItem.name === undefined) {
          this.menuItem.expanded = !this.menuItem.expanded;
          this.menuItem.name = menuItemName;
        } else {
          //if it's the same one, we're closing
          //else, we're opening a new one
          if (this.menuItem.name === menuItemName) {
            this.menuItem.expanded = !this.menuItem.expanded;
            this.menuItem.name = menuItemName;
          } else {
            this.menuItem.expanded = true;
            this.menuItem.name = menuItemName;
          }
        }
      } else {
        this.menuItem.expanded = false;
        this.menuItem.name = menuItemName;
      }
    },
    formatMailTo() {
      let email = this.getSelectedCompany.amEmail
        ? this.getSelectedCompany.amEmail
        : this.getDefaultAMEmail;
      return "mailto:" + email;
    },
    async handleRedirectToOriginal() {
      try {
        this.isRedirectingToOriginal = true;

        //track when we redirected
        const route = this.$route.meta.localSitesRoute ?? "account";

        //retrieve qlogin
        const qLogin = await this.fetchQLoginLink({
          returnUrl: route,
          isEmbedded: false,
        });

        if (qLogin) {
          //call api and track redirect
          await this.recordUserActivity(route).then(() => {
            window.location.href = qLogin;
          });
        } else {
          this.displayErrorMessage("Error Occurred Trying To Redirect");
        }
      } catch {
        this.displayErrorMessage("Error Occurred Trying To Redirect");
      } finally {
        this.isRedirectingToOriginal = false;
      }
    },
    getProductAuthorizations(selectedCompany) {
      let hasOFCCP = false;
      let prodAuths = [];

      selectedCompany.productAuthorizations.forEach((a) => {
        if (a.name.includes("OFCCP")) {
          hasOFCCP = true;
        } else {
          prodAuths.push(a.name);
        }
      });

      if (hasOFCCP) {
        prodAuths.push("OFCCP");
      }

      var prodAuthList = "";
      prodAuths.forEach((a) => {
        prodAuthList = prodAuthList + a + " ";
      });
      return prodAuthList;
    },
  },
  mounted() {
    if (appcues_account_id) {
      let appcuesScript = document.createElement("script");
      appcuesScript.setAttribute(
        "src",
        `//fast.appcues.com/${appcues_account_id}.js`
      );
      document.head.appendChild(appcuesScript);
    }
    if (heap_account_id && !this.getImpersonating) {
      addHeapToApp();
      window.heap?.load(heap_account_id, { secureCookie: true });
    }
  },
  watch: {
    $route(to) {
      this.openMenuItem(to.name);
    },
    async getSelectedCompany(company) {
      this.openMenuItem(this.$route.name);
      //send data to identify data to heal; only send data for non impersonated users
      if (heap_account_id && !this.getImpersonating) {
        const userRole = UserRoles.find((u) => u.value == company.userRoleId);
        const prodAuths = getProductAuthsForAnalytics(this.getSelectedCompany);
        var user = this.getUser;
        window.heap.track("attempting_identify", {
          heap_id: heap_account_id,
          isImpersonating: this.getImpersonating,
        });
        try {
          window.heap.identify(
            `${this.getUserId.toUpperCase()}:${company.id.toUpperCase()}`
          );
          window.heap.track("identified", {
            heap_id: heap_account_id,
            isImpersonating: this.getImpersonating,
          });
        } catch (error) {
          window.heap.track("identify_failed", {
            identity_value: user.id,
            error_message: error,
          });
        }

        window.heap.addUserProperties({
          productAuthorizations: prodAuths,
          amsId: company.legacyId,
          userRole: userRole.text,
          salesforceId: company.salesforceId,
          email: this.getUserEmail,
          name: user.profile.first_name + " " + user.profile.last_name,
        });
      }
    },
    getUserFullName(val) {
      if (val) {
        this.fullName = val;
      }
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-position: left 5% bottom, right 13% top 18%;
  background-repeat: no-repeat, no-repeat;
  background-size: auto 50%;
}

.v-main__wrap {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.top-menu-item .v-list-group__header .v-list-group__header__prepend-icon {
  margin-right: 18px;
}

#app-container {
  height: 100%;
}

.v-list-item__title {
  white-space: normal;
}

.list__tile--active .list__tile__action,
.list__tile--active .list__tile__action .icon {
  color: inherit;
}

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) .v-icon {
  color: #004a98;
}

.list__tile__action,
.list__tile__action .icon {
  color: #004a98;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.container {
  padding: 0;
}

.profile-menu .v-list-item {
  display: block;
  padding-top: 15px;
}

.contact-title {
  font-size: 1rem !important;
}

.contact-info {
  color: white;
  white-space: normal;
  font-size: 0.925rem !important;
}

#copyright {
  font-size: 0.625rem !important;
  opacity: 0.62;
}

#feedback-link {
  text-align: left;
  height: 30px;
  position: fixed;
  bottom: 30px;
  background-color: #d0e7f9;
}

.feedback-link-text-drawer-open {
  /* The size of the drawer*/
  padding-right: 310px;
}
</style>
