export const isProduction = true;
export const authority = 'https://auth.circaworks.com';
export const client_id = 'employer-portal';
export const redirect_uri = 'https://diversitycatalyst.circaworks.com/auth-callback';
export const response_type = 'code';
export const scope = 'openid profile';
export const baseEmployerApiUrl = 'https://employer-api.circaworks.com';
export const baseOutreachServiceApiUrl = "https://gateway.oms.localjobnetwork.com";
export const post_logout_redirect_uri = "https://diversitycatalyst.circaworks.com";
export const diversitycatalyst_uri = "https://diversitycatalyst.circaworks.com";
export const silent_redirect_uri = "https://diversitycatalyst.circaworks.com/silent-refresh";
export const apm_server_uri = "https://apm.circaworks.com/";
export const apm_service_name = "Employer Portal SPA Production";
export const default_am_email = "Info@circaworks.com";
export const default_am_contact_number = "800.984.3775";
export const am_default_email = "customersupport@circaworks.com";
export const am_default_contact_number = "800.984.3775";
export const gtag_id = "G-9WSJDTG9WP";
export const hotjar_id = "2582879";
export const appcues_account_id = 103681;
export const heap_account_id = 3470560920;
export const diversity_sourcing_uri = 'https://api.ai4jobs.info/twg/api/v1/circa/login';
export const displayJobPerformanceReport = true;
export const displayVersaPayOrders = true;
export const external_iframe_link_help = "https://circaworks.com/education/";
export const oms_base_url = 'https://oms-employer.circaworks.com';