<template>
  <v-container fluid fill-height id="iframe-container">
    <iframe
      v-if="iframeSrc"
      ref="iframe"
      width="100%"
      :src="iframeSrc"
      frameborder="0"
      v-iframe-resize="{
        log: false,
        checkOrigin: false,
      }"
      @load="onIFrameLoad"
    ></iframe>
    <loading-spinner v-else></loading-spinner>
  </v-container>
</template>

<script>
import iframeResize from "iframe-resizer/js/iframeResizer";
import LoadingSpinner from "@/components/LoadingSpinner";
import {
  modifyReturnURLWithParamsOnLoad,
  getLocalSitesRouteInfoFromEvent,
} from "@/router/iframe-utilities";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: { LoadingSpinner },
  props: ["preventIframeReload"],
  directives: {
    "iframe-resize": {
      bind(el, binding) {
        iframeResize(binding.value, el);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["fetchQLoginLink"]),
    onIFrameLoad() {
      window.parent.scrollTo(0, 0);
      this.sendHeapIdentifyMessage();
      return;
    },
    getObjFromSearchParam(searchParams) {
      const paramsObj = {};
      for (const [key, value] of searchParams) {
        paramsObj[key] = value;
      }
      return paramsObj;
    },
    handleIFrameUrlUpdate(event) {
      if (typeof event.data !== "string") {
        return;
      }

      //these events have nothing to do with routing
      if (event.data.includes("iFrameSizer")) {
        return;
      }

      const { matchingLocalSitesRoute, iFrameQueryParams } =
        getLocalSitesRouteInfoFromEvent(event.data, this.$router);

      if (matchingLocalSitesRoute) {
        const routerParams = {
          preventIframeReload: true,
        };

        if (iFrameQueryParams) {
          const catalystQueryParams = new URLSearchParams(
            this.$route.query
          ).toString();

          //if, for some reason, the query params match, don't push as to not pollute the history unecessarily
          if (
            iFrameQueryParams.toLowerCase() != catalystQueryParams.toLowerCase()
          ) {
            this.$router
              .push({
                name: matchingLocalSitesRoute.name,
                params: routerParams,
                query: this.getObjFromSearchParam(
                  new URLSearchParams(iFrameQueryParams)
                ),
              })
              .catch((err) => {
                //when we click search in forms, we are navigating to the same route but with new params
                //since we're updating the router, it will throw an error saying we pushed to the same route so we are just supressing that error
                if (
                  err.name == "NavigationDuplicated" &&
                  err.message.includes(
                    "Avoided redundant navigation to current location"
                  )
                ) {
                  //do nothing
                }
              });
          }
        } else if (matchingLocalSitesRoute.name != this.$route.name) {
          this.$router.push({
            name: matchingLocalSitesRoute.name,
            params: routerParams,
          });
        }
      }
    },
    async setIFrameSrc() {
      const routeHasParams = !_.isEmpty(this.$route.query);
      let returnUrl = this.$route.meta.localSitesRoute;

      if (routeHasParams) {
        const queryParams = new URLSearchParams(this.$route.query).toString();
        returnUrl = modifyReturnURLWithParamsOnLoad(returnUrl, queryParams);
      }

      this.iframeSrc = await this.fetchQLoginLink({
        returnUrl: encodeURIComponent(returnUrl),
        isEmbedded: true,
      });
    },
    sendHeapIdentifyMessage() {
      let selectedCompany = this.getSelectedCompany;
      let userId = this.getUserId;
      const message = { type: 'heap_identify', payload: `${userId.toUpperCase()}:${selectedCompany.id.toUpperCase()}` };
      try {
        this.$refs.iframe.contentWindow.postMessage(message, this.iframeSrc);
      } catch (error) {
        window.heap.track('legacy_identify_failed', {
          identity_value: `${userId.toUpperCase()}:${selectedCompany.id.toUpperCase()}`,
          error_message: error,
        });
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "getCompanies",
      "getSelectedCompany",
      "getUserFullName",
      "getUserId"
    ]),
    ...mapGetters("users", ["getLoggedInUserUpdated"]),
  },
  created: async function () {
    const sevenHours = 25200000;
    //forces a page refresh to get a new  cookie on the legacy site if the user is idled on the same page for 8 hours
    setTimeout(() => {
      location.reload();
    }, sevenHours);

    await this.setIFrameSrc();

    window.addEventListener("message", this.handleIFrameUrlUpdate);
  },
  watch: {
    async getCompanies(newValue) {
      if (this.iframeSrc == "" && newValue && newValue.length > 0) {
        await this.setIFrameSrc();
      }
    },
    async getSelectedCompany() {
      await this.setIFrameSrc();
    },
    async getLoggedInUserUpdated(updated) {
      const accountInfoRoute = "/account/info";

      //force reload iframe when logged in user is updated
      if (updated && this.$route.meta.localSitesRoute == accountInfoRoute) {
        await this.setIFrameSrc();
      }
    },
  },
  destroyed: function () {
    window.removeEventListener("message", this.handleIFrameUrlUpdate);
  },
  data() {
    return {
      iframeSrc: "",
    };
  },
};
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
#iframe-container {
  padding: 0;
}
</style>
