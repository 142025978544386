<style scoped lang="scss">
h2 {
  font-size: 1.25rem;
}
.v-card {
  padding: 1rem;
}

.chart {
  background-color: #e5e5e5;
}

.header {
  margin-bottom: 1rem;
}
.error-panel {
  background-color: #e5e5e5;
  width: 100%;
  text-align: center;
  padding: 2rem;
  font-size: 1.25rem;
}
</style>

<template>
  <v-card>
    <div class="header" tabindex="-1">
      <h2>{{ $t("outreachBreakdownData.sectionHeader") }}</h2>
    </div>
    <div class="mb-5 text-left">
      <span>{{ this.getActionTypeText(this.currentActionType) }}</span>
    </div>
    <div v-if="isLoadingError" class="error-panel">
      {{ $t("outreachBreakdownData.failure") }}
    </div>
    <div v-else-if="isCurrentActionAllZeroes" class="error-panel" tabindex="0">
      {{ allZeroesMessage }}
    </div>
    <div
      v-else-if="outreachBreakdownData"
      class="chart-contents"
      :aria-label="outreachBreakdownDataSummary"
      tabindex="0"
    >
      <outreach-breakdown-chart
        v-if="outreachBreakdownData"
        :chart-data="outreachBreakdownData"
        :options="options"
        class="chart"
      />
    </div>
    <loading-spinner class="ma-5" v-else></loading-spinner>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";
import OutreachBreakdownChart from "./outreach-breakdown-chart";

export default {
  components: { OutreachBreakdownChart, LoadingSpinner },
  computed: {
    ...mapGetters("dashboard", ["outreachServiceToken"]),
    ...mapGetters("outreach-breakdown-data", [
      "outreachBreakdownData",
      "options",
      "outreachBreakdownDataSummary",
      "allZeroesMessage",
      "isCurrentActionAllZeroes",
      "isLoadingError",
      "currentActionType",
    ]),
  },
  data() {
    return {
      actionTypeList: [
        {
          text: this.$t("outreachBreakdownData.jobLinksSent"),
          value: "jobsSent",
        },
        {
          text: this.$t("outreachBreakdownData.jobViews"),
          value: "jobViews",
        },
        {
          text: this.$t("outreachBreakdownData.applicants"),
          value: "applicants",
        },
      ],
    };
  },
  created() {
    if (this.outreachServiceToken) {
      this.fetchOutreachBreakdownData();
    }
  },
  methods: {
    ...mapActions("outreach-breakdown-data", ["fetchOutreachBreakdownData"]),
    getActionTypeText(actionType) {
      return this.actionTypeList.find((type) => type.value == actionType).text
    }
  },
  filters: {},
};
</script>
