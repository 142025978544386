<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" offset="2">
        <v-card class="pa-3">
          <v-data-table
            :headers="headers"
            :items="getPreferences"
            item-key="id"
            :loading="getLoading"
            sort-by="name"
            :hide-default-footer="true"
          >
            <template v-slot:[`item.status`]="item">
              <v-row justify="center">
                <v-switch
                  v-model="item.value"
                  color="green"
                  @click="handleStatusUpdate(item)"
                >
                </v-switch>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        {
          text: "Notification Type",
          align: "start",
          value: "name",
          width: "40%",
        },
        {
          text: "Description",
          align: "start",
          value: "description",
          width: "40%",
        },
        {
          text: "Notify Me",
          align: "center",
          value: "status",
          width: "20%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("notification-preferences", ["getPreferences", "getLoading"]),
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
  methods: {
    ...mapActions("notification-preferences", ["fetchNotificationPreferences", "updateNotificationPreference"]),
    async handleStatusUpdate(item) {
        const updatedStatus = item.value;
        
        //if it's changed
        if(updatedStatus != item.item.status)
        {
            await this.updateNotificationPreference({
                notificationTypeId: item.item.id,
                status: updatedStatus
            });
        }
    },
  },
  watch: {
    async getSelectedCompany() {
      await this.fetchNotificationPreferences();
    },
  },
  async created() {
    await this.fetchNotificationPreferences();
  },
};
</script>

<style>
</style>