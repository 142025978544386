<template>
  <v-container class="container--fluid">
    <v-card class="pa-3">
      <v-row>
        <v-col>
          <v-form
            ref="dateSearchForm"
            lazy-validation
            lass="mx-4"
            v-model="isValid"
            class="mb-n7"
          >
            <v-row no-gutters>
              <v-col cols="3" sm="3" md="6" lg="7" xl="9"></v-col>
              <v-col
                ><div class="d-flex pt-2 mb-n3 text-subtitle-1" tabindex="0">
                  Search by Create Date
                </div></v-col
              >
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="3" md="6" lg="7" xl="9" />
              <v-col>
                <div class="d-sm-inline-flex">
                  <from-to-date-input
                    :class="{ 'px-3': $vuetify.breakpoint.xs }"
                    v-model="datePickerFilter"
                  />
                  <div class="pr-4">
                    <v-btn
                      @click="getDocumentsHandler"
                      :disabled="!isValid"
                      color="primary"
                      large
                      text
                      class="retrieve-report-cards-btn"
                      v-if="$vuetify.breakpoint.xs"
                    >
                      Search
                    </v-btn>
                    <div class="py-5 pl-5">
                      <v-btn
                        @click="getDocumentsHandler"
                        :disabled="!isValid"
                        color="primary"
                        
                        small
                        v-if="!$vuetify.breakpoint.xs"
                      >
                        <v-icon dense>{{ icons.search }}</v-icon>
                        Search
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row></v-form
          >
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items-per-page="25"
        item-key="url"
        :loading="isLoading"
        :footer-props="footerProps"
        :items="documents"
        :sort-by="['date']"
        :sort-desc="[true]"
        tabindex="0"
      >
        <template v-slot:[`item.title`]="item">
          <a target="_blank" @click="viewReportCard(item)">{{ item.value }}</a>
        </template>
        <template v-slot:[`item.date`]="item">
          <span>{{ item.value | formatDateToLocaleString }} </span>
        </template>
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          <span aria-live="polite" aria-atomic="true">{{pageStart}}-{{pageStop}} of {{itemsLength}}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mdiCalendar, mdiFileSearchOutline } from "@mdi/js";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import FromToDateInput from "@/components/form/FromToDateInput.vue";
import { isEmpty } from "lodash";

export default {
  components: { FromToDateInput },

  data: () => ({
    isLoading: false,
    icons: {
      calendar: mdiCalendar,
      search: mdiFileSearchOutline,
    },
    footerProps: { "items-per-page-options": [25, 50, 75, 100] },
    isValid: true,
    headers: [
      {
        text: "Title",
        align: "start",
        filterable: true,
        value: "title",
      },
      { text: "Document Date", value: "date" },
    ],
    datePickerFilter: {
      fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        .toISOString()
        .substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      validationRules: {
        fromDateRules: [
          (v) => {
            return !isEmpty(v) || "From Date is required";
          },
        ],
        toDateRules: [
          (v) => {
            return !isEmpty(v) || "To Date is required";
          },
        ],
      },
    },
  }),
  async created() {
    this.isLoading = true;
    await this.fetchDocuments(this.buildPayload());
    this.isLoading = false;
  },
  computed: {
    ...mapState("document-center", ["documents"]),
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
  mounted() {
      const sortableColumns = this.$el.querySelectorAll('th.sortable > span:not(.v-icon)');

      sortableColumns.forEach(col => {
        col.setAttribute('tabindex', '0')

        col.addEventListener('keyup', this.columnKeyUp)
      });
    },
  methods: {
    ...mapActions("document-center", [
      "fetchDocuments",
      "retrieveReportCardUrl",
    ]),
    columnKeyUp(event) {
      if (event.key !== " " && event.key !== "Enter") return

      event.target.click()
    },
    ...mapMutations("app-messages", ["displaySuccessMessage"]),
    async getDocumentsHandler() {
      this.isLoading = true;
      await this.fetchDocuments(this.buildPayload());
      this.isLoading = false;
    },
    viewReportCard({ item }) {
      this.retrieveReportCardUrl({ ReportCardId: item.mailingKey }).then(
        (url) => {
          if (url) {
            window.open(url, "_blank").focus();
          }
        }
      );
    },
    buildPayload() {
      const payload = {
        CompanyId: this.getSelectedCompany.id,
        BeginDate: this.datePickerFilter.fromDate,
        EndDate: this.datePickerFilter.toDate,
      };

      return payload;
    },
  },
  filters: {
    formatDateToLocaleString(date) {
      return new Date(date).toLocaleDateString();
    },
  },
  watch: {
    documents() {
        this.displaySuccessMessage(`${this.documents.length} documents found.`);
    }
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .retrieve-report-cards-btn {
    position: relative;
    right: 0.1rem;
    bottom: 1rem;
    width: 100%;
  }
}
</style>
