//build localsites return url with params if catalyst has params
export function modifyReturnURLWithParamsOnLoad(returnUrl, queryParams) {
  if (returnUrl) {
    //if localsites url contains params, replace it with catalyst query params
    //else if it doesn't, just add query params as is
    const returnQueryIndex = returnUrl.indexOf("?");
    if (returnQueryIndex > 0) {
      returnUrl = `${returnUrl.substring(0, returnQueryIndex)}?${queryParams}`;
    } else {
      returnUrl = `${returnUrl}?${queryParams}`;
    }
    return returnUrl;
  }
}

//extracts local sites route from event if exists in our route configs
export function getLocalSitesRouteInfoFromEvent(eventData, router) {
  const queryDelimIndex = eventData.indexOf("?");
  const { url: ljnUrl, iFrameQueryParams } = getLJNUrl(eventData, queryDelimIndex);
  const matchingLocalSitesRoute = findMatchingLocalSitesRoute(router, ljnUrl);

  return {
    matchingLocalSitesRoute,
    iFrameQueryParams
  };
}

function getLJNUrl(eventData, queryDelimIndex) {
  const ljnObj = {
    url: "",
    iFrameQueryParams: "",
  };
  if (queryDelimIndex > 0) {
    //if it includes pagemode, return url with that pagemode
    //else keep the page mode query for the url
    if (eventData.includes("pagemode")) {
      const params = eventData.split("&");
      if (params.length > 1) {
        ljnObj.iFrameQueryParams = eventData.substring(queryDelimIndex + 1);
      }
      ljnObj.url = params[0];
    } else {
      [ljnObj.url, ljnObj.iFrameQueryParams] = eventData.split("?");
    }
  }

  if (ljnObj.url.includes("/applicants.asp")) {
    //we only have pagemode=1 in catalyst so we need to treat all applicants related routes,
    //regardless of their pagemodes, as pagemode=1 so it can match with our config
    ljnObj.url = "/applicants.asp?pagemode=1";
  }

  return ljnObj;
}

function findMatchingLocalSitesRoute(router, ljnUrl) {
  const matchingLocalSitesRoute = router.getRoutes().find((x) => {
    if (x.meta) {
      return x.meta.localSitesRoute == ljnUrl;
    }
    return false;
  });

  return matchingLocalSitesRoute;
}
