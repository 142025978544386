//static industry types for form
const CompanySizeTypes = Object.freeze([
    { text: "Self-employed", value: 1130 },
    { text: "1-10", value: 1131 },
    { text: "11-50", value: 1132 },
    { text: "51-200", value: 1133 },
    { text: "201-500", value: 1134 },
    { text: "501-1,000", value: 1135 },
    { text: "1,001-5,000", value: 1136 },
    { text: "5,001-10,000", value: 1137 },
    { text: "10,001+", value: 1138 },
  ]);
  export default CompanySizeTypes;
  