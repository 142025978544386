<template>
  <div class="container">
    <span class="text-center"
      ><h1 tabindex="-1">Applicant Demographics</h1></span
    >
    <v-card class="pa-3 ">
      <v-row>
        <v-col class="hidden-md-and-down" sm="1" xl="4"></v-col>
        <v-col>
          <div>
            <applicant-demographics-filter
              v-model="filters"
              @submit="loadData"
            />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" lg="6"></v-col>
        <v-col align="end">
          <div class="pt-2 mb-n1 pr-2">
            <v-btn class="mb-2" small @click="exportCharts"
              >Export<v-icon right class="export-icon" color="#1D6F42">{{
                icons.export
              }}</v-icon>
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="25"
                ></v-progress-circular
              ></template> </v-btn
          ></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <diversity-category-chart
            class="mt-n4"
            v-if="!isLoading"
            :chart-data="getDiversityCategoryChartData()"
          />
          <loading-spinner v-else class="ma-10"></loading-spinner>
        </v-col>
        <v-col md="12" lg="6">
          <gender-chart
            class="mt-n4"
            v-if="!isLoading"
            :chart-data="getGenderChartData()"
          />
          <loading-spinner v-else class="ma-10"></loading-spinner>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
h1 {
  margin-bottom: 2rem;
}
.container--fluid {
  padding: 2rem;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import DiversityCategoryChart from "@/components/Reports/ApplicantDemographics/diversity-category-chart";
import GenderChart from "@/components/Reports/ApplicantDemographics/gender-chart";
import ApplicantDemographicsFilter from "@/components/Reports/ApplicantDemographics/ApplicantDemographicsFilter";
import LoadingSpinner from "@/components/LoadingSpinner";
import moment from "moment";
import { mdiChartBoxOutline } from "@mdi/js";
import { buildDownloadLink } from "@/utilities";

export default {
  components: {
    DiversityCategoryChart,
    GenderChart,
    ApplicantDemographicsFilter,
    LoadingSpinner,
  },

  data() {
    return {
      isLoading: true,
      filters: {
        jobTitle: "",
        location: "",
        dates: {
          fromDate: moment()
            .subtract(1, "Years")
            .toISOString()
            .substr(0, 10),
          toDate: moment()
            .toISOString()
            .substr(0, 10),
          validationRules: {
            fromDateRules: [],
            toDateRules: [],
          },
        },
      },
      icons: {
        export: mdiChartBoxOutline,
      },
    };
  },
  computed: {
    ...mapGetters("applicant-demographics", [
      "getDiversityCategoryBreakdown",
      "getGenderBreakdown",
      "getApplicantTotal",
    ]),
    ...mapGetters("auth", ["getCompanies", "getSelectedCompany"]),
  },
  async created() {
    await this.loadData(this.filters);
  },
  methods: {
    ...mapActions("applicant-demographics", ["fetchApplicantDemographics"]),
    async loadData() {
      this.isLoading = true;

      await this.fetchApplicantDemographics({
        companyId: this.getSelectedCompany.id,
        jobTitle: this.filters.jobTitle,
        location: this.filters.location,
        startDate: this.filters.dates.fromDate,
        endDate: this.filters.dates.toDate,
      });

      this.isLoading = false;
    },
    getDiversityCategoryChartData() {
      return {
        labels: Object.keys(this.getDiversityCategoryBreakdown),
        datasets: [
          {
            data: Object.entries(this.getDiversityCategoryBreakdown).map(
              ([, v]) => {
                return ((v / this.getApplicantTotal) * 100).toFixed(1);
              }
            ),
            backgroundColor: [
              "rgba(0, 188, 112, 1)",
              "rgba(0, 74, 152, 1)",
              "rgba(102, 233, 241, 1)",
              "rgba(230, 231, 232, 1)",
              "rgba(147, 149, 152, 1)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 188, 112, 1)",
              "rgba(0, 74, 152, 1)",
              "rgba(102, 233, 241, 1)",
              "rgba(230, 231, 232, 1)",
              "rgba(147, 149, 152, 1)",
              "rgba(0, 0, 0, 1)",
            ],
          },
        ],
      };
    },
    getGenderChartData() {
      return {
        labels: Object.keys(this.getGenderBreakdown),
        datasets: [
          {
            data: Object.entries(this.getGenderBreakdown).map(([, v]) => {
              return ((v / this.getApplicantTotal) * 100).toFixed(1);
            }),
            backgroundColor: [
              "rgba(0, 74, 152, 1)",
              "rgba(0, 188, 112, 1)",
              "rgba(230, 231, 232, 1)",
              "rgba(102, 233, 241, 1)",
            ],
          },
        ],
      };
    },
    exportCharts() {
      var barGraph = document.getElementById("horizontalbar-chart");
      var donutChart = document.getElementById("doughnut-chart");
      buildDownloadLink({
        title: "Diversity Category Breakdown",
        htmlElement: barGraph,
        blob: barGraph.msToBlob && barGraph.msToBlob(),
      });
      buildDownloadLink({
        title: "Gender Breakdown",
        htmlElement: donutChart,
        blob: donutChart.msToBlob && donutChart.msToBlob(),
      });
    },
  },
  watch: {
    getSelectedCompany: "loadData",
  },
};
</script>
