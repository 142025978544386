<template>
  <v-container fluid>
    <loading-spinner v-if="isLoading"></loading-spinner>
    <div v-else>
      <v-expansion-panels v-model="getSelectedContentTagIndex" class="tag-list">
        <v-expansion-panel v-for="tag in getTags" :key="tag.id">
          <v-expansion-panel-header color="#f8f4f4">
            <v-row class="expansion-panel">
              <v-col cols="1">
                <v-icon>{{ icons.newspaper }}</v-icon>
              </v-col>
              <v-col class="d-flex align-center"> {{ tag.label }}</v-col>
              <v-col class="d-flex align-center justify-end pr-5" cols="2"
                >View {{ tag.contents.length }} items</v-col
              >
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f8f4f4">
            <span v-for="c in tag.contents" :key="c.contentId">
              <v-row>
                <v-col cols="1">
                  <v-icon>{{ icons.attachment }}</v-icon>
                </v-col>
                <v-col cols="8" class="text-left">
                  <v-btn
                    plain
                    small
                    color="#004A98"
                    @click="handleAttachmentClick(c)"
                    :loading="
                      contentDownloading && contentIDToDownload == c.contentId
                    "
                    >{{ c.name }}</v-btn
                  >
                </v-col>
              </v-row>
            </span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import { mdiAttachment, mdiNewspaperVariantMultiple } from "@mdi/js";
import { mapGetters, mapActions } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["contentId"],
  components: {
    LoadingSpinner,
  },
  data: () => {
    return {
      isLoading: true,
      contentDownloading: false,
      contentIDToDownload: null,
      icons: {
        newspaper: mdiNewspaperVariantMultiple,
        attachment: mdiAttachment,
      },
    };
  },
  methods: {
    ...mapActions("abi-content", [
      "fetchTags",
      "downloadFile",
    ]),
    async handleAttachmentClick(content) {
      const payload = {
        id: content.fileId,
        name: content.fileName,
        resourceId: content.contentId
      };

      this.contentIDToDownload = content.contentId;
      this.contentDownloading = true;

      await this.downloadFile(payload);

      this.contentIDToDownload = null;
      this.contentDownloading = false;
    },
  },
  computed: {
    ...mapGetters("abi-content", ["getTags", "getSelectedContentTagIndex"]),
  },
  async created() {
    this.isLoading = true;
    await this.fetchTags(this.contentId);

    this.isLoading = false;
    },
    watch: {
      isLoading(loading) {
        if (!loading) {
          setTimeout(() => {
            const firstExpandButton = this.$el.querySelector('button.v-expansion-panel-header');
            firstExpandButton.click();
          }, 100);
        }
      }
    }
};
</script>

<style >
.tag-list {
  max-width: 80rem;
  margin: auto;
}
</style>