import { isProduction } from "@/config/app-config";
import outreachService from "@/api/outreach-service";
import { resourcesForCurrentLanguage } from '@/lang-resources';

const langResources = resourcesForCurrentLanguage.toastMessages;

const state = () => ({
    outreachServiceToken: null
});

const getters = {
    outreachServiceToken(state) { return state.outreachServiceToken; }
}

const mutations = {
    setOutreachServiceToken(state, val) { state.outreachServiceToken = val; }
}

const actions = {
    fetchOutreachServiceToken({ commit }, companyId) {
        return outreachService.getOutreachServiceToken({
            CompanyId: companyId,
        }, {
            success: (data) => { commit("setOutreachServiceToken", data.jwt); },
            error: (error) => {
                if (error.response && error.response.status == 401) {
                    commit("app-messages/displayErrorMessage", langResources.authenticationError, { root: true });
                }
                else {
                    commit("app-messages/displayErrorMessage", langResources.genericToastrError, { root: true });
                }
            }
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    strict: !isProduction,
};
