<template>
  <v-container class="container--fluid">
    <v-col cols="12">
      <job-screenshot-filter class="pt-4" />
    </v-col>
    <v-col cols="12">
      <job-screenshots-table class="mt-n6" />
    </v-col>
  </v-container>
</template>
<script>
import JobScreenshotFilter from "@/components/DocumentCenter/JobScreenshotFilter.vue";
import JobScreenshotsTable from "@/components/DocumentCenter/JobScreenshotsTable.vue";

export default {
  components: { JobScreenshotFilter, JobScreenshotsTable },
  data: () => ({
    selectedTab: null,
  }),
};
</script>