import axios from "axios";
import { baseOutreachServiceApiUrl } from "@/config/app-config";
import store from "@/store";

const DO_NOTHING = () => { };

export default {
  async post(endpoint, payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    try {
      const res = await axios.post(baseOutreachServiceApiUrl + endpoint, payload, {
        headers: {
          Accept: "application/json",
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.getters["dashboard/outreachServiceToken"]}`,
        },
      });
      return success(res.data);
    } catch (e) {
      if (process.env.NODE_ENV !== "production") console.error(e);
      return error(e);
    }
  },
};
