import { isProduction } from "@/config/app-config";
import abi from "@/api/abi/resources";
import FileDownload from "js-file-download";
import { ResourceType } from "./ResourceType";
import _ from "lodash";
import store from "../..";

const state = () => ({
  tags: [],
  selectedContentTagIndex: -1,
});

const getters = {
  getTags(state) {
    return state.tags;
  },
  getSelectedContentTagIndex(state) {
    return state.selectedContentTagIndex;
  },
};

const mutations = {
  setTags(state, val) {
    state.tags = val;
  },
  setSelectedContentTagIndex(state, val) {
    state.selectedContentTagIndex = val;
  },
};

const actions = {
  async fetchTags({ commit, rootGetters }, contentId) {
    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id
    };
    return abi.getContentTagsWithContents(payload, {
      success: (tags) => {
        if (contentId) {
          //get the index of the first tag containing the content tag id
          const firstTagWithContentFromRotue = _.findIndex(tags, (t) =>
            _.some(t.contents, [
              "contentId",
              contentId,
            ])
          );

          commit("setSelectedContentTagIndex", firstTagWithContentFromRotue);
        }

        commit("setTags", tags);
      },
    });
  },
  async downloadFile({ rootGetters }, payload) {
    payload.companyId = rootGetters["auth/getSelectedCompany"].id;
    payload.resourceType = ResourceType.Content.name;
    return abi.getFile(payload, {
      success: (data) => {
        FileDownload(data, payload.name);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        store.commit("app-messages/displayErrorMessage", "Error occurred while trying to retrieve observances.", { root: true });
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
