<template>
  <v-container>
    <v-alert
      v-if="showPastInvoicesMessage() && hasValidSageCustomerId()"
      class="ma-2 text-left"
      colored-border
      border="left"
      type="info"
      elevation="2"
    >
      To view or pay previous invoices, please contact your account manager or
      call (800) 984-3775.
    </v-alert>
    <v-alert
      v-else
      class="ma-2 text-left"
      colored-border
      border="left"
      type="info"
      elevation="2"
    >
      Please contact your account manager at {{ getDefaultAMContactNum }} for
      payment information.
    </v-alert>
    <iframe
      v-if="!isLoading"
      width="100%"
      frameborder="0"
      :src="this.getVersaPayUrl"
    ></iframe>
    <loading-spinner v-else-if="hasValidSageCustomerId()"></loading-spinner>
  </v-container>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

const PAST_INVOICE_CUTOFF_DATE = moment("2021-11-01");

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  components: { LoadingSpinner },
  computed: {
    ...mapGetters("orders", ["getVersaPayUrl"]),
    ...mapGetters("auth", [
      "getSelectedCompany",
      "getDefaultAMContactNum",
      "getSageCustomerId",
    ]),
  },
  methods: {
    ...mapActions("orders", ["fetchVersaPayUrl"]),
    async loadIFrame() {
      this.isLoading = true;
      await this.fetchVersaPayUrl();
      this.isLoading = false;
    },

    hasValidSageCustomerId() {
      var scid = this.getSageCustomerId;
      if (!scid || scid.includes("JF") || scid.includes("TEMP")) {
        return false;
      }
      return true;
    },
    showPastInvoicesMessage() {
      return moment(this.getSelectedCompany.created) < PAST_INVOICE_CUTOFF_DATE;
    },
  },
  async created() {
    if (this.hasValidSageCustomerId()) {
      await this.loadIFrame();
    }
  },
};
</script>

<style scoped>
iframe {
  height: 80vh;
}
</style>
