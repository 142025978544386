import http from "@/api/employer-http";

const DO_NOTHING = () => { };

export default {
  getObservances(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    let params = new URLSearchParams();
    params.append("companyId", payload.CompanyId);
    params.append("startDate", payload.StartDate);
    params.append("endDate", payload.EndDate);
    payload.Tags.forEach(tagId => params.append("tags", tagId));

    return http.get(
      "/api/abi/observances", params,
      {
        success,
        error,
      }
    );
  },
  getObservance(observanceId, companyId, { success = DO_NOTHING, error = DO_NOTHING }) {
    let params = new URLSearchParams();
    params.append("companyId", companyId);
    return http.get(
      `/api/abi/observances/${observanceId}`, params,
      {
        success,
        error,
      }
    );
  },
  saveObservance(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.post("/api/abi/userobservances/save?companyId=" + payload.companyId, payload, {
      success,
      error,
    });
  },
  getUserSavedObservances(
    payload,
    { success = DO_NOTHING, error = DO_NOTHING }
  ) {
    return http.post("/api/abi/userobservances?companyId=" + payload.companyId, payload, {
      success,
      error,
    });
  },
  deleteUserObservance(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.delete("/api/abi/userobservances?companyId=" + payload.companyId, payload, {
      success,
      error,
    });
  },
  getFile(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.getBlob(
      "/api/abi/files",
      {
        fileId: payload.id,
        companyId: payload.companyId,
        resourceType: payload.resourceType,
        resourceId: payload.resourceId
      },
      {
        success,
        error,
      }
    );
  },
  getTags(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    let params = new URLSearchParams();
    params.append("companyId", payload.companyId);
    return http.get(
      "/api/abi/tags", params,
      {
        success,
        error,
      }
    );
  },
  getContentTagsWithContents(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    let params = new URLSearchParams();
    params.append("companyId", payload.companyId);
    return http.get(
      "/api/abi/tags/contents", params,
      {
        success,
        error,
      }
    );
  },
};
