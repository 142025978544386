import http from './employer-http';

const DO_NOTHING = () => { };

export default {
    getCities(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get('/api/cities', { substr: payload }, {
            success,
            error
        });
    }
};