<template>
  <v-container>
    <v-dialog v-model="showModal" max-width="60rem" eager>
      <v-card class="observance-detail">
        <v-card-title class="text-h5 justify-center name" tabindex="0" aria-live="polite">{{
          this.getSelectedObservance.name
        }}</v-card-title>
        <v-container class="d-flex justify-center">
          <v-chip-group justify-center>
            <v-chip
              v-for="tag in this.getSelectedObservance.tags"
              :key="tag.id"
              color="primary"
            >
              {{ tag.label }}
            </v-chip>
          </v-chip-group>
        </v-container>
        <div class="text-center date">
          {{ this.formattedStart }}
          <span v-if="this.formattedEnd"> - {{ this.formattedEnd }} </span>
        </div>

        <v-card-text class="text-center mt-3" tabindex="0">
          {{ this.getSelectedObservance.description }}
        </v-card-text>
        <v-container>
          <h4 class="mb-2">Attachments</h4>
          <span v-if="this.hasAttachments">
            <v-row
              v-for="att in this.sortedAttachments"
              :key="att.id"
              dense
              class="mt-n3"
            >
              <v-col>
                <v-btn
                  text
                  color="blue"
                  @click="
                    handleAttachmentClick(
                      getSelectedObservance.id,
                      att.id,
                      att.name
                    )
                  "
                  :loading="
                    attachmentDownloading && attachmentIDToDownload == att.id
                  "
                  small
                >
                  {{ att.name }}
                </v-btn>
              </v-col>
            </v-row>
          </span>
          <span class="caption" v-else> Content Coming Soon </span>
        </v-container>
        <v-container class="pb-10 mt-5">
          <v-img v-if="this.getSelectedObservance.imageURI"
                 max-width="40rem"
                 class="mx-auto"
                 :src="this.getSelectedObservance.imageURI" />
          <v-btn @click="showModal = false"
                 class="button ma-2"
                 color="primary"
                 outlined
                 rounded
                 elevation="0">Close</v-btn>
        </v-container>
        
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import dayjs from "dayjs";

export default {
  data: () => ({
    hasAttachments: false,
    attachmentIDToDownload: null,
    attachmentDownloading: false,
    formattedStart: null,
    formattedEnd: null,
  }),
  computed: {
    ...mapGetters("abi-observances", ["getShowModal", "getSelectedObservance"]),
    ...mapMutations("abi-observances", [
      "setShowModal",
      "setSelectedObservance",
    ]),

    showModal: {
      get() {
        return this.getShowModal;
      },
      set(value) {
        this.showObservance({
          show: value,
          observance: {},
        });
        this.$router.replace({ path: "/abi/observances" });
      },
    },
    sortedAttachments: {
      get() {
        const kvpAttachments = this.getSelectedObservance.attachments;
        const arrAttachments = Object.values(kvpAttachments);
        
        return arrAttachments.sort((a, b) => {
          return (b.order != null) - (a.order != null) || a.order - b.order;
        });
      },
    },
  },
  watch: {
    getSelectedObservance(value) {
      this.hasAttachments = Object.keys(value.attachments).length !== 0;
      this.formattedStart = dayjs(value.start).format("MMMM DD, YYYY");

      if (value.end) {
        this.formattedEnd = dayjs(value.end).format("MMMM DD, YYYY");
      } else {
        this.formattedEnd = null;
      }
    },
  },
  methods: {
    ...mapActions("abi-observances", ["showObservance", "downloadFile"]),
    handleAttachmentClick(observanceId, id, fileName) {
      const payload = {
        id,
        name: fileName,
        resourceId: observanceId,
      };

      this.attachmentIDToDownload = id;
      this.attachmentDownloading = true;

      this.downloadFile(payload);

      this.attachmentDownloading = false;
      this.attachmentIDToDownload = null;
    },
  },
};
</script>

<style scoped>
.observance-detail {
  overflow-y: auto;
  overflow-x: hidden;
}

.observance-detail .name,
.observance-detail .date {
  color: #0971ea !important;
}
</style>