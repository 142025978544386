<template>
  <v-container v-model="value" class="text-left authorization pt-2 pb-2">
    <div class="mb-1">Section Access and Authorization:</div>
    <div>
      <v-row>
        <v-col class="pt-1 pb-1">
          <v-switch v-model="user.accessJobs"
                    label="Access Jobs"
                    :disabled="isCurrentUser"></v-switch>
        </v-col><v-col class="pt-1 pb-1">
          <v-switch v-model="user.accessResumes"
                    label="Access Resumes"
                    :disabled="isCurrentUser"></v-switch>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col class="pt-1 pb-1">
          <v-switch v-model="user.accessApplicants"
                    label="Access Applicants"
                    :disabled="isCurrentUser"></v-switch>
        </v-col><v-col class="pt-1 pb-1">
          <v-switch v-model="user.accessReports"
                    label="Access Reports"
                    :disabled="isCurrentUser"></v-switch>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col class="pt-1 pb-1">
          <v-switch v-model="user.editAllJobPostings"
                    label="Edit All Job Postings"
                    :disabled="isCurrentUser"></v-switch>
        </v-col>
        <v-col class="pt-1 pb-1">
          <v-switch v-model="user.accessOFCCPAccountManagement"
                    label="Access OFCCP Account Management"
                    :disabled="isCurrentUser"></v-switch>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col class="pt-1 pb-1">
          <v-switch v-if="hasAICM"
                    v-model="user.accessDiversitySourcing"
                    label="Access Diversity Sourcing"
                    :disabled="isCurrentUser"></v-switch>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import { mapGetters } from "vuex";

export default {
  name: "UserPermissionsComponent",
  props: {
    user: Object,
    isCurrentUser: Boolean,
    value: Boolean,
    },
  computed: {
    ...mapGetters("auth", ["getProductAuth"]),
    hasAICM() {
      return this.getProductAuth("AICM");
    },
  },
};
</script>
