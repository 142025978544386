<template>
  <v-container>
    <div id="circa_content">
      <v-row>
        <v-col>
          <h1>Outreach Management System</h1>
          <p>
            Connect with and automatically distribute your jobs to 15,500+ community-based organizations to reach underrepresented individuals and diversity your workforce with our Outreach Management System.
          </p>
          <ul>
            <li>Easily send, track and record referrals, communication, and outreach to demonstrate and assess your efforts for OFCCP Compliance. </li>
            <li>Conduct outreach to women, minorities, veterans, and individuals with disabilities, and more.</li>
            <li>Your open positions are sent to Local Veterans' Employment Representative (LVERs) and Disabled Veterans Outreach Program (DVOP) specialists through a targeted email to help reach more veterans.</li>
          </ul>
          <br />
          <v-btn rounded
                 color="primary"
                 class="mx-auto"
                 width="80%" :href="
                     `mailto:${(getSelectedCompany.amEmail ? getSelectedCompany.amEmail : 'renewal@circaworks.com')}?subject=Outreach Management System Inquiry&body=I'm interested in receiving information on Circa's Outreach Management System.`">
            Contact Your Account Manager
          </v-btn>
        </v-col>
        <v-col align-self="center">
          <img src="https://circaworks.com/wp-content/uploads/2022/09/oms_image700.jpg" alt="oms image" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<style>
  @import '../../styles/Upsell.css';
</style>

<script>
  import { mapGetters } from "vuex";
  import circle from "@/assets/Circa_Circles2021.png";
  export default {
    computed:
    {
      ...mapGetters("auth", ["getSelectedCompany", "getDefaultAMEmail"])
    },
    mounted: async function () {
      if (document.getElementById("app"))
        document.getElementById(
          "app"
        ).style.backgroundImage = `url(${circle}), url(${circle})`;
    },
    destroyed() {
      if (document.getElementById("app"))
        document.getElementById("app").style.backgroundImage = "none";
    },
  }
</script>