var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.organizationFields,"items":_vm.organizationEngagementSummaryData,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":"","loading":_vm.isLoading,"loading-text":"Loading... Please wait","tabindex":"0"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":_vm.updateSorted},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-toolbar-title',[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("organizationEngagementSummary.sectionHeader"))+" ")])]),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"inner-table",attrs:{"dark":"","dense":"","headers":_vm.recipientFields,"items":item.recipientEngagement,"hide-default-footer":true,"tabindex":"0","aria-live":"polite"}})],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_c('span',{attrs:{"aria-live":"polite","aria-atomic":"true"}},[_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" of "+_vm._s(itemsLength))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }