import http from './outreach-service-http';
import employerHttp from './employer-http';

const DO_NOTHING = () => {};

export default {
    getOutreachPipelineData(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post('/r/Dashboard/OutreachPipelineData', payload, {
            success,
            error
        });
    },
    getOutreachBreakdownData(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post('/r/Dashboard/OutreachActionCategoryBreakdown', payload, {
            success,
            error
        });
    },
    getOrganizationEngagementSummaryData(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post('/r/Dashboard/OrganizationEngagementSummary', payload, {
            success,
            error
        });
    },
    getOutreachServiceToken(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return employerHttp.get('/api/Outreach', payload, {
            success,
            error
        });
    }
};
