export const resources = {
    en: {
        toastMessages: {
            genericToastrError: 'Something went wrong',
            authenticationError: 'Unable to authenticate user',
        },
        dashboard: {
            header: 'Outreach Dashboard (Last 30 Days)',
        },
        outreachPipelineData: {
            sectionHeader: 'Outreach Pipeline',
            applicantsHeader: 'Number of Applicant Clicks',
            viewsHeader: 'Number of Job Views',
            linksHeader: 'Number of Outreach Jobs Sent',
            minorities: 'People of Color',
            iwd: 'IWD',
            veterans: 'Veterans',
            women: 'Women',
            all: 'All Categories',
            failure: 'Failed to load',
            authenticationError: 'Unable to authenticate user',
            genericToastrError: 'Something went wrong trying to retrieve Organization Engagement Summary data'
        },
        outreachBreakdownData: {
            sectionHeader: 'Community and Diversity Outreach Breakdown',
            jobLinksSent: 'Number of Outreach Jobs Sent',
            jobViews: 'Number of Job Views',
            applicants: 'Number of Applicant Clicks',
            failure: 'Failed to load',
            iwd: 'IWD',
            minorities: 'People of Color',
            veterans: 'Veterans',
            women: 'Women',
            stateWorkforce: 'State Workforce',
            other: 'Other',
            authenticationError: 'Unable to authenticate user',
            genericToastrError: 'Something went wrong attempting to retrieve Community and Diversity Outreach Breakdown data',
            noActionsMessage: {
                jobsSent: 'No Outreach Jobs were sent in the last 30 days',
                jobViews: 'No Job Views in the last 30 days',
                applicants: 'No Applicant Clicks in the last 30 days'
            }
        },
        organizationEngagementSummary: {
            sectionHeader: 'Organization Engagement Summary',
            automaticOutreachChannel: 'Automatic Outreach',
            linkageChannel: 'Linkage',
            vetConnectChannel: 'VetConnect',
            multipleChannel: 'Multiple',
            failure: 'Failed to load',
            communityOrganization: 'Community Organization',
            channel: 'Channel',
            jobLinksSent: 'Outreach Jobs Sent',
            views: 'Views',
            applicants: 'Applicant Clicks',
            recipient: 'Recipient',
            email: 'E-Mail',
            authenticationError: 'Unable to authenticate user',
            genericToastrError: 'Something went wrong trying to retrieve Organization Engagement Summary data'
        },
    },
    es: {
        toastMessages: {
            genericToastrError: 'Algo salió mal',
            authenticationError: 'Incapaz de Autenticar Usuario',
        },
        dashboard: {
            header: 'Panel de Divulgación (30 Días Anteriores)',
        },
        outreachPipelineData: {
            sectionHeader: 'Tubería de Alcance',
            applicantsHeader: 'Numero de Solicitantes',
            viewsHeader: 'Numero de las Vistas',
            linksHeader: 'Numero de Trabajos de Enlaces Expedidos',
            minorities: 'Gente de Color',
            iwd: 'Individuos con Discapacidades',
            veterans: 'Veteranos',
            women: 'Mujer',
            all: 'Todos',
            failure: 'Falló al cargar',
            authenticationError: 'Incapaz de Autenticar Usuario',
            genericToastrError: 'Algo salió mal al intentar recuperar los datos del Resumen de compromiso de la organización'
        },
        outreachBreakdownData: {
            sectionHeader: 'Desglose de Alcance Comunitario y de Diversidad',
            jobLinksSent: 'Enlaces de Trabajo Enviados',
            jobViews: 'Vistas de Trabajo',
            applicants: 'Solicitantes',
            failure: 'Falló al cargar',
            minorities: 'Gente de Color',
            iwd: 'Individuos con Discapacidades',
            veterans: 'Veteranos',
            women: 'Mujer',
            stateWorkforce: 'Fuerza Laboral Estatal',
            other: 'Otro',
            authenticationError: 'Incapaz de Autenticar Usuario',
            genericToastrError: 'Algo salió mal al intentar recuperar los datos del Resumen de compromiso de la organización',
            noActionsMessage: 'No actions of the selected type were found for this time period.'
        },
        organizationEngagementSummary: {
            sectionHeader: 'Resumen de Compromiso de los Organizaciónes',
            failure: 'Falló al cargar',
            automaticOutreachChannel: 'Alcance Automático',
            linkageChannel: 'Enlace',
            vetConnectChannel: 'VetConnect',
            multipleChannel: 'Múltiple',
            communityOrganization: 'Organización de la Comunidad',
            channel: 'Canal',
            jobLinksSent: 'Enlaces de Trabajo Enviados',
            views: 'Puntos de Vista',
            applicants: 'Solicitantes',
            recipient: 'Recipiente',
            email: 'Correo Electrónico',
            authenticationError: 'Incapaz de Autenticar Usuario',
            genericToastrError: 'Algo salió mal al intentar recuperar los datos del Resumen de compromiso de la organización'
        },
    }
}

export const currentLanguage = (navigator.languages
    ? navigator.languages[0]
    : (navigator.language || navigator.userLanguage)).split('-')[0];

export const resourcesForCurrentLanguage = resources[currentLanguage];
