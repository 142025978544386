
<style scoped>
.inner-table {
  margin: 1rem;
}
h2 {
  font-size: 1.25rem;
}
.v-data-table >>> .v-data-table__wrapper {
  margin: 0 1rem;
}
</style>

<template>
  <div>
    <v-data-table
      :headers="organizationFields"
      :items="organizationEngagementSummaryData"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      tabindex="0"
      @update:options="updateSorted"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <h2>
              {{ $t("organizationEngagementSummary.sectionHeader") }}
            </h2></v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            dark
            dense
            class="inner-table"
            :headers="recipientFields"
            :items="item.recipientEngagement"
            :hide-default-footer="true"
            tabindex="0"
            aria-live="polite"
          >
          </v-data-table>
        </td>
      </template>
      <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
        <span aria-live="polite" aria-atomic="true">{{pageStart}}-{{pageStop}} of {{itemsLength}}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      expanded: [],
      sortDesc: true,
      organizationFields: [
        { text: "", value: "data-table-expand" },
        {
          value: "organizationName",
          text: this.$t("organizationEngagementSummary.communityOrganization"),
        },
        {
          value: "channel",
          text: this.$t("organizationEngagementSummary.channel"),
        },
        {
          value: "totalNumberOfJobsSent",
          text: this.$t("organizationEngagementSummary.jobLinksSent"),
          align: "end",
        }
      ],
      recipientFields: [
        {
          value: "recipientName",
          text: this.$t("organizationEngagementSummary.recipient"),
        },
        {
          value: "recipientEmail",
          text: this.$t("organizationEngagementSummary.email"),
        },
        {
          value: "channel",
          text: this.$t("organizationEngagementSummary.channel"),
        },
        {
          value: "numberOfJobsSent",
          text: this.$t("organizationEngagementSummary.jobLinksSent"),
          align: "end",
        }
      ],
      sortByOption:undefined,
    };
  },
  computed: {
    ...mapGetters("organization-engagement-summary-data", [
      "currentPage",
      "numberRows",
      "organizationEngagementSummaryData",
    ]),
    ...mapGetters("dashboard", ["outreachServiceToken"]),
    isLoading: function () {
      return (
        this.organizationEngagementSummaryData == undefined 
      );
    },
    },
  watch: {
    isLoading(newValue){
      if (!newValue) {
        setTimeout(() => {
          const firstExpandButton = this.$el.querySelector('button.v-data-table__expand-icon');
          firstExpandButton.click(); }, 500);
      }
    },
  },
  created() {
    if (this.outreachServiceToken) {
      this.fetchOrganizationEngagementSummaryData();
    }
    },
    mounted() {
      const sortableColumns = this.$el.querySelectorAll('th.sortable > span:not(.v-icon)');

      sortableColumns.forEach(col => {
        col.setAttribute('tabindex', '0')

        col.addEventListener('keyup', this.columnKeyUp)
      });
    },
  methods: {
    ...mapActions("organization-engagement-summary-data", [
      "fetchOrganizationEngagementSummaryData",
    ]),
    columnKeyUp(event) {
      if (event.key !== 'Enter') return

      event.target.click()
    },
    updateSorted() {
      if (!this.isLoading) {
        setTimeout(() => {
          const firstExpandButton = this.$el.querySelector('button.v-data-table__expand-icon');
          if (!firstExpandButton.classList.contains("v-data-table__expand-icon--active")) {
            firstExpandButton.click();
          }
        }, 100);
      }
    },
  },
  filters: {},
};
</script>
