<template>
  <v-container fluid>
    <v-card class="observance-card" outlined>
        <v-list-item>
          <v-row>
            <v-col v-on:click="handleOnClick(true)" v-on:keydown.enter="handleOnClick(true)" cols="5" tabindex="0">
              <div class="observance-short-summary rounded-lg">
                <v-row>
                  <v-col cols="2">
                    <v-img v-if="observance.duration > 21"
                           src="~@/assets/calendar_month.png"
                           contain
                           class="observance-calendar"
                           alt="Month Long">
                    </v-img>
                    <v-img v-else-if="observance.duration == 1"
                           src="~@/assets/calendar_day.png"
                           contain
                           class="observance-calendar"
                           alt="Single Day">
                    </v-img>
                    <v-img v-else
                           src="~@/assets/calendar_multiple.png"
                           contain
                           class="observance-calendar"
                           alt="Multiple Days">
                    </v-img>

                  </v-col>
                  <v-col cols="9">
                    <v-row>
                      <v-col>
                        <p class="observance-title">
                          {{ observance.name }}
                        </p>
                        <span>
                          <p class="observance-date">{{ this.formattedDate }}</p>
                        </span>
                        <v-row justify="start" align="center">
                          <v-col v-for="tag in observance.tags"
                                 :key="tag.id"
                                 class="py-0 px-1 flex-grow-0 observance-tag">
                            <v-chip class="mb-1"
                                    color="#114c98"
                                    text-color="#fff"
                                    small>
                              {{ tag.label }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-icon class="window-icon">
                      {{
                    icons.modelWindowIcon
                      }}
                    </v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col>
              <v-divider class="blue-divider" vertical></v-divider>
            </v-col>
            <v-col cols="5">
              <v-list-item-content class="observance-content" >
                <p v-if="observance.description.length > 300"
                   class="observance-description text-left" tabindex="0">
                  {{ this.truncateObservanceDescription(observance.description) }}
                  <a v-on:click="handleOnClick(true)" @keyup.enter="handleOnClick(true)" tabindex="0">View More...</a>
                </p>
                <p v-else class="observance-description text-left" tabindex="0">
                  {{ observance.description }}
                </p>
              </v-list-item-content>
            </v-col>
            <v-col cols="1" class="button">
              <v-list-item-action class="text-center">
                <v-btn v-if="getObservanceIsSavedByUser(observance.id)"
                       class="button ma-2"
                       @click="removeObservanceHandler(observance.id)"
                       :loading="getObservanceBtnLoading"
                       :text="true"
                       color="primary"
                       outlined
                       rounded
                       elevation="0">
                  Unsave
                </v-btn>
                <v-btn v-else
                       class="button ma-2"
                       @click="saveObservanceHandler(observance.id)"
                       :loading="getObservanceBtnLoading"
                       color="primary"
                       rounded
                       elevation="0">
                  Save
                </v-btn>
              </v-list-item-action>
            </v-col>
          </v-row>
        </v-list-item>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import { mdiOpenInNew } from "@mdi/js";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  props: ["observance"],
  data: function () {
    return {
      formattedStart: null,
      formattedDate: null,
      formattedEnd: null,
      formattedStartDay: null,
      getObservanceBtnLoading: false,
      icons: {
        modelWindowIcon: mdiOpenInNew,
      },
    };
  },
  created() {
    const dayStartDate = dayjs(this.observance.start);
    this.formattedDate = dayStartDate.format("MMMM DD, YYYY").toUpperCase();
    if (this.observance.end) {
      if (
        dayjs(this.observance.start).month() ==
        dayjs(this.observance.end).month()
      ) {
        if (
          dayjs(this.observance.start).year() ==
          dayjs(this.observance.end).year()
        ) {
          this.formattedDate =
            dayjs(this.observance.start).format("MMMM DD-").toUpperCase() +
            dayjs(this.observance.end).format("DD, YYYY");
        } else {
          this.formattedDate =
            this.formattedDate +
            "-" +
            dayjs(this.observance.end).format("MMMM DD, YYYY").toUpperCase();
        }
      } else {
        this.formattedDate =
          this.formattedDate +
          "-" +
          dayjs(this.observance.end).format("MMMM DD, YYYY").toUpperCase();
      }
    }
  },
  methods: {
    ...mapActions("abi-observances", [
      "showObservance",
      "saveObservance",
      "removeSavedObservance",
    ]),
    ...mapMutations("abi-observances", [
      "setShowModal",
      "setSelectedObservance",
    ]),
    async saveObservanceHandler(id) {
      this.getObservanceBtnLoading = true;
      await this.saveObservance(id);
      this.getObservanceDetails(id);
      this.getObservanceBtnLoading = false;
    },
    async removeObservanceHandler(id) {
      this.getObservanceBtnLoading = true;
      await this.removeSavedObservance(id);
      this.getObservanceBtnLoading = false;
    },
    handleOnClick(showModal) {
      this.showObservance({
        show: showModal,
        observance: this.observance,
      });
    },
    truncateObservanceDescription(description) {
      return description.slice(0, 300) + "...";
    },
  },
  computed: {
    ...mapGetters("abi-observances", [
      "getObservanceIsSavedByUser",
      "getObservanceDetails",
    ]),

   
  },
};
</script>

<style scoped>
.observance-title {
  font-weight: bold;
  font-size: 25px;
  padding: 0px;
  margin: 0px;
}
div,
col {
  padding: 0px;
  margin: 0px;
}
.observance-short-summary {
  width: 100%;
  height:100%;
  color: black;
  font-size: 16px;
  font-weight: 100;
  font-family: "Gotham", "Gotham Book";
  padding: 1rem 0 0.5rem 1rem;
  text-align: left;
  background-color: #e8e9eb;
  outline: 2px solid #114c98;
  cursor: pointer;
}
.observance-description {
  padding-top: 1rem;
  align-content: center;
  align-self: center;
}
.observance-tag {
  font-size: 9px;
}
.blue-divider {
  border-width: 1.3px;
  border-color: #66e9f1;
  background-color: #66e9f1;
}

.observance-card {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  font-size: 16px;
  font-family: "gotham book";
}

.observance-calendar {
  padding-top: 1rem;
  padding-left: 1rem;
  height: 50px;
  width: 50px;
}
.button {
  width: 100px;
  color: #fff;
  vertical-align: 50%;
  align-content: center;
  align-self: center;
}
.window-icon {
  align-content: center;
  align-self: flex-end;
  float: left;
  position: absolute;
  bottom: 10px;
}

@media screen and (min-width: 1950px) {
  .observance-card {
    padding: 1.5rem;
  }
}
</style>
