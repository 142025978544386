import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SilentRefresh from "../views/SilentRefresh.vue";
import store from "@/store";
import IFrame from "@/views/IFrame";
import IFrameExternal from "@/views/IFrameExternal";
import OutreachDashboard from "@/views/OutreachDashboard";
import DocumentCenter from "@/views/DocumentCenter";
import Observances from "@/views/ABI/Observances";
import Content from "@/views/ABI/Content";
import JobPerformance from "@/views/Reports/JobPerformance";
import ApplicantDemographics from "@/views/Reports/ApplicantDemographics";
import HelpPage from "@/views/HelpPage";
import Orders from "@/views/Account/Orders";
import FeatureFlagTypes from "@/router/feature-flags";
import Notifications from "@/views/Notification/Notifications";
import NotificationPreferences from "@/views/Notification/NotificationPreferences";
import Users from "@/views/Account/Users";
import DJUpsell from "@/components/Upsell/DJUpsell.vue";
import OMSUpsell from "@/components/Upsell/OMSUpsell.vue";
import DiversitySourcingUpsell from "@/components/Upsell/DiversitySourcingUpsell.vue";
import ABIUpsell from "@/components/Upsell/ABIUpsell.vue";
import EmployerBadge from "@/views/Account/EmployerBadge.vue";
import Info from "@/views/Account/Info";
import OutreachManagement from "@/views/OutreachManagement.vue";
import {
  getProductAuthsForAnalytics
} from "@/utilities";

import {
  mdiHome,
  mdiAccountBoxMultiple,
  mdiAccountSearch,
  mdiEarth,
  mdiNewspaperVariant,
  mdiFileChart,
  mdiPost,
  mdiOpenInNew,
  mdiHelpCircle,
  mdiLibrary,
  mdiHandHeart,
  mdiHumanGreetingProximity
} from "@mdi/js";
import {
  ApmVuePlugin
} from "@elastic/apm-rum-vue";
import {
  apm_server_uri,
  apm_service_name,
  gtag_id,
  hotjar_id,
  isProduction,
  displayVersaPayOrders,
  appcues_account_id,
  external_iframe_link_help,
  heap_account_id,
} from "../config/app-config";
import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";

Vue.use(VueRouter);
const routes = [{
    path: "/",
    name: "Home",
    component: Home,
    props: true,
    meta: {
      icon: mdiHome,
      isOpen: false,
      show: true,
    },
  },
  {
    path: "/account",
    name: "Account Settings",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiAccountBoxMultiple,
      show: true,
    },
    children: [{
        path: "orders",
        name: "Orders",
        component: displayVersaPayOrders ? Orders : null,
        meta: {
          show: displayVersaPayOrders,
        },
      },
      {
        path: "authorized-users",
        name: "Authorized Users",
        component: Users,
        meta: {
          show: true,
        },
      },
      {
        path: "company-info",
        name: "Company Info",
        component: Info,
        props: true,
        meta: {
          show: true,
        },
      },
      {
        path: "locations",
        name: "Company Locations",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/location/list",
          show: true,
        },
      },
      {
        path: "profile",
        name: "Company Profile",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/companyprofile/list",
          show: false,
        },
      },
      {
        path: "branding",
        name: "Company Branding",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/companybrandingsite/list",
          show: false,
          productAuthorization: "BRAND",
        },
      },
      {
        path: "employer-badge",
        name: "Employer Badge",
        component: EmployerBadge,
        props: true,
        meta: {
          productAuthorization: "DJJP",
          show: true,
        },
      },
      {
        path: "employer-badge/unauthorized",
        name: "Diversity Jobs",
        component: DJUpsell,
        props: true,
        meta: {
          show: false,
        },
      },
      {
        path: "socialmedia",
        name: "Social Media",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/socialmediawebsitemanage",
          show: true,
        },
      },
      {
        path: "templates",
        name: "Message Templates",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/applicantmessage/list",
          show: true,
        },
      },
      {
        path: "recipients",
        name: "Message Recipients",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/companyrecipient/list",
          show: true,
        },
      },
    ],
  },
  {
    path: "/jobs",
    name: "Job Management",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiPost,
      show: false,
    },
    children: [{
        path: "new-job",
        name: "Post a Job",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/jobs_manage.asp",
          show: true,
          productAuthorization: "LJNJP",
        },
        beforeEnter: (to, from, next) => {
          to.query.pagemode = 15;
          if (
            store.getters["auth/getFeatureFlag"](FeatureFlagTypes.UseCircaATS)
          ) {
            to.query.usecircaats = true;
          } else {
            to.query.usecircaats = false;
          }
          next(to.params);
        },
      },
      {
        path: "active-jobs",
        name: "Active Jobs",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/jobs_manage.asp?pagemode=1",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "questionnaires",
        name: "Questionnaires",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/questionnaire/list",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
    ],
  },
  {
    path: "/diversity",
    name: "Diversity",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiEarth,
      show: false,
      productAuthorization: "OMS",
    },
    children: [{
        path: "outreach-management",
        name: "Outreach Management",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/outreach/list",
          show: true,
          productAuthorization: "OMS",
        },
      },
      {
        path: "outreach-management/unauthorized",
        name: "Outreach Management Unauthorized",
        component: OMSUpsell,
        meta: {
          show: false,
        },
      },
      {
        path: "linkage-agents",
        name: "Linkage Agents",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/jobagent/list",
          show: true,
        },
      },
      {
        path: "outreach-dashboard",
        name: "Outreach Dashboard",
        component: OutreachDashboard,
        meta: {
          show: true,
          productAuthorization: "OMS",
        },
      },
      {
        path: "outreach-dashboard/unauthorized",
        name: "Outreach Dashboard Unauthorized",
        component: OMSUpsell,
        meta: {
          show: false,
        },
      },
      {
        path: "account-managment",
        name: "ESDS Account Management",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/ofccp/liststatejobbank",
          show: false,
          productAuthorization: "OFCCP",
        },
      },
    ],
  },
  {
    path: "/diversity/unauthorized",
    name: "",
    component: OMSUpsell,
    meta: {
      show: true,
      icon: mdiEarth,
    },
  },
  {
    path: "/resumes",
    name: "Resume Search",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiNewspaperVariant,
      show: true,
    },
    children: [{
        path: "diversity-sourcing",
        name: "Diversity Sourcing Powered by ThisWay",
        props: false,
        meta: {
          localSitesRoute: null,
          show: true,
          productAuthorization: "AICM",
          icon: mdiOpenInNew,
          external: "",
          redirectWithQLogin: () => {
            return store.dispatch("auth/fetchQLoginLink", {
              returnUrl: "/account/redirecttoai4jobs",
              isEmbedded: false,
            });
          },
        },
      },
      {
        path: "diversity-sourcing/unauthorized",
        name: "Diversity Sourcing Powered by ThisWay ",
        component: DiversitySourcingUpsell,
        props: true,
        meta: {
          show: false,
        },
      },
      {
        path: "by-category",
        name: "By Category",
        props: false,
        redirect: {
          name: "Advanced Search"
        },
        meta: {
          localSitesRoute: null,
          show: false,
        },
      },
      {
        path: "resumes-advanced-search",
        name: "Advanced Search",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/resumes.asp?pagemode=2",
          show: true,
        },
      },
      {
        path: "agents",
        name: "Resume Agents",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/resumeagent/list",
          show: true,
        },
      },
    ],
  },
  {
    path: "/applicant-tracking",
    name: "Applicant Tracking",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiAccountSearch,
      show: false,
      featureFlag: FeatureFlagTypes.UseCircaATS,
    },
    children: [{
        path: "applicants",
        name: "Applicants",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/applicants.asp?pagemode=1",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "hiring-activity",
        name: "Hiring Activity",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/hiring/list",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "hiring-pipeline",
        name: "Hiring Pipeline",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/hiringpipeline/list",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "applicant-report",
        name: "Applicant Report",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/report/applicants",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "applicant-blocks",
        name: "Applicant Blocks",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/applicantblock/list",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "hiring-stages",
        name: "Hiring Stages",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/applicantfolder/list",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "disposition-codes",
        name: "Disposition Codes",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/dispositioncode/list",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "auto-reply",
        name: "Auto-Reply Message",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/account.asp?pagemode=30",
          show: true,
          productAuthorization: "LJNJP",
        },
      },
    ],
  },
  {
    path: "/abi",
    name: "Advancing Belonging & Inclusion (ABI)",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiHandHeart,
      show: false,
      productAuthorization: "ABI",
    },
    children: [{
        path: "observances/:selectedObservanceId?",
        name: "Observances",
        component: Observances,
        props: true,
        meta: {
          show: true,
          productAuthorization: "ABI",
        },
      },
      {
        path: "content/:contentId?",
        name: "Content Library",
        component: Content,
        props: true,
        meta: {
          show: true,
          productAuthorization: "ABI",
        },
      },
    ],
  },
  {
    path: "/abi/unauthorized",
    name: "Advancing Belonging & Inclusion (ABI)",
    component: ABIUpsell,
    props: false,
    meta: {
      isOpen: false,
      icon: mdiHandHeart,
      show: true,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiFileChart,
      show: true,
    },
    children: [{
        path: "reports-all",
        name: "All Reports",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/report/menu",
          show: false,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "reports-center",
        name: "Reports Center",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/report/list",
          show: false,
          productAuthorization: "LJNJP",
        },
      },
      {
        path: "document-center",
        name: "Document Center",
        component: DocumentCenter,
        props: true,
        meta: {
          show: true,
        },
      },
      {
        path: "job-performance",
        name: "Job Performance",
        component: JobPerformance,
        props: true,
        meta: {
          show: false,
          featureFlag: FeatureFlagTypes.JobPerformanceBoard,
        },
      },
      {
        path: "document-center/report-card",
        name: "Report Cards",
        component: DocumentCenter,
        props: true,
        meta: {
          show: false,
        },
      },
      {
        path: "applicant-demographics",
        name: "Applicant Demographics",
        component: ApplicantDemographics,
        props: true,
        meta: {
          show: false,
          featureFlag: FeatureFlagTypes.ApplicantDemographicReport,
        },
      },
    ],
  },
  {
    path: "/resources",
    name: "Resources",
    component: {
      render: (c) => c("router-view"),
    },
    props: false,
    meta: {
      isOpen: false,
      icon: mdiLibrary,
      show: true,
    },
    children: [{
      path: "/training-resources",
      name: "Training Resources",
      component: IFrameExternal,
      props: true,
      meta: {
        icon: mdiHelpCircle,
        isOpen: false,
        show: true,
        iFrameSource: external_iframe_link_help,
        },
      },
      {
        path: "resourceLibrary",
        name: "Resource Library",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: null,
          show: true,
          external: "https://www.circaworks.com/resources",
          icon: mdiOpenInNew,
        },
      },
      {
        path: "webinars",
        name: "Customer Webinars",
        props: true,
        meta: {
          localSitesRoute: null,
          show: true,
          external: "https://circaworks.com/webinars/",
          icon: mdiOpenInNew,
        },
      },
      {
        path: "careerevents",
        name: "Career Events",
        component: IFrame,
        props: true,
        meta: {
          localSitesRoute: "/employment-resources/careerevents",
          show: true,
          icon: null,
        },
      },
    ],
  },
  {
    path: "/silent-refresh",
    name: "SilentRefresh",
    component: SilentRefresh,
    props: true,
    meta: {
      isOpen: false,
      show: false,
    },
    children: [],
  },
  {
    path: "/notification",
    name: "Notifications",
    component: {
      render: (c) => c("router-view"),
    },
    props: true,
    meta: {
      isOpen: false,
      show: false,
    },
    children: [{
        path: "list",
        name: "Notification List",
        component: Notifications,
        props: true,
        meta: {
          show: false,
        },
      },
      {
        path: "preferences",
        name: "Notification Preferences",
        component: NotificationPreferences,
        props: true,
        meta: {
          show: false,
        },
      },
    ],
  },
  {
    path: '/outreach',
    name: 'Outreach',
    component: { render: (c) => c('router-view') },
    props: true,
    meta: {
      show: false,
      icon: mdiHumanGreetingProximity,
      featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
      productAuthorization: "OMS"
    },
    children: [
      {
        path: 'search',
        name: 'Search',
        component: OutreachManagement,
        meta: {
          show: true,
          featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
          productAuthorization: "OMS"
        }
      },
      {
        path: 'community-partners/:id',
        name: 'Community Partners',
        component: OutreachManagement,
        meta: {
          show: false,
          hidden: true,
          featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
          productAuthorization: "OMS"
        }
      },
      {
        path: 'contact-profiles/:id',
        name: 'Contact Profiles',
        component: OutreachManagement,
        meta: {
          show: false,
          hidden: true,
          featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
          productAuthorization: "OMS"
        }
      },
      {
        path: 'lists',
        name: 'Lists',
        component: OutreachManagement,
        meta: {
          featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
          productAuthorization: "OMS"
        }
      },
      {
        path: 'lists/:id',
        name: 'List',
        component: OutreachManagement,
        meta: {
          show: false,
          hidden: true,
          featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
          productAuthorization: "OMS"
        }
      },
      {
        path: 'activities',
        name: 'Activities',
        component: OutreachManagement,
        meta: {
          show: true,
          featureFlag: FeatureFlagTypes.NewOutreachManagementSystem,
          productAuthorization: "OMS"
        }
      },
      {
        path: ":route?/unauthorized",
        name: "Outreach",
        component: OMSUpsell,
        props: true,
        meta: {
          isOpen: false,
          icon: mdiHumanGreetingProximity,
          show: false,
    },
  },
    ]
  },
  {
    path: "/help",
    name: "Help",
    component: HelpPage,
    props: true,
    meta: {
      icon: mdiHelpCircle,
      isOpen: false,
      show: true,
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  checkAuthorization(to, next);

  if (to.path == "/impersonate") {
    store.commit("auth/clearSelectedCompany");
    await store.dispatch("auth/clearUserStore");
    await store.dispatch("auth/signIn");
    store.commit("auth/setImpersonating", true);
    next({
      name: "Home",
    });
  }

  await store.dispatch("auth/fetchUser");

  if (store.getters["auth/isAuthenticated"]) {
    if (appcues_account_id) identifyUserForAppcues();
    if (heap_account_id && !store.getters["auth/getImpersonating"]) identifyUserForHeap();

    next();
  } else if (to.path == "/auth-callback") {
    let user = await store.dispatch("auth/completeSignIn");
    if (user.state?.returnPath)
      next({
        path: user.state.returnPath,
      });
    else
      next({
        name: "Home",
      });
  } else {
    await store.dispatch("auth/signIn");
    next();
  }
});

const defaultTitle = "Diversity Catalyst";
router.afterEach((to) => {
  if (!to.params.preventIframeReload) {
    store.commit("local-sites-iframe/reloadIFrame");
  }
  Vue.nextTick(() => {
    if (to.name) {
      document.title = `${to.name} | ${defaultTitle}`;
    } else {
      document.title = defaultTitle;
    }
  });
});

if (gtag_id != undefined) {
  Vue.use(
    VueGtag, {
      config: {
        id: gtag_id,
      },
    },
    router
  );
}

if (hotjar_id != undefined) {
  Vue.use(Hotjar, {
    id: hotjar_id,
    isProduction: isProduction,
  });
}

Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: apm_service_name,
    serverUrl: apm_server_uri,
    secretToken: "",
  },
});

function identifyUserForHeap() {
  let selectedCompany = store.getters["auth/getSelectedCompany"];
  let prodAuths = getProductAuthsForAnalytics(selectedCompany);
  let user = store.getters["auth/getUser"];
  try {
    window.heap.identify(
      `${store.getters["auth/getUserId"].toUpperCase()}:${selectedCompany.id.toUpperCase()}`
    );
  } catch (error) {
    window.heap.track('identify_failed', {
      identity_value: store.getters["auth/getUserId"],
      error_message: error,
    });
  }

  window.heap.addUserProperties({
    "productAuthorizations": `${prodAuths}`,
    "amsId": selectedCompany.amsId,
    "userRole": user.userRole,
    "salesforceId": selectedCompany.salesforceId,
    "email": user.email,
    "name": user.fullName,
  });
}

function identifyUserForAppcues() {
  let selectedCompany = store.getters["auth/getSelectedCompany"];
  let prodAuths = getProductAuthsForAnalytics(selectedCompany);

  window.Appcues?.identify(store.getters["auth/getUserEmail"], {
    fullName: store.getters["auth/getUserFullName"],
    email: store.getters["auth/getUserEmail"],
    companyName: selectedCompany.name,
    subscriptionType: selectedCompany.subscriptionType,
    productAuthorization: prodAuths,
    created_at: store.getters["auth/getUserCreatedDate"],
    Account_Manager: selectedCompany.amName,
    Account_Manager_Email: selectedCompany.amEmail,
  });
}

function checkAuthorization(to, next) {
  let authorized = true;

  if ("productAuthorization" in to.meta) {
    const prodAuthName = to.meta.productAuthorization;

    //if it's ljnjp look for priorprodauth status, else look for current
    const getterName =
      prodAuthName == "LJNJP" ?
      "auth/getPriorProductAuth" :
      "auth/getProductAuth";

    authorized = store.getters[getterName](prodAuthName);
  }
  if ("featureFlag" in to.meta && authorized) {
    authorized = store.getters["auth/getFeatureFlag"](to.meta.featureFlag);
  }

  if (authorized) {
    next();
  } else {
    next({
      path: to.path + "/unauthorized",
    });
  }
}

export default router;