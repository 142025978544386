import { mixins, Doughnut } from "vue-chartjs";
const { reactiveProp } = mixins;
let options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: "Gender Breakdown",
  },
  ticks: {
    precision: 0,
  },
  legend: {
    display: true,
    labels: {
      fontStyle: "bold",
    },
  },
  plugins: {
    datalabels: {
      formatter: function(value, context) {
        return value > 0
          ? context.chart.data.labels[context.dataIndex] +
              ": " +
              context.chart.data.datasets[0].data[context.dataIndex] +
              "%"
          : "";
      },
      color: ["white", "yellow", "blue", "black"],
    },
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        return (
          data["labels"][tooltipItem["index"]] +
          ": " +
          data["datasets"][0]["data"][tooltipItem["index"]] +
          "%"
        );
      },
    },
  },
};

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, options);
  },
  watch: {
    //add watcher to tear down chart because options
    //is not reactive (otherwise we'd have to declare it in state )
    chartData() {
      this.renderChart(this.chartData, options);
    },
  },
};
