<template>
  <v-menu
    v-model="menuOpen"
    offset-y
    right
    nudge-bottom="10"
    :close-on-content-click="false"
    :attach="'#notificationMenuAnchor'"
    :left="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        aria-label="notification menu"
        v-bind="attrs"
        v-on="on"
        height="40"
        width="40"
        color="white"
        fab
        class="ml-3"
      >
        <v-badge
          :content="getUnreadNotifications.length"
          :value="getUnreadNotifications.length > 0"
          color="red"
          overlap
        >
          <v-icon>{{ icon }}</v-icon></v-badge
        >
      </v-btn>
    </template>
    <v-container class="white">
      <notifications-list
        dismissable
        class="notifications-list"
        v-model="recentUnreadNotifications"
        @notificationClicked="menuOpen = false"
      />
      <v-divider></v-divider>
      <div class="d-flex justify-space-between">
        <v-btn
          class="ma-3 notification-btn"
          @click="handleViewAllClick"
          color="primary"
          >View all</v-btn
        >
        <v-btn
          class="ma-3 notification-btn"
          :disabled="recentUnreadNotifications.length == 0"
          @click="
            setNotificationsRead(recentUnreadNotifications.map((n) => n.id))
          "
          color="secondary"
          >Mark all as read</v-btn
        >
      </div>
    </v-container>
  </v-menu>
</template>

<style scoped>
.notifications-list {
  width: 450px;
}
.notification-btn {
  width: 200px;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { mdiBellOutline } from "@mdi/js";
import NotificationsList from "@/components/Notifications/NotificationsList";

export default {
  name: "NotificationMenu",
  components: { NotificationsList },
  data() {
    return {
      icon: mdiBellOutline,
      menuOpen: false,
    };
  },
  computed: {
    ...mapGetters("notification", ["getUnreadNotifications"]),
    recentUnreadNotifications() {
      return this.getUnreadNotifications.slice(0, 10);
    },
  },
  methods: {
    ...mapActions("notification", [
      "fetchUnreadNotifications",
      "setNotificationsRead",
    ]),
    handleViewAllClick() {
      this.menuOpen = false;
      this.$router.push({ path: "/notification/list" });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US");
    },
  },
  async created() {
    await this.fetchUnreadNotifications();
  },
};
</script>