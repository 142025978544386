import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import { i18n } from './i18n';
import { appcues_account_id } from "@/config/app-config";
import Maska from 'maska'
Vue.use(Maska)

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App),
  watch: {
    "$route": () => {
      if (appcues_account_id) {
        window.Appcues?.page()
      }
    }
  }
}).$mount('#app');