<template>
  <v-container>
    <div id="circa_content">
        <v-row>
          <v-col>
            <h1>Diversity Sourcing</h1>
            <p>
              Looking to reach more targeted candidates quickly? Diversity Sourcing can connect with your Circa account so that you can access an expansive, searchable network of diversity candidates that removes job ad bias and saves time within the recruiting process.
            </p>
            <ul>
              <li>Instantly receive 300 candidates that are ranked based on how well their skills match your job description, even if they do not directly apply. </li>
              <li>Enjoy a network of 169 million underrepresented candidates from over 8,500+ diversity organizations with more than 13M+ veterans.  </li>
              <li>Your job descriptions can be reviewed to remove potential biases. </li>
              <li>Blind candidate screening with ethnicity, gender, name, education, age, or many other factors redacted</li>
              <li>Full resume and contact information available after a match and desire to learn more about the candidate</li>
            </ul>
            <br />
            <v-btn rounded
                   color="primary"
                   class="mx-auto"
                   width="80%" :href="
                     `mailto:${(getSelectedCompany.amEmail ? getSelectedCompany.amEmail : 'renewal@circaworks.com')}?subject=Diversity Sourcing Inquiry&body=I'm interested in receiving information on Circa's Diversity Sourcing.`">
              Contact Your Account Manager
            </v-btn>
          </v-col>
          <v-col align-self="center">
            <img src="https://circaworks.com/wp-content/uploads/2022/09/diversity_sourcing_image700.jpg" alt="diversity sourcing image" />
          </v-col>
        </v-row>
    </div>
  </v-container>
</template>

<style>
  @import '../../styles/Upsell.css';
</style>

<script>
  import { mapGetters } from "vuex";
  import circle from "@/assets/Circa_Circles2021.png";
  export default {
    computed:
    {
      ...mapGetters("auth", ["getSelectedCompany", "getDefaultAMEmail"])
    },
    mounted: async function () {
      if (document.getElementById("app"))
        document.getElementById(
          "app"
        ).style.backgroundImage = `url(${circle}), url(${circle})`;
    },
    destroyed() {
      if (document.getElementById("app"))
        document.getElementById("app").style.backgroundImage = "none";
    },
  }
</script>