import axios from "axios";
import { baseEmployerApiUrl, diversitycatalyst_uri } from "@/config/app-config";
import store from "@/store";

const DO_NOTHING = () => {};

export default {
  async post(endpoint, payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    try {
      if (store.getters["auth/getImpersonating"]) {
        payload.previewFeatures = true;
      }

      const res = await axios.post(baseEmployerApiUrl + endpoint, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.getters["auth/getAccessToken"]}`,
          "Access-Control-Allow-Origin": `${diversitycatalyst_uri}`,
        },
      });
      return success(res.data);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await store.dispatch("auth/clearUserStore");
        await store.dispatch("auth/signIn");
      }

      if (process.env.NODE_ENV !== "production") console.error(e);
      return error(e);
    }
  },

  async put(endpoint, payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    try {
      if (store.getters["auth/getImpersonating"]) {
        payload.previewFeatures = true;
      }

      const res = await axios.put(baseEmployerApiUrl + endpoint, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.getters["auth/getAccessToken"]}`,
          'Access-Control-Allow-Origin': `${diversitycatalyst_uri}`
        },
      });
      return success(res.data);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await store.dispatch("auth/clearUserStore");
        await store.dispatch("auth/signIn");
      }

      if (process.env.NODE_ENV !== "production") console.error(e);
      return error(e);
    }
  },

  async get(endpoint, params, { success = DO_NOTHING, error = DO_NOTHING }, noAuth = false) {
    try {
      if (store.getters["auth/getImpersonating"]) {
        params.previewFeatures = true;
      }

      const res = await axios.get(baseEmployerApiUrl + endpoint, {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: noAuth ? "" : `Bearer ${store.getters["auth/getAccessToken"]}`,
          "Access-Control-Allow-Origin": `${diversitycatalyst_uri}`,
        },
      });
      return success(res.data);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await store.dispatch("auth/clearUserStore");
        await store.dispatch("auth/signIn");
      }

      if (process.env.NODE_ENV !== "production") console.error(e);
      return error(e);
    }
  },

  async delete(endpoint, params, { success = DO_NOTHING, error = DO_NOTHING }) {
    try {
      if (store.getters["auth/getImpersonating"]) {
        params.previewFeatures = true;
      }

      let config = {
        data: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.getters["auth/getAccessToken"]}`,
          "Access-Control-Allow-Origin": `${diversitycatalyst_uri}`,
        },
      };
      
      await axios.delete(baseEmployerApiUrl + endpoint, config);
      return success();
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await store.dispatch("auth/clearUserStore");
        await store.dispatch("auth/signIn");
      }

      if (process.env.NODE_ENV !== "production") console.error(e);
      return error(e.response);
    }
  },

  async getBlob(
    endpoint,
    params,
    { success = DO_NOTHING, error = DO_NOTHING }
  ) {
    try {
      if (store.getters["auth/getImpersonating"]) {
        params.previewFeatures = true;
      }

      const res = await axios.get(baseEmployerApiUrl + endpoint, {
        params: params,
        headers: {
          Authorization: `Bearer ${store.getters["auth/getAccessToken"]}`,
          "Access-Control-Allow-Origin": `${diversitycatalyst_uri}`,
        },
        responseType: "blob",
      });
      return success(res.data);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await store.dispatch("auth/clearUserStore");
        await store.dispatch("auth/signIn");
      }

      if (process.env.NODE_ENV !== "production") console.error(e);
      return error(e);
    }
  },
};
