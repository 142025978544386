import { isProduction } from "@/config/app-config";


const state = () => ({
  key: 0
});

const getters = {
    getKey(state) {
        return state.key;
    }
};

const mutations = {
    reloadIFrame(state) {
        state.key++;
    }
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
