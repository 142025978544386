<template>
  <v-container fluid>
    <address-verification-modal
      v-model="needsAddressVerification"
      @close="handleCloseAddressVerification"
      @submitAddress="handleAddressVerification"
    />
    <loading-spinner v-if="getIsLoading"></loading-spinner>
    <v-card class="ma-6" v-else>
      <v-card-text>
        <v-form
          class="pa-3"
          v-model="isAccountInfoValid"
          :disabled="!canEditInfo"
        >
          <v-row align="center">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Company Name
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Company Name"
                v-model="companyName"
                :rules="companyNameValidation"
                single-line
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Business Address 1
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Business Address"
                v-model="street"
                :rules="streetValidation"
                single-line
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Address 2
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Address 2" v-model="street2"> </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                City, State
              </div>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="cityState"
                :loading="cityStatesLoading"
                :items="items"
                :search-input.sync="search"
                cache-items
                :item-text="getItemTextVal"
                :item-value="getItemTextVal"
                hide-no-data
                label="City, State"
                single-line
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="1" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Zip Code
              </div>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Zip Code"
                v-model="zipCode"
                :rules="zipCodeValidation"
                required
                single-line
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Business Phone
              </div>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-maska="'###-###-####'"
                label="xxx-xxx-xxxx"
                v-model="phoneNumber"
                :rules="phoneNumberValidation"
                single-line
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Website Address
              </div>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Website Address"
                v-model="website"
                :rules="websiteValidation"
                single-line
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                :href="getWebsiteLink()"
                small
                text
                color="blue"
                target="_blank"
                >Company Website
                <v-icon right dark x-small>
                  {{ icons.mdiOpenInNew }}
                </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Type of Industry
              </div>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Type of Industry"
                max-width="25rem"
                item-text="text"
                item-value="value"
                :items="industries"
                v-model="industryId"
                :rules="industryTypeValidation"
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end">
                Number of Employees
              </div>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Number of Employees"
                max-width="25rem"
                item-text="text"
                item-value="value"
                :items="numofemployees"
                v-model="companySizeId"
                :rules="companySizeValidation"
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end justify-content-center">
              <div class="text-body-1 font-weight-black align-end">
                We are a Federal Contractor
              </div>
            </v-col>
            <v-col cols="6">
              <v-radio-group row v-model="isFederalContractor">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
                <span class="v-label theme--light"
                  >(companies required to comply with OFCCP should select
                  yes)</span
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="center" class="mt-n8">
            <v-col cols="3" class="d-flex justify-end">
              <div class="text-body-1 font-weight-black align-end" id="asdf">
                We Provide Personnel To Companies
              </div>
            </v-col>
            <v-col cols="6">
              <v-radio-group row v-model="isStaffProvider">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
                <span class="v-label theme--light"
                  >(staffing and recruiting companies should select yes)</span
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n1">
            <v-col>
              <v-btn
                color="primary"
                @click="handleUpdateInfo"
                :disabled="!canEditInfo || !isAccountInfoValid"
                :loading="standardizingAddress"
                large
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";
import IndustryTypes from "@/components/Account/Info/Types/industries";
import CompanySizeTypes from "@/components/Account/Info/Types/company-sizes";
import AddressVerificationModal from "@/components/Account/Info/AddressVerificationModal";
import cities from "@/api/cities";
import { mdiOpenInNew } from "@mdi/js";
export default {
  components: {
    LoadingSpinner,
    AddressVerificationModal,
  },
  data() {
    return {
      isAccountInfoValid: false,
      industries: IndustryTypes,
      numofemployees: CompanySizeTypes,
      cityStatesLoading: false,
      items: [],
      search: null,
      icons: {
        mdiOpenInNew,
      },
      needsAddressVerification: false,
      standardizingAddress: false,
    };
  },
  async created() {
    await this.initializeForm();
  },
  watch: {
    async getSelectedCompany() {
      await this.initializeForm();
    },
    search(val) {
      // Items have already been loaded and length is 3
      if (this.items.length > 0) {
        if (val?.length < 3) {
          this.items.length = [];
        }
        return;
      } else {
        if (val?.length < 3) return;
      }

      // Items have already been requested
      if (this.cityStatesLoading) return;

      val && val !== this.select && this.querySelections(val);
    },
  },

  methods: {
    getWebsiteLink() {
      const protocol = "https://";
      let protWebsite = this.website;
      if (!this.website.startsWith(protocol)) {
        protWebsite = `${protocol}${this.website}`;
      }
      return protWebsite;
    },
    async initializeForm() {
      this.items = [];
      await this.fetchCompanyInfo();
      this.items.push(this.getCityState);
    },
    getItemTextVal(item) {
      return `${item.city}, ${item.stateCode}`;
    },
    async querySelections(v) {
      this.cityStatesLoading = true;
      // Simulated ajax query
      await cities.getCities(v, {
        success: (cities) => {
          this.items = cities;
        },
        error: () => {},
      });
      this.cityStatesLoading = false;
    },

    ...mapActions("company-info", [
      "fetchCompanyInfo",
      "updateInfo",
      "standardizeAddress",
    ]),
    ...mapMutations("company-info", [
      "setCompanyName",
      "setPhoneNumber",
      "setWebsite",
      "setIsFederalContractor",
      "setIsStaffProvider",
      "setIndustryId",
      "setCompanySizeId",
      "setStreet",
      "setStreet2",
      "setCityState",
      "setZipCode",
      "setLatitude",
      "setLongitude",
    ]),

    async handleUpdateInfo() {
      if (this.isAccountInfoValid) {
        try {
          this.standardizingAddress = true;
          var result = await this.standardizeAddress();
          this.standardizingAddress = false;

          if (result) {
            this.needsAddressVerification = result;
          } else {
            await this.updateInfo({
              useStandardAddress: false,
              addressVerificationStatus: 4, //auto accepted
            });
          }
        } catch {
          this.standardizingAddress = false;
        }
      }
    },
    async handleAddressVerification(useStandard) {
      this.needsAddressVerification = false;
      await this.updateInfo({
        useStandardAddress: useStandard,
        addressVerificationStatus: useStandard ? 1 : 2,
      });
    },
    handleCloseAddressVerification() {
      this.needsAddressVerification = false;
    },
  },
  computed: {
    ...mapGetters("company-info", [
      "getCompanyName",
      "getPhoneNumber",
      "getWebsite",
      "getIsFederalContractor",
      "getIsStaffProvider",
      "getIndustryId",
      "getCompanySizeId",
      "getStreet",
      "getStreet2",
      "getCityState",
      "getZipCode",
      "getLatitude",
      "getLongitude",
      "getIsLoading",
      "getStandardAddress",
    ]),
    ...mapGetters("auth", ["getSelectedCompany", "getUserRoleId"]),

    canEditInfo() {
      return [1, 2].includes(this.getUserRoleId);
    },
    industryTypeValidation() {
      return [
        (value) => {
          return (value && value >= 15) || "Industry is required";
        },
      ];
    },
    companySizeValidation() {
      return [
        (value) => {
          return (value && value >= 1130) || "Company Size is required";
        },
      ];
    },
    zipCodeValidation() {
      return [
        (zip) => {
          return zip?.length >= 5 || "Zip Code is required";
        },
        (zip) => {
          return zip?.length <= 15 || "Too many characters";
        },
      ];
    },
    companyNameValidation() {
      return [
        (value) => {
          return value?.length > 0 || "Company Name is required";
        },
      ];
    },
    streetValidation() {
      return [
        (value) => {
          return value?.length > 0 || "Business Address is required";
        },
      ];
    },
    cityValidation() {
      return [
        (value) => {
          return value?.length > 0 || "City is required";
        },
      ];
    },
    stateCodeValidation() {
      return [
        (value) => {
          return value?.length > 0 || "City, State is required";
        },
      ];
    },
    phoneNumberValidation() {
      return [
        (value) => {
          return value?.length > 0 || "Phone Number is required";
        },
      ];
    },
    websiteValidation() {
      return [
        (value) => {
          return value?.length > 0 || "Website is required";
        },
      ];
    },
    companyName: {
      get() {
        return this.getCompanyName;
      },
      set(value) {
        this.setCompanyName(value);
      },
    },
    phoneNumber: {
      get() {
        return this.getPhoneNumber;
      },
      set(value) {
        this.setPhoneNumber(value);
      },
    },
    website: {
      get() {
        return this.getWebsite;
      },
      set(value) {
        this.setWebsite(value);
      },
    },
    isFederalContractor: {
      get() {
        return this.getIsFederalContractor;
      },
      set(value) {
        this.setIsFederalContractor(value);
      },
    },
    isStaffProvider: {
      get() {
        return this.getIsStaffProvider;
      },
      set(value) {
        this.setIsStaffProvider(value);
      },
    },
    industryId: {
      get() {
        return this.getIndustryId;
      },
      set(value) {
        this.setIndustryId(value);
      },
    },
    companySizeId: {
      get() {
        return this.getCompanySizeId;
      },
      set(value) {
        this.setCompanySizeId(value);
      },
    },
    street: {
      get() {
        return this.getStreet;
      },
      set(value) {
        this.setStreet(value);
      },
    },
    street2: {
      get() {
        return this.getStreet2;
      },
      set(value) {
        this.setStreet2(value);
      },
    },
    cityState: {
      get() {
        return this.getCityState;
      },
      set(value) {
        this.setCityState(value);
      },
    },
    zipCode: {
      get() {
        return this.getZipCode;
      },
      set(value) {
        this.setZipCode(value);
      },
    },
  },
};
</script>

<style>
.align-end {
  text-align: end;
}
</style>