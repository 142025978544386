<template>
  <v-container fluid class="system-pref">
    <span class="text-center"
      ><h1 class="mb-6">Notification Preferences</h1></span
    >

    <notification-preferences-list></notification-preferences-list>
  </v-container>
</template>

<script>
import NotificationPreferencesList from "@/components/Notifications/Preferences/NotificationPreferencesList";

export default {
  components: { NotificationPreferencesList },
};
</script>

<style scoped lang="scss">
.system-pref {
  padding: 2rem 4rem;
}
</style>