<template>
  <v-container>
    <div id="circa_content">
      <v-row>
        <v-col>
          <h1>DiversityJobs.com</h1>
          <p>
            As a customer your jobs are currently posted to Circa's 600+ local and diverse job boards. Enhance your reach for each job posting by posting to <a href="https://www.diversityjobs.com/diversity-insights/">DiversityJobs</a>, powered by Circa. DiversityJobs smart technology delivers job postings from thousands of employers who care about diversity and are looking to connect with job seekers looking for a diverse workplace.
          </p>
          <ul>
            <li>
              Optimized for <b>Google for Jobs</b> to prominently display your jobs in Google Search, which has an average 34% apply click conversion rate on DiversityJobs
            </li>
            <li>Reach more underrepresented groups with our network of niche job board sites for Black Americans, Latinx, Asians, Native Americans, Women, Veterans, People with Disabilities, and members of the LGBTQIA+ Community. </li>
            <li>Utilize our Diversity Employer Badge in branding, advertising and job promotions to showcase your commitment to diversity hiring.</li>
            <li>Through the power of targeted marketing, we find candidates for you through the utilization of programmatic advertising to drive more applicants to you.</li>
          </ul>
          <br />
          <v-btn rounded
                 color="primary"
                 class="mx-auto"
                 width="80%" :href="
                     `mailto:${(getSelectedCompany.amEmail ? getSelectedCompany.amEmail : 'renewal@circaworks.com')}?subject=Diversity Jobs Inquiry&body=I'm interested in receiving information on Diversity Jobs.`">
            Contact Your Account Manager
          </v-btn>
        </v-col>
          <v-col align-self="center">
            <img src="https://circaworks.com/wp-content/uploads/2022/09/dj_image700.jpg" alt="djcom image" />
          </v-col>
        </v-row>
</div>
  </v-container>
</template>

<style>
  @import '../../styles/Upsell.css';
</style>

<script>
  import { mapGetters } from "vuex";
  import circle from "@/assets/Circa_Circles2021.png";
  export default {
    computed:
    {
      ...mapGetters("auth", ["getSelectedCompany", "getDefaultAMEmail"])
    },
    mounted: async function () {
      if (document.getElementById("app"))
        document.getElementById(
          "app"
        ).style.backgroundImage = `url(${circle}), url(${circle})`;
    },
    destroyed() {
      if (document.getElementById("app"))
        document.getElementById("app").style.backgroundImage = "none";
    },
  }
</script>