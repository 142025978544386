<template>
  <div>
    <v-snackbar
      timeout="10000"
      :value="errorMessage"
      @input="setDisplayErrorMessage"
      color="red"
      bottom
      right
      elevation="24"
      >{{ getErrorMessageText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="hideErrorMessage">
          <v-icon>{{ closeIcon }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      timeout="10000"
      :value="successMessage"
      @input="setDisplaySuccessMessage"
      color="green"
      bottom
      right
      elevation="24"
      >{{ getSuccessMessageText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="hideSuccessMessage">
          <v-icon>{{ closeIcon }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { mdiClose } from "@mdi/js";

export default {
  components: {},
  data: () => ({
    closeIcon: mdiClose,
  }),
  computed: {
    ...mapGetters("app-messages", [
      "getDisplaySuccessMessage",
      "getDisplayErrorMessage",
      "getSuccessMessageText",
      "getErrorMessageText",
    ]),
    successMessage: {
      get() {
        return this.getDisplaySuccessMessage;
      },
      set(value) {
        this.setDisplaySuccessMessage = value;
      },
    },
    errorMessage: {
      get() {
        return this.getDisplayErrorMessage;
      },
      set(value) {
        this.setDisplayErrorMessage = value;
      },
    },
  },
  methods: {
    ...mapMutations("app-messages", [
      "displaySuccessMessage",
      "displayErrorMessage",
      "setDisplaySuccessMessage",
      "setDisplayErrorMessage",
    ]),
    hideSuccessMessage() {
      this.setDisplaySuccessMessage(false);
    },
    hideErrorMessage() {
      this.setDisplayErrorMessage(false);
    },
  },
};
</script>
