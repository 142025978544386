<template>
  <v-form ref="jobScreenshotForm" v-model="formInfo.isValid">
    <v-container>
      <v-row>
        <v-col></v-col>
        <v-col cols="12" md="2">
          <v-text-field
            type="number"
            min="1"
            v-model="screenshotFilters.jobID"
            label="Job ID"
            outlined
            dense
            @keyup.enter="handleRetrieveScreenshots"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="screenshotFilters.jobTitle"
            label="Title"
            outlined
            dense
            @keyup.enter="handleRetrieveScreenshots"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <from-to-date-input
            class="jobScreenshotFromToDate"
            v-model="datePickerFilter"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            @click="handleRetrieveScreenshots"
            :disabled="!formInfo.isValid"
            color="primary"
            large
            text
            class="retrieve-screenshots-btn"
            v-if="$vuetify.breakpoint.xs"
          >
            Search
          </v-btn>
          <v-btn
            @click="handleRetrieveScreenshots"
            :disabled="!formInfo.isValid"
            color="primary"
            small
            left
            v-else
            class="retrieve-screenshots-icon-btn"
          >
            <v-icon dense>{{ icons.search }}</v-icon>
            Search
            
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import FromToDateInput from "@/components/form/FromToDateInput.vue";
import { mapActions, mapGetters } from "vuex";
import { mdiFileSearchOutline } from "@mdi/js";
import { isEmpty } from "lodash";

export default {
  components: { FromToDateInput },
  data: () => ({
    screenshotFilters: {
      jobID: null,
      location: "",
      jobTitle: "",
    },
    icons: {
      search: mdiFileSearchOutline,
    },
    formInfo: {
      isValid: true,
    },
    datePickerFilter: {
      fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
        .toISOString()
        .substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      validationRules: {
        fromDateRules: [
          (v) => {
            return !isEmpty(v) || "From Date is required";
          },
        ],
        toDateRules: [
          (v) => {
            return !isEmpty(v) || "To Date is required";
          },
        ],
      },
    },
  }),
  computed: {
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
  created() {
    this.handleRetrieveScreenshots();
  },
  methods: {
    ...mapActions("job-screenshots-data", ["retrieveScreenshots"]),
    handleRetrieveScreenshots() {
      const payload = {
        CompanyId: this.getSelectedCompany.id,
        StartDate: this.datePickerFilter.fromDate,
        EndDate: this.datePickerFilter.toDate,
        JobTitle: this.screenshotFilters.jobTitle,
      };
      if (
        this.screenshotFilters.jobID &&
        !isNaN(this.screenshotFilters.jobID)
      ) {
        payload.JobId = this.screenshotFilters.jobID;
      }
      this.retrieveScreenshots(payload);
    },
  },
};
</script>
<style scoped>
.jobScreenshotFromToDate {
  position: relative;
  bottom: 1.25rem;
}
.retrieve-screenshots-btn {
  position: relative;
  right: 1.25rem;
}
@media screen and (max-width: 600px) {
  .retrieve-screenshots-btn {
    position: relative;
    right: 0.1rem;
    bottom: 3rem;
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .retrieve-screenshots-icon-btn {
    position: relative;
    bottom: 3rem;
  }
}
@media screen and (min-width: 960px) and (max-width: 1950px) {
  .retrieve-screenshots-icon-btn {
    position: relative;
    right: 1rem;
  }
}
@media screen and (min-width: 1950px) {
  .jobScreenshotFromToDate {
    position: relative;
    right: 2rem;
  }
  .retrieve-screenshots-icon-btn {
    position: relative;
   right: 6rem;
  }
}
</style>