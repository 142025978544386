import { HorizontalBar, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    extends: HorizontalBar,
    plugins: [ChartDataLabels],
    mixins: [mixins.reactiveProp],
    props: ['chartData', 'options'],
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
};