import userActivity from "@/api/user-activities/user-activity";
import { isProduction } from "@/config/app-config";

const UserActivityRecorder = {
  recordUserActivity: async (
    userId,
    companyId,
    activityTypeId,
    { key, value }
  ) => {
    const payload = {
      UserId: userId,
      CompanyId: companyId,
      UserActivityTypeId: activityTypeId,
      Key: key,
      Value: value,
    };
     await userActivity.postUserActivity(payload, {
      error: (e) => {
        if (!isProduction) {
          console.log(e);
        }
      },
    });
  },
};

export default UserActivityRecorder;
