import { isProduction } from "@/config/app-config";
import users from "@/api/users";

const state = () => ({
  stage: 1,
  isUserFormValid: false,
  stepperUser: null,
  loading: false,
  userToAddExists: false,
});

const getters = {
  getStage(state) {
    return state.stage;
  },
  getIsUserFormValid(state) {
    return state.isUserFormValid;
  },
  getStepperUser(state) {
    return state.stepperUser;
  },
  getLoading(state) {
    return state.loading;
  },
  getUserToAddExists(state) {
    return state.userToAddExists;
  },
};

const mutations = {
  setStage(state, val) {
    state.stage = val;
  },
  setIsUserFormValid(state, val) {
    state.isUserFormValid = val;
  },
  setStepperUser(state, val) {
    state.stepperUser = val;
  },
  setLoading(state, val) {
    state.loading = val;
  },
  setUserToAddExists(state, val) {
    state.userToAddExists = val;
  },
};

const actions = {
  async fetchAndSetUserByEmail({ commit }, email) {
    commit("setLoading", true);
    return await users.getUserByEmail(email, {
      success: (data) => {
        if (data) {
          commit("setUserToAddExists", true);
          commit("setStepperUser", { ...data, userRole: 3 });
        } else {
          commit("setUserToAddExists", false);
          commit("setStepperUser", {
            firstName: "",
            lastName: "",
            email,
            userRole: 3,
          });
        }
        commit("setStage", 2);

        commit("setLoading", false);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while retrieving authorized users",
          {
            root: true,
          }
        );
        commit("setLoading", true);
      },
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
