<template>
  <v-container class="container--fluid document-center">
    <span class="text-center"><h1 tabindex="0" autofocus>Document Center</h1></span>
    <v-card class="pa-3">
      <v-tabs v-model="selectedTab">
        <v-tab>Report Card</v-tab>
        <v-tab>Job Screenshots</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <ReportCard :key="getSelectedCompany.id" />
        </v-tab-item>
        <v-tab-item>
          <JobScreenshots :key="getSelectedCompany.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import ReportCard from "@/components/DocumentCenter/ReportCard.vue";
import JobScreenshots from "@/components/DocumentCenter/JobScreenshots.vue";
import { mapGetters } from "vuex";

export default {
  components: { ReportCard, JobScreenshots },
  data: () => ({
    selectedTab: null,
  }),
  computed: {
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
};
</script>
<style scoped lang="scss">
h1 {
  margin-bottom: 2rem;
}
.document-center {
  padding: 2rem 4rem;
}
.heading1 {
  font-weight: 500;
  font-size: 1.5rem !important;
}
</style>

