<template>
  <v-container @keydown.enter="handleAdd">
    <v-card flat>
      <v-card-title
        v-if="userExists"
        class="text-h5 justify-center mt-n5"
      >
        Add Existing User To Company
      </v-card-title>
      <v-card-title v-else class="text-h5 justify-center mt-n5">
        Add New User To Company
      </v-card-title>
      <v-card-text class="mb-10">
        <v-form ref="form" v-model="isUserFormValid">
          <v-row class="mb-n8">
            <v-col cols="6">
              <v-select
                label="User Type"
                max-width="25rem"
                item-text="text"
                item-value="value"
                v-model="user.userRole"
                :items="userRoles"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="user.email"
                label="Email"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            ><v-col cols="6">
              <v-text-field
                v-model="user.firstName"
                label="First Name"
                :rules="firstNameValidation"
                :disabled="userExists"
              ></v-text-field> </v-col
            ><v-col cols="6">
              <v-text-field
                v-model="user.lastName"
                label="Last Name"
                :rules="lastNameValidation"
                :disabled="userExists"
              ></v-text-field> </v-col
          ></v-row>
          <user-permissions-component
            :isCurrentUser="false"
            :user="user"
            :value="value"
          ></user-permissions-component>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiEyeOff } from "@mdi/js";
import UserPermissionsComponent from "@/components/Account/Users/UserPermissionsComponent.vue";

export default {
  components: {
    UserPermissionsComponent,
  },

  name: "UserModal",
  props: {
    value: Boolean,
    user: Object,
    stage: Number,
    userExists: Boolean,
    currentUser: Boolean,
  },
  data() {
    return {
      userRoles: [
        { text: "User", value: 3 },
        { text: "Administrator", value: 2 },
      ],
      icons: {
        pass: mdiCheckCircle,
        fail: mdiCloseCircle,
        visible: mdiEye,
        notVisible: mdiEyeOff,
      },
      isUserFormValid: false,
    };
  },
  watch: {
    user(newValue) {
      //use to initialize
      if (!this.userExists) {
        this.$refs.form.resetValidation();
      }
      if (newValue) {
        console.log(newValue);
      }
    },
    stage(){
      this.$refs.form.resetValidation();
    },
    isUserFormValid(val) {
      this.$emit("isFormValid", val);
    },
    
  },

  methods: {
    ...mapActions("auth", ["signIn", "clearUserStore"]),
    async handleAdd() {
      if (this.$refs.form.validate()) {
        this.$emit("enter");
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["usesSSO", "getUserId"]),

    firstNameValidation() {
      return [
        (name) => {
          return name.length > 0 || "First name is required";
        },
        (name) => {
          return name.length <= 40 || "Too many characters";
        },
      ];
    },
    lastNameValidation() {
      return [
        (name) => {
          return (name && name.length > 0) || "Last name is required";
        },
        (name) => {
          return (name && name.length <= 50) || "Too many characters";
        },
      ];
    },
    userRoleValidation() {
      return [
        (type) => {
          return type?.length > 0 || "User type is required";
        },
      ];
    },
  },
};
</script>

<style></style>
