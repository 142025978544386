import http from './employer-http';

const DO_NOTHING = () => { };

export default {
    getVersaPayUrl(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get('/api/Orders/VersaPayUrl', { companyId: payload }, {
            success,
            error
        });
    }
};