import { UserManager, WebStorageStateStore } from "oidc-client";
import store from "@/store";
import { getSystemTime } from "@/utilities"
import {
  authority,
  client_id,
  redirect_uri,
  response_type,
  scope,
  post_logout_redirect_uri,
  silent_redirect_uri
} from "./config/app-config";

export class AuthService {
  constructor() {
    const settings = {
      authority: authority,
      client_id: client_id,
      redirect_uri: redirect_uri,
      response_type: response_type,
      scope: scope,
      monitorSession: false,
      post_logout_redirect_uri: post_logout_redirect_uri,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true,
      silent_redirect_uri: silent_redirect_uri,
      clockService: new DCClockService()
    };
    this.userManager = new UserManager(settings);
  }
  

  getUser() {
    return this.userManager.getUser();
  }

  signInRedirect(currentState) {
    return this.userManager.signinRedirect({state: currentState});
  }

  completeSignIn() {
    return this.userManager.signinRedirectCallback();
  }

  completeSilentRefresh()
  {
    return this.userManager.signinSilentCallback();
  }

  getAccessToken() { 
    return this.userManager.getUser().then((data) => {
      return data.access_token;
    });
  }

  async clearUser() {
    return await this.userManager.removeUser();
  }

  async logOut() {
    store.commit('auth/setImpersonating', false);
    var user = await this.userManager.getUser();
    await this.userManager.signoutRedirect({
      id_token_hint: user.id_token,
    });
  }
}

class DCClockService{
  async getEpochTime() {
    return await getSystemTime();
  }
}