import http from "./api/employer-http";

function addHeapToApp() {
  var heapScript = document.createElement("script");
  var heapScriptText = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};
    var r=document.createElement("script");
    r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
    var a=document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(r,a);
    for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};`
  var text = document.createTextNode(heapScriptText);
  heapScript.appendChild(text);
  document.head.appendChild(heapScript);
}

// msToBlob is vendor prefixed and is only available on IE
// other variants of toBlob are deprecated / not recommended
// Download attribute does not exist IE
function buildDownloadLink({ title, htmlElement, blob }) {
  if (blob) {
    window.navigator.msSaveOrOpenBlob(blob, `${title}.png`);
  } else {
    let data = htmlElement.toDataURL("image/png", 1.0);
    let downloadLink = document.createElement("a");
    downloadLink.download = title;
    downloadLink.href = data;
    downloadLink.click();
  }
}

const DO_NOTHING = () => {};

async function getSystemTime() {
  return await http.get(
    "/api/timeutility",
    {},
    {
      success: DO_NOTHING,
      error: DO_NOTHING,
    },
    true
  );

}
function getProductAuthsForAnalytics(selectedCompany) {
  let hasOFCCP = false;
  let prodAuths = [];

  selectedCompany.productAuthorizations.forEach((a) => {
    if (a.name.includes("OFCCP")) {
      hasOFCCP = true;
    } else {
      prodAuths.push(a.name);
    }
  });

  if (hasOFCCP) {
    prodAuths.push("OFCCP");
  }

  var prodAuthList = "";
  prodAuths.forEach((a) => {
    prodAuthList = prodAuthList + a + " ";
  });
  return prodAuthList;
}

export { addHeapToApp, buildDownloadLink, getSystemTime, getProductAuthsForAnalytics };
