<template>
  <v-container class="container--fluid content">
    <span class="text-center"><h1 class="mb-6">Content Library</h1></span>
    <content-list :contentId="contentId"></content-list>
  </v-container>
</template>
<script>
import ContentList from "@/components/ABI/Content/ContentList.vue";

export default {
  props: ["contentId"],
  components: { ContentList },
};
</script>
<style scoped lang="scss">
.content {
  padding: 2rem 4rem;
}
</style>