import { isProduction } from "@/config/app-config";
import users from "@/api/users";

const state = () => ({
  companyUsers: [],
});

const getters = {
  getUsers(state) {
    return state.companyUsers;
  },
};
const mutations = {
  setUsers(state, users) {
    state.companyUsers = users;
  },
};
const actions = {
  async addNewUser({ rootGetters, dispatch }, user) {
    var company = rootGetters["auth/getSelectedCompany"];
    var authorizingUser = rootGetters["auth/getUserId"];
    var oAuth = rootGetters["auth/getUser"].id_token;
    var hasSSO = rootGetters["auth/usesSSO"];
    var payload = {
      companyId: company.id,
      companyLegacyId: company.legacyId,
      companyName: company.name,
      authorizingUser: authorizingUser,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      confirmEmail: user.email,
      has_SSO: hasSSO == "true" ?? false,
      userRole: user.userRole,
      accessJobs: user.accessJobs,
      accessResumes: user.accessResumes,
      accessApplicants: user.accessApplicants,
      accessOFCCPAccountManagement: user.accessOFCCPAccountManagement,
      accessReports: user.accessReports,
      editAllJobPostings: user.editAllJobPostings,
      accessDiversitySourcing: user.accessDiversitySourcing,
      oAuthToken: oAuth,
    };
    return await dispatch("addUser", payload);
  },

  async addUser({ commit }, payload) {
    return await users.addUser(payload, {
      success: () => {
        commit(
          "app-messages/displaySuccessMessage",
          "New user added successfully!",
          {
            root: true,
          }
        );
        return true;
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while adding new user",
          {
            root: true,
          }
        );
        return false;
      },
    });
  },
  async fetchLoggedInUserComp({ commit, rootGetters }) {
    const companyId = rootGetters["auth/getSelectedCompany"].id;
    const loggedInUserId = rootGetters["auth/getUserId"];

    const payload = {
      companyId,
      userId: loggedInUserId,
    };

    return await users.getUser(payload, {
      success: (data) => {
        if (data && data.length == 1) {
          //since we're specifying user, there should only be one element
          return data[0];
        }
        return null;
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while retrieving company user",
          {
            root: true,
          }
        );
      },
    });
  },
  async fetchUserPermissions({ rootGetters, commit }, userId) {
    const companyId = rootGetters["auth/getSelectedCompany"].id;
    var payload = {
      companyId: companyId,
      userId: userId,
    };
    return await users.getUserPermissions(payload, {
      success: (data) => {
        if (data) {
          return data;
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while retrieving user permissions",
          {
            root: true,
          }
        );
        return null;
      },
    });
  },
  async fetchUsers({ commit, rootGetters }) {
    const companyId = rootGetters["auth/getSelectedCompany"].id;
    await users.getUsers(companyId, {
      success: (data) => {
        if (data) {
          commit("setUsers", data);
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while retrieving authorized users",
          {
            root: true,
          }
        );
      },
    });
  },
  async saveUser({ rootGetters, dispatch }, user) {
    var payload = null;
    var companyId = rootGetters["auth/getSelectedCompany"].id;
    var authorizingUser = rootGetters["auth/getUserId"].toUpperCase();
    var oAuth = rootGetters["auth/getUser"].id_token;
    var companyLegacyId = rootGetters["auth/getSelectedCompany"].legacyId;
    if (authorizingUser == user.id.toUpperCase()) {
      payload = {
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        confirmEmail: user.email,
        currentPassword: user.currentPassword ?? "",
        newPassword: user.newPassword ?? "",
        confirmPassword: user.confirmPassword ?? "",
        oAuthToken: oAuth,
      };
      return await dispatch("updateUser", payload);
    } else {
      payload = {
        oAuthToken: oAuth,
        userId: user.id,
        userRole: user.userRole,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        confirmEmail: user.email,
        currentPassword: user.currentPassword ?? "",
        newPassword: user.password ?? "",
        confirmPassword: user.confirmPassword ?? "",
        authorizingUser: authorizingUser,
        companyId: companyId,
        companyLegacyId: companyLegacyId,
        accessJobs: user.accessJobs,
        accessResumes: user.accessResumes,
        accessApplicants: user.accessApplicants,
        accessOFCCPAccountManagement: user.accessOFCCPAccountManagement,
        accessReports: user.accessReports,
        editAllJobPostings: user.editAllJobPostings,
        accessDiversitySourcing: user.accessDiversitySourcing,
      };
      return await dispatch("updateCompanyUser", payload);
    }
  },
  async updateCompanyUser({ commit }, payload) {
    return await users.updateCompanyUser(payload, {
      success: () => {
        commit(
          "app-messages/displaySuccessMessage",
          "User updated successfully!",
          {
            root: true,
          }
        );
        return true;
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while updating user from company",
          {
            root: true,
          }
        );
        return false;
      },
    });
  },
  async updateStatus({ commit, rootGetters }, { userId, status }) {
    const companyId = rootGetters["auth/getSelectedCompany"].id;
    const companyLegacyId = rootGetters["auth/getSelectedCompany"].legacyId;
    const idToken = rootGetters["auth/getUser"].id_token;
    const payload = {
      userId,
      companyId,
      status,
      idToken,
      companyLegacyId,
    };

    await users.toggleUserStatus(payload, {
      success: () => {},
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while updating user status",
          {
            root: true,
          }
        );
      },
    });
  },

  async updateUser({ commit }, payload) {
    return await users.updateUser(payload, {
      success: () => {
        commit("auth/setUserFirstName", payload.firstName, {
          root: true,
        });
        commit("auth/setUserLastName", payload.lastName, {
          root: true,
        });
        commit(
          "app-messages/displaySuccessMessage",
          "User updated successfully!",
          {
            root: true,
          }
        );
        return true;
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        if (error.response.data.includes("Password")) {
          commit(
            "app-messages/displayErrorMessage",
            "Unable to update Password - please verify that Password meets requirements",
            {
              root: true,
            }
          );
        } else {
          commit(
            "app-messages/displayErrorMessage",
            "Error occurred while updating user",
            {
              root: true,
            }
          );
        }
        return false;
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  strict: !isProduction,
};
