<style scoped lang="scss">
h2 {
  font-size: 1.25rem;
}
.v-card {
  padding: 1rem;
  margin: 0 auto;
  display: inline-block;
}

.section-value {
  font-size: 2.5rem;
}
</style>

<template>
  <v-card>
    <h2>{{ $t("outreachPipelineData.sectionHeader") }}</h2>
    <div v-if="getIsLoadingError" class="error-panel">
      {{ $t("outreachPipelineData.failure") }}
    </div>
    <div v-else-if="outreachPipelineData" class="container" aria-live="polite">
      <v-tabs v-model="selectedTab" color="primary">
        <v-tab v-for="tab in getTabs" :key="tab.id" :value="tab.id">
          {{ $t(`outreachPipelineData.${tab.title}`) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="item in getTabData"
          :key="item.partnerOrganizationCategory"
          :aria-label="item.numberOfJobLinksSent + ' ' + $t('outreachPipelineData.linksHeader')"
        >
          <v-card flat class="d-flex">
            <v-row justify="center">
              <v-col md="4" cols="12">
                <div class="section-value">
                  {{ item.numberOfJobLinksSent.toLocaleString("en") }}
                </div>
                <h4>
                  {{ $t("outreachPipelineData.linksHeader") }}
                </h4>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <loading-spinner class="my-5" v-else></loading-spinner>
  </v-card>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { LoadingSpinner },
  data() {
    return {
      selectedTab: null,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["outreachServiceToken"]),
    ...mapGetters("outreach-pipeline-data", [
      "getIsLoadingError",
      "outreachPipelineData",
      "getTabs",
      "getTabData",
    ]),
  },
  created() {
    if (this.outreachServiceToken) {
      this.fetchOutreachPipelineData();
    }
  },
  methods: {
    ...mapActions("outreach-pipeline-data", ["fetchOutreachPipelineData"]),
  },
  filters: {},
};
</script>
