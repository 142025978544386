import { isProduction } from "@/config/app-config";
import notification from "@/api/notification";
import store from "@/store";

const state = () => ({
  notifications: []
});

const getters = {
  getUnreadNotifications(state) {
    return state.notifications.filter(n => !n.isRead);
  },
  getAllNotifications(state) {
    return state.notifications;
  },
};
const mutations = {
  setNotificationRead(state, id) {
    state.notifications = state.notifications.map(n => n.id == id ? {...n, isRead: true} : n);
  },
  setNotifications(state, value) {
    state.notifications = value;
  }
};

const actions = {
  async fetchUnreadNotifications({ commit, rootGetters }) {
    let payload = {
      companyId: rootGetters['auth/getSelectedCompany'].id,
      userId: rootGetters['auth/getUserId']
    };
    await notification.getUnread(payload, {
      success: (data) => {
        if (data) {
          data.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
          commit("setNotifications", data);
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        store.commit("app-messages/displayErrorMessage", "Error occurred while trying to retrieve unread notifications.", { root: true });
      },
    });
  },
  async fetchAllNotifications({ commit, rootGetters }) {
    let payload = {
      companyId: rootGetters['auth/getSelectedCompany'].id,
      userId: rootGetters['auth/getUserId']
    };
    await notification.getAll(payload, {
      success: (data) => {
        if (data) {
          data.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
          commit("setNotifications", data);
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        store.commit("app-messages/displayErrorMessage", "Error occurred while trying to retrieve all notifications.", { root: true });
      },
    });
  },
  async setNotificationsRead({ commit, getters, rootGetters }, notificationIds) {
    let impersonating = rootGetters['auth/getImpersonating'];
    if(!impersonating) {
      let notificationsToUpdate = getters.getUnreadNotifications.filter(n => notificationIds.includes(n.id));
      let payload = {
        readNotifications: notificationsToUpdate.map(n => {
          return {
            companyId: rootGetters['auth/getSelectedCompany'].id,
            userId: rootGetters['auth/getUserId'],
            notificationId: n.id
          }
        })
      };
      await notification.setRead(payload, {
        success: () => {
          notificationsToUpdate.forEach(n => commit("setNotificationRead", n.id));
        },
        error: (error) => {
          if (!isProduction) {
            console.log(error);
          }

          store.commit("app-messages/displayErrorMessage","Error occurred while trying to set notifications as read.", { root: true });
        },
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
