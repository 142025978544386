import http from "./employer-http";

const DO_NOTHING = () => {};

export default {
  async getJobPerformanceSummary(params, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get("/api/reports/jobperformance/summary", params, {
      success,
      error,
    });
  },
  async getJobPerformanceDetails(params, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get("/api/reports/jobperformance/details", params, {
      success,
      error,
    });
  },
  async getJobPerformanceByDate(
    params,
    { success = DO_NOTHING, error = DO_NOTHING }
  ) {
    return http.get("/api/reports/jobperformance/bydate", params, {
      success,
      error,
    });
  },
  async getApplicantDemographics(params, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get("/api/reports/applicantdemographics", params, {
      success,
      error,
    });
  },
};
