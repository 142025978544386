<template>
  <v-container>
    <div id="circa_content">
      <v-row>
        <v-col>
          <h1>Advancing Belonging & Inclusion (ABI) - <br />DEI Calendar & Content</h1>
          <p>
            Advance engagement & diversity education within your organization with Circa’s new product that provides access to content, guides, and resources for consistent employee engagement and support of DEI strategies to advance inclusion and belonging in the workplace. ABI includes:
          </p>
          <ul>
            <li>Comprehensive list of DEI observances, holidays, and awareness dates</li>
            <li>Ready to use communication templates for each event on the DEI Calendar</li>
            <li>DEI content library with templates, sample policies, step by step guides, and how to documents</li>
          </ul>
          <br />
          <v-btn v-if="$vuetify.breakpoint.lgAndUp"
                 rounded
                 color="primary"
                 class="mx-auto"
                 width="80%" :href="
                     `mailto:${(getSelectedCompany.amEmail ? getSelectedCompany.amEmail : 'renewal@circaworks.com')}?subject=Advancing Belonging and Inclusion Inquiry&body=I'm interested in receiving information on Advancing Belonging and Inclusion.`">
            Contact Your Account Manager
          </v-btn>
          <v-btn v-else
                 rounded
                 color="primary"
                 class="mx-auto"
                 width="80%" :href="
                     `mailto:${(getSelectedCompany.amEmail ? getSelectedCompany.amEmail : 'renewal@circaworks.com')}?subject=Advancing Belonging and Inclusion Inquiry&body=I'm interested in receiving information on Advancing Belonging and Inclusion.`">
            Contact Us
          </v-btn>
        </v-col>
        <v-col align-self="center">
          <img src="https://circaworks.com/wp-content/uploads/2022/08/inline_dei_content2.jpg" alt="ABI image" style="max-width:95%" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<style>
  @import '../../styles/Upsell.css';
</style>

<script>
  import { mapGetters } from "vuex";
  import circle from "@/assets/Circa_Circles2021.png";
  export default {
    computed:
    {
      ...mapGetters("auth", ["getSelectedCompany", "getDefaultAMEmail"])
    },
    mounted: async function () {
      if (document.getElementById("app"))
        document.getElementById(
          "app"
        ).style.backgroundImage = `url(${circle})`;
    }, 
    destroyed() {
      if (document.getElementById("app"))
        document.getElementById("app").style.backgroundImage = "none";
    },
  }
</script>