import { isProduction } from "@/config/app-config";
import { AuthService } from "@/auth-service";
import company from "@/api/company";
import qLogin from "@/api/qLogin";
import { UserActivityRecorder, UserActivityTypes } from "@/api/user-activities";
import productAuthorizations from "@/store/helpers/product-authorizations";

import {
  am_default_email,
  am_default_contact_number,
} from "../../config/app-config";
import router from "@/router";

const authService = new AuthService();
const state = () => ({
  user: undefined,
  companies: undefined,
  selectedCompany: undefined,
  impersonating: false,
});

const getters = {
  getUser(state) {
    return state.user;
  },
  getUserId(state) {
    return state.user.profile.sub;
  },
  getUserFullName(state) {
    if (state.user?.firstName && state.user?.lastName) {
      return state.user.firstName + " " + state.user.lastName;
    }
    return undefined;
  },
  getUserFirstName(state) {
    return state.user.firstName;
  },
  getUserLastName(state) {
    return state.user.lastName;
  },
  getUserEmail(state) {
    return state.user.profile.email;
  },
  getUserCreatedDate(state) {
    return state.user.profile.created_date;
  },
  isAuthenticated(state) {
    return state.user && !state.user.expired;
  },
  usesSSO(state) {
    return state.user.profile.sso;
  },
  getFeatureFlag: (state) => (featureId) => {
    let featureFlag = state.selectedCompany.featureFlags.find(
      (feature) => feature.id === featureId
    );
    return featureFlag && featureFlag.value;
  },
  getProductAuth: (state) => (name) => {
    let matchingFunc = (n) => n.name === name;
    if (name === "OFCCP")
      matchingFunc = (n) => n.name.startsWith(name) && n.status;

    let productAuth =
      state.selectedCompany.productAuthorizations.find(matchingFunc);
    return productAuth && productAuth.status;
  },
  getPriorProductAuth: (state) => (name) => {
    return state.selectedCompany.hadPriorProdAuths[name];
  },
  getAccessToken(state) {
    return state.user.access_token;
  },
  getCompanies(state) {
    return state.companies;
  },
  getSelectedCompany(state) {
    return state.selectedCompany;
  },
  getSelectedCompanyName(state) {
    return state.selectedCompany.name;
  },
  getDefaultAMEmail() {
    return am_default_email;
  },
  getDefaultAMContactNum() {
    return am_default_contact_number;
  },
  getImpersonating(state) {
    return state.impersonating;
  },
  getDefaultCompany(state) {
    return state.companies?.find((x) => x.isDefault);
  },
  getUserRoleId(state) {
    return state.selectedCompany.userRoleId;
  },
  isTopEmployer(state){
    return state.selectedCompany.top_employer;
  },
  getSageCustomerId(state){
    return state.selectedCompany.sageCustomerId;
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserFirstName(state, val) {
    state.user = { ...state.user, firstName: val };
  },
  setUserLastName(state, val) {
    state.user = { ...state.user, lastName: val };
  },
  setCompanies(state, { companies, userId }) {
    state.companies = companies;

    //this gets called on load;
    //if no selectedCompany in localstorage for user, try to set it
    if (!state.selectedCompany || state.selectedCompany.userId !== userId) {
      state.selectedCompany = undefined;
      if (companies && companies.length > 0) {
        state.selectedCompany = determineCompanyToSelect(companies);
      }
      state.selectedCompany.userId = userId;
    } else {
      //we need to refresh selected company
      //especially since feature flags can be toggled anytime
      state.selectedCompany = state.companies.find(
        (comp) => comp.id === state.selectedCompany.id
      );
    }
  },
  setSelectedCompany(state, { companyToSelect, userId }) {
    state.selectedCompany = companyToSelect;
    state.selectedCompany.userId = userId;
  },
  setSelectedCompanyName(state, val) {
    state.selectedCompany = { ...state.selectedCompany, name: val };
  },
  clearSelectedCompany(state) {
    state.selectedCompany = undefined;
  },
  setImpersonating(state, value) {
    state.impersonating = value;
  },
};

const actions = {
  async handleSelectCompany({ commit, getters, dispatch }, companyToSelect) {
    const userId = getters.getUserId;
    commit("setSelectedCompany", { companyToSelect, userId });
    productAuthorizations.verifySubscription(getters.getSelectedCompany);
    dispatch("notification/fetchUnreadNotifications", {}, { root: true });
  },
  async fetchUser({ commit, getters, dispatch }) {
    const authUser = await authService.getUser();
    commit("setUser", authUser);

    //don't dispatch if we've already retrieved companies for that user on login
    if (getters.getUser && !getters.getCompanies) {
      await dispatch("fetchCompanies");
    }

    if (getters.getUser && getters.getCompanies) {
      const userInfo = await dispatch(
        "users/fetchLoggedInUserComp",
        {},
        { root: true }
      );

      commit("setUserFirstName", userInfo.firstName);
      commit("setUserLastName", userInfo.lastName);
    }

    return getters.getUser;
  },
  signIn() {
    let state = {
      returnPath: router.currentRoute.fullPath,
    };
    return authService.signInRedirect(state);
  },
  async completeSignIn({ commit, getters }) {
    let user = await authService.completeSignIn();
    commit("setUser", user);
    return getters.getUser;
  },
  async completeSignInRefresh() {
    await authService.completeSilentRefresh();
  },
  async fetchCompanies({ commit, getters }) {
    const userId = getters.getUserId;
    return company.getUserCompanies(userId, {
      success: async (companies) => {
        commit("setCompanies", { companies, userId });
        productAuthorizations.verifySubscription(getters.getSelectedCompany);
      },
    });
  },
  async clearUserStore() {
    await authService.clearUser();
  },
  async userLogOut({ commit }) {
    commit("clearSelectedCompany");
    await authService.logOut();
  },

  async recordUserActivity({ getters }, route) {
    await UserActivityRecorder.recordUserActivity(
      getters.getUserId,
      getters.getSelectedCompany.id,
      UserActivityTypes.RedirectToOriginalPortal,
      { key: "route", value: `${route}` }
    );
  },

  async fetchQLoginLink({ getters }, { returnUrl, isEmbedded }) {
    const payload = {
      IdToken: getters.getUser.id_token,
      ReturnUrl: returnUrl,
      CompanyId: getters.getSelectedCompany.id,
      IsEmbedded: isEmbedded,
    };

    return qLogin.getQLoginLink(payload, {
      success: (payload) => {
        var res = JSON.parse(payload);
        var qLoginUrl = new URL(res.QLoginLink);
        if (isProduction) {
          qLoginUrl.protocol = "https:";
        } else {
          qLoginUrl.protocol = "http:";
        }
        return qLoginUrl.toString();
      },
      error: (e) => {
        if (!isProduction) {
          console.log(e);
        }
      },
    });
  },
};

function determineCompanyToSelect(companies) {
  return (
    companies.find((x) => x.selectCompany) ??
    companies.find((x) => x.isDefault) ??
    companies[0]
  );
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
