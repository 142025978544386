import { isProduction } from "@/config/app-config";
import abi from "@/api/abi/resources";
import FileDownload from "js-file-download";
import { ResourceType } from "./ResourceType";
import dayjs from "dayjs";
import router from "@/router";
import store from "../..";

const state = () => ({
  observances: [],
  isLoading: false,
  selectedObservance: {},
  showModal: false,
  tags: [],
  userObservances: [],
  showUserSavedObservances: false,
});

const getters = {
  getObservances(state) {
    return state.observances;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getShowModal(state) {
    return state.showModal;
  },
  getSelectedObservance(state) {
    return state.selectedObservance;
  },
  getTags(state) {
    return state.tags;
  },
  getUserObservances(state) {
    return state.userObservances;
  },
  getObservanceIsSavedByUser: (state) => (id) => {
    return state.userObservances
      .map((observance) => {
        return observance.id;
      })
      .includes(id);
  },
  getObservanceDetails: (state) => (id) => {
    return state.observances.find((o) => {
      return o.id === id;
    });
  },
  getShowUserSavedObservances(state) {
    return state.showUserSavedObservances;
  },
};

const mutations = {
  setObservances(state, val) {
    state.observances = val;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setShowModal(state, showModal) {
    state.showModal = showModal;
  },
  setSelectedObservance(state, val) {
    state.selectedObservance = val;
  },
  setTags(state, value) {
    state.tags = value;
  },
  setUserObservances(state, val) {
    state.userObservances = val;
  },
  addUserSavedObservance(state, val) {
    state.userObservances.push(val);
  },
  removeUserSavedObservance(state, val) {
    let idx = state.userObservances.findIndex((ob) => {
      return ob.id === val;
    });
    state.userObservances.splice(idx, 1);
  },
  setShowUserSavedObservances(state, val) {
    state.showUserSavedObservances = val;
  },
};

const actions = {
  showObservance({ commit }, { observance, show }) {
    commit("setShowModal", show);
    if (show) {
      commit("setSelectedObservance", observance);

      router.replace({ path: `/abi/observances/${observance.id}` });
    }
  },
  async fetchSelectedObservance({ commit, rootGetters }, observanceId) {
    commit("setLoading", true);
    await abi.getObservance(
      observanceId,
      rootGetters["auth/getSelectedCompany"].id,
      {
        success: (data) => {
          commit("setSelectedObservance", data);
          commit("setShowModal", true);
          commit("setLoading", false);
        },
        error: (error) => {
          if (!isProduction) {
            console.log(error);
          }
          commit("setLoading", false);

          store.commit(
            "app-messages/displayErrorMessage",
            "Error occurred while trying to retrieve observance.",
            { root: true }
          );
        },
      }
    );
  },
  async fetchObservances({ commit }, payload) {
    commit("setLoading", true);
    await abi.getObservances(payload, {
      success: (data) => {
        data.forEach((observance) => {
          if (observance.end) {
            observance.duration =
              Math.ceil(
                dayjs(observance.end).diff(dayjs(observance.start), "hours") /
                  24
              ) + 1;
          } else {
            observance.duration = 1;
          }
        });

        data.sort(function (a, b) {
          if (new Date(a.start) > new Date(b.start)) return 1;
          if (new Date(a.start) < new Date(b.start)) return -1;

          if (a.duration > b.duration) return 1;
          if (a.duration < b.duration) return -1;

          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
        });

        commit("setObservances", data);
        commit("setLoading", false);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit("setLoading", false);

        store.commit(
          "app-messages/displayErrorMessage",
          "Error occurred while trying to retrieve observances.",
          { root: true }
        );
      },
    });
  },
  async fetchTags({ commit, rootGetters }) {
    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
    };
    return abi.getTags(payload, {
      success: (data) => {
        commit(
          "setTags",
          data.filter((t) => t.type == "Observance")
        );
      },
    });
  },
  async fetchUserObservances({ commit, getters, rootGetters }) {
    commit("setLoading", true);

    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
      userId: rootGetters["auth/getUserId"],
    };
    await abi.getUserSavedObservances(payload, {
      success: (data) => {
        let observances = getters.getObservances.filter((o) => {
          return data.includes(o.id);
        });
        observances.sort((a, b) => new Date(a.start) - new Date(b.start));
        commit("setUserObservances", observances);
        commit("setLoading", false);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit("setLoading", false);
        store.commit(
          "app-messages/displayErrorMessage",
          "Error occurred while trying to retrieve observances.",
          { root: true }
        );
      },
    });
  },
  async saveObservance({ commit, getters, rootGetters }, id) {
    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
      userId: rootGetters["auth/getUserId"],
      observanceId: id,
    };
    commit(
      "app-messages/displaySuccessMessage",
      ``,
      {
        root: true,
      }
    );
    await abi.saveObservance(payload, {
      success: () => {
        commit(
          "app-messages/displaySuccessMessage",
          `Saved observance.`,
          {
            root: true,
          }
        );
        this.updateNotificationBool = !this.updateNotificationBool;
        commit("addUserSavedObservance", getters.getObservanceDetails(id));
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
      },
    });
  },
  async removeSavedObservance({ commit, rootGetters }, id) {
    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
      userId: rootGetters["auth/getUserId"],
      observanceId: id,
    };
    commit(
      "app-messages/displaySuccessMessage",
      ``,
      {
        root: true,
      }
    );
    await abi.deleteUserObservance(payload, {
      success: () => {
        commit(
          "app-messages/displaySuccessMessage",
          `Unsaved observance.`,
          {
            root: true,
          }
        );
        this.updateNotificationBool = !this.updateNotificationBool;
        commit("removeUserSavedObservance", id);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        store.commit(
          "app-messages/displayErrorMessage",
          "Error occurred while trying to retrieve list of tags.",
          { root: true }
        );
      },
    });
  },
  downloadFile({ rootGetters }, payload) {
    payload.companyId = rootGetters["auth/getSelectedCompany"].id;

    payload.resourceType = ResourceType.Observance.name;

    return abi.getFile(payload, {
      success: (data) => {
        FileDownload(data, payload.name);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        store.commit(
          "app-messages/displayErrorMessage",
          "Error occurred while trying to retrieve observances.",
          { root: true }
        );
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
