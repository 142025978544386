import { isProduction } from "@/config/app-config";
import reportsService from "@/api/reports";
import { resourcesForCurrentLanguage } from "@/lang-resources";

const langResources = resourcesForCurrentLanguage.toastMessages;

const state = () => ({
  applicantDemographics: null,
});

const getters = {
  getDiversityCategoryBreakdown(state) {
    return state.applicantDemographics
      ? state.applicantDemographics.totalApplicantsByDiversityCategory
      : [];
  },
  getGenderBreakdown(state) {
    return state.applicantDemographics
      ? state.applicantDemographics.totalApplicantsByGender
      : [];
  },
  getApplicantTotal(state) {
    return Object.entries(
      state.applicantDemographics.totalApplicantsByGender
    ).reduce((sum, curr) => {
      return sum + curr[1];
    }, 0);
  },
};

const mutations = {
  setApplicantDemographics(state, value) {
    state.applicantDemographics = value;
  },
};

const actions = {
  async fetchApplicantDemographics(
    { commit },
    { companyId, jobTitle, location, startDate, endDate }
  ) {
    commit("setApplicantDemographics", null);
    await reportsService.getApplicantDemographics(
      {
        CompanyId: companyId,
        JobTitle: jobTitle,
        Location: location,
        StartDate: startDate,
        EndDate: endDate,
      },
      {
        success: (data) => {
          commit("setApplicantDemographics", data);
        },
        error: (error) => {
          if (error.response && error.response.status == 401) {
            commit("app-messages/displayErrorMessage", langResources.authenticationError, { root: true });
          } else {
            commit("app-messages/displayErrorMessage", langResources.genericToastrError, { root: true });
          }
        },
      }
    );
  },
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
