import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
let options = {
  responsive: true,
  maintainAspectRatio: false,
  ticks: {
    precision: 0,
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    xAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'Week Of'
      }
    }]
  }
};

export default {
  extends: Line,
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, options);
  },
  watch: {
    //add watcher to tear down chart because options 
    //is not reactive (otherwise we'd have to declare it in state )
    chartData() {
      this.renderChart(this.chartData, options);
    },
  },
};
