<template>
  <iframe
    :src="source"
    frameborder="0"
    sandbox="allow-forms allow-pointer-lock 
    allow-same-origin allow-scripts allow-top-navigation allow-downloads"
  />
</template>

<script>
export default {
  data() {
    return {
      source: "",
    };
  },
  created() {
    this.source = this.$route.meta.iFrameSource;
  },
};
</script>

<style scoped>
iframe {
  height: 100vh;
  width: 100%;
}
</style>
