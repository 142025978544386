import http from "@/api/employer-http";

const DO_NOTHING = () => {};

export default {
  getReportCards(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get(
      "/api/Documents/reportcard/clientreportcards",
      {
        companyId: payload.CompanyId,
        beginDate: payload.BeginDate,
        endDate: payload.EndDate,
      },
      {
        success,
        error,
      }
    );
  },
  getJobScreenshots(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get(
      "/api/Documents/jobscreenshots/jobscreenshots", payload,
      {
        success,
        error,
      }
    );
  },
  getDocumentUrl(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get("/api/Documents/documenturl", payload, {
      success,
      error,
    });
  },
};
