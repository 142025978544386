import { isProduction } from "@/config/app-config";
import outreachService from "@/api/outreach-service";
import dayjs from 'dayjs';
import { resourcesForCurrentLanguage } from '@/lang-resources';

const langResources = resourcesForCurrentLanguage.outreachPipelineData;

const state = () => ({
    outreachPipelineData: null,
    isLoadingError: false,
    tabs: [
        { title: "all", id: 0 },
        { title: "minorities", id: 14 },
        { title: "women", id: 13 },
        { title: "iwd", id: 15 },
        { title: "veterans", id: 12 }
    ]
});

const getters = {
    outreachPipelineData(state) {
        return state.outreachPipelineData;
    },
    getTabs(state) {
        return state.tabs;
    },
    getTabData(state) {
        const data = [state.outreachPipelineData.total, ...state.outreachPipelineData.breakdown];
        return state.tabs.map(tab => data.find(x => x.partnerOrganizationCategory == tab.id));
    },
    getIsLoadingError(state) {
        return state.isLoadingError;
    }
}

const mutations = {
    setOutreachPipelineData(state, val) {
        state.outreachPipelineData = val;
    },
    setIsLoadingError(state, val) {
        state.isLoadingError = val;
    }
}

const actions = {
    fetchOutreachPipelineData({ commit }) {
        commit("setOutreachPipelineData", null);
        commit("setIsLoadingError", false);
        return outreachService.getOutreachPipelineData({
            "StartDate": dayjs().add(-1, 'month').format('MM/DD/YYYY'),
            "EndDate": dayjs().format('MM/DD/YYYY')
        }, {
            success: (data) => {
                commit("setOutreachPipelineData", data);
                commit("setIsLoadingError", false);
            },
            error: (error) => {
                if (error.response && error.response.status == 401) {
                    commit("app-messages/displayErrorMessage", langResources.authenticationError, { root: true });
                }
                else {
                    commit("app-messages/displayErrorMessage", langResources.genericToastrError, { root: true });
                }
                commit("setIsLoadingError", true);
            }
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    strict: !isProduction,
};  