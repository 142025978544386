<template>
  <v-container fluid class="ma-0">
    <v-row no-gutters>
      <v-col class="ma-1">
        <v-card flat style="background: transparent">
          <v-card-text class="text-left secondary--text pb-0 heading1 text-h6 mb-2">Diversity Catalyst</v-card-text>
          <v-card-title class="primary--text text-left text-h3 heading2 pt-0 mb-6">
            Welcome
          </v-card-title>
          <v-card-subtitle class="text-left intro">
            Diversity Catalyst is where we’ll work with you to manage your
            compliance, build your diverse workforce, help you save time, and
            drive innovation and growth for your business. As our valued
            customer, we are here to help you with the right programs and
            partners who support diversity initiatives.
          </v-card-subtitle>
          <v-card-subtitle class="text-left intro mb-6" v-if="!hasABI || hasABI && (hasOMS || hasLJNJP)">
            To begin, click community partners to search and connect with
            Circa’s community partners or source and match candidates from our
            diverse social network of 100M+ individuals. Or explore any of our
            other products, resources and reports available to manage your
            diversity and compliance programs.
          </v-card-subtitle>
          <v-card-actions class="mt-n5">
            <v-row v-if="hasOMS || hasLJNJP">
              <v-col style="flex-direction: column" class="d-flex justify-space-between mx-auto" cols="6" v-if="hasOMS">
                <div>
                  <router-link to="/diversity/outreach-management">
                    <v-img class="mx-auto mb-2" width="100px" contain src="~@/assets/sourcing_icon.png">
                    </v-img>
                  </router-link>
                  <p class="mx-auto text-center align-self-start icon-content">
                    Leverage relationships with community-based organizations to
                    grow your pipeline
                  </p>
                </div>
                <v-btn to="/diversity/outreach-management" rounded color="primary" class="mx-auto" width="100%">
                  Find Community Partners
                </v-btn>
              </v-col>
              <v-col style="flex-direction: column" class="d-flex justify-space-between mx-auto"
                v-if="getSelectedCompany.hasAICM" cols="6">
                <div>
                  <a target="_blank" :href="diversitySourcingUri">
                    <v-img class="mx-auto mb-2" width="100px" contain src="~@/assets/recruiting_icon.png">
                    </v-img>
                  </a>
                  <p class="mx-auto text-center align-self-start icon-content">
                    Source qualified candidates for your opportunities
                  </p>
                </div>
                <v-btn :href="diversitySourcingUri" target="_blank" rounded color="primary" class="mx-auto"
                  width="100%">
                  Find Candidates
                </v-btn>
              </v-col>
              <v-col v-else-if="hasLJNJP" style="flex-direction: column" class="d-flex justify-space-between mx-auto"
                cols="6">
                <div>
                  <router-link to="/resumes/resumes-advanced-search">
                    <v-img class="mx-auto mb-2" width="100px" contain src="~@/assets/recruiting_icon.png">
                    </v-img>
                  </router-link>
                  <p class="mx-auto text-center">
                    Source qualified candidates for your opportunities
                  </p>
                </div>
                <v-btn width="100%" to="/resumes/resumes-advanced-search" rounded color="primary">Find
                  Candidates</v-btn>
              </v-col>
            </v-row>
            <v-row v-else-if="hasABI && !hasOMS && !hasLJNJP" style="padding-top:20px;">
              <v-col style="flex-direction: column" class="d-flex justify-space-between mx-auto" cols="6">
                <div>
                  <router-link to="/abi/observances">
                    <v-img class="mx-auto mb-2" height="100px" contain src="~@/assets/Observances_Icon.png">
                    </v-img>
                  </router-link>
                  <p>
                    Celebrate DEI events with your organization
                  </p>
                </div>
                <v-btn width="100%" to="/abi/observances" rounded color="primary">
                  Observances
                </v-btn>
              </v-col>
              <v-col style="flex-direction: column" class="d-flex justify-space-between mx-auto" cols="6">
                <div>
                  <router-link to="/abi/content">
                    <v-img class="mx-auto mb-2" height="100px" contain src="~@/assets/Content_Icon.png">
                    </v-img>
                  </router-link>
                  <p>
                    Leverage resources to educate and support your DEI Plan
                  </p>
                </div>
                <v-btn width="100%" to="/abi/content" rounded color="primary">
                  Content Library
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.lgAndUp" class="mt-8">
        <v-img src="~@/assets/shutterstock_435199744.jpg" class="mx-auto" position="center left 10%" aspect-ratio="1.2"
          min-width="379">
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  diversity_sourcing_uri,
  am_default_contact_number,
} from "../config/app-config";
import { mapGetters } from "vuex";
import circle from "@/assets/Circa_Circles2021.png";
// @ is an alias to /src

export default {
  name: "Home",
  computed: {
    ...mapGetters("auth", ["getSelectedCompany"]),
    hasOMS() {
      var company = this.getSelectedCompany;
      return this.companyHasProductAuth(company, "OMS");
    },
    hasABI() {
      var company = this.getSelectedCompany;
      return this.companyHasProductAuth(company, "ABI");
    },
    hasLJNJP() {
      var company = this.getSelectedCompany;
      return this.companyHasProductAuth(company, "LJNJP");
    }
  },
  mounted() {
    if (document.getElementById("app"))
      document.getElementById(
        "app"
      ).style.backgroundImage = `url(${circle}), url(${circle})`;
  },
  destroyed() {
    if (document.getElementById("app"))
      document.getElementById("app").style.backgroundImage = "none";
  },
  data() {
    return {
      diversitySourcingUri: diversity_sourcing_uri,
      amDefaultContactNumber: am_default_contact_number,
    };
  },
  methods: {
    companyHasProductAuth(company, product) {
      let authorization = company.productAuthorizations.find(
        (x) => x.name == product
      );
      return authorization ? authorization.status : false;
    }
  }
};
</script>
<style scoped>
.heading1 {
  font-weight: 500;
  font-size: 1.5rem !important;
}

.heading2 {
  font-weight: bold !important;
  font-size: 4.17rem !important;
}

.intro {
  font-weight: 400;
  font-size: 1.33rem;
  color: #6d6c6c !important;
  line-height: 1.25em;
}

.icon-content {
  line-height: 1.25em;
}
</style>
