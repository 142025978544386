<template>
  <v-container fluid>
    <div v-if="isTopEmployer" class="top_employer">
      <div class="audit_Content">
        <!-- resourcesListing -->
        <h1>DiversityJobs.com Top Diversity Employer Badge</h1>
        <p>
          You’ve earned the right to display our DiversityJobs.com badge. It is
          a nice way to show candidates and customers that you are being
          proactive about Diversity &amp; Inclusion. You are now listed on our
          <a
            href="https://diversityjobs.com/employer/top-diversity-employers/A"
            target="_blank"
            rel="noopener nofollow external noreferrer"
            data-wpel-link="external"
            >Top Diversity Employers page</a
          >
          as well.
        </p>
        <h3 style="padding-top: 40px">Preview</h3>
        <p>
          <img
            decoding="async"
            class="entered lazyloaded"
            style="padding-top: 10px"
            src="https://diversityjobs.com/imglib/employer-images/top-diversity-employer.png"
            width="191"
            height="201"
            data-lazy-src="https://diversityjobs.com/imglib/employer-images/top-diversity-employer.png"
            data-ll-status="loaded"
          /><noscript
            ><img
              decoding="async"
              class=""
              style="padding-top: 10px"
              src="https://diversityjobs.com/imglib/employer-images/top-diversity-employer.png"
              width="191"
              height="201"
          /></noscript>
        </p>
        <h3 style="padding-top: 40px">Get your badge</h3>
        <p>
          Copy the HTML markup below and paste it on your website. If you need
          any custom alterations or assistance, please
          <a
            href="https://www.diversityjobs.com/contact/"
            data-wpel-link="external"
            rel="nofollow external noopener noreferrer"
            >contact us</a
          >.
        </p>
        <h3 style="padding-top: 40px">Where to display it?</h3>
        <p>You may use the badge freely on your:</p>
        <ul>
          <li style="list-style-type: none">
            <ul>
              <li>websites</li>
              <li>social media properties</li>
              <li>printed materials</li>
              <li>email signatures</li>
            </ul>
          </li>
        </ul>
        
        <p>
          <a
            href="https://circaworks.com/wp-content/uploads/2022/03/Top-Employer-Social-Media-Templates.pdf"
            target="_blank"
            rel="noopener"
            data-wpel-link="internal"
            >Download</a
          >
          our social media post templates for Facebook, LinkedIn, and twitter so
          that you can easily share your new badge on social!
        </p>
        <div style="background-color: #e6e7e8; color: #000000; padding: 20px">
          <pre id="div1" style="white-space: pre-wrap">
&lt;!--DiversityJobs.com Badge --&gt;
&lt;a href="https://diversityjobs.com/employer/top-diversity-employers?utm_campaign=TopEmployer&amp;utm_medium=badge"&gt;
&lt;img src="https://diversityjobs.com/imglib/employer-images/top-diversity-employer.png" width="200" alt="DiversityJobs.com Top Employer" title="DiversityJobs.com Top Employer" /&gt;&lt;/a&gt;
&lt;!-- END DiversityJobs.com Badge --&gt;</pre>
        </div>
        <div style="padding-top: 20px">
          <table width="100%" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td align="left">
                  <table
                    style="border-collapse: separate !important"
                    border="0"
                    width="300"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            background-color: #4fd59e;
                            color: #ffffff;
                            font-size: 14px;
                            font-weight: bold;
                            padding: 10px 10px;
                            border-radius: 25px;
                            font-family: Arial, Helvetica, sans-serif;
                            text-align: center;
                            border: solid 1px #4fd59e;
                          "
                        >
                          <a
                            style="color: #ffffff; text-decoration: none"
                            href="#"
                            onclick="CopyToClipboard('div1');return false;"
                            >COPY HTML TO CLIPBOARD</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <h3 style="padding-top: 40px">
          What’s the difference between the Employer Member &amp; Top Diversity
          Employer Badge Requirements?
        </h3>
        <p>
          The Employer Member Badge is for a client who has a Circa diversity or
          compliance powered subscription.
        </p>
        <p>
          The Top Diversity Employer Badge is for a client who has a Circa
          diversity or compliance powered subscription, the subscription had
          been in force for 12+ months, and the client renews for a minimum of 1
          year agreement or greater beyond the initial 12 months.
        </p>
      </div>
    </div>
    <div v-else class="employer">
      <div class="audit_Content">
        <h1 id="title">DiversityJobs.com Employer Member Badge</h1>
        <p class="text">
          Thank you for joining Circa as a customer! Feel free to share our
          DiversityJobs.com Member Badge to show candidates that you are being
          proactive about Diversity &amp; Inclusion.
        </p>
        <h3 style="padding-top: 40px" class="text">Preview</h3>
        <p>
          <img
            decoding="async"
            class="alignnone wp-image-20162 size-medium entered lazyloaded"
            src="https://diversityjobs.com/imglib/employer-images/employer-member.png"
            alt=""
            width="300"
            height="262"
            data-lazy-srcset="https://diversityjobs.com/imglib/employer-images/employer-member.png 300w, https://diversityjobs.com/imglib/employer-images/employer-member.png 686w"
            data-lazy-sizes="(max-width: 300px) 100vw, 300px"
            data-lazy-src="https://diversityjobs.com/imglib/employer-images/employer-member.png"
            data-ll-status="loaded"
            sizes="(max-width: 300px) 100vw, 300px"
            srcset="
              https://diversityjobs.com/imglib/employer-images/employer-member.png 300w,
              https://diversityjobs.com/imglib/employer-images/employer-member.png        686w
            "
          /><noscript
            ><img
              decoding="async"
              class="alignnone wp-image-20162 size-medium"
              src="https://diversityjobs.com/imglib/employer-images/employer-member.png"
              alt=""
              width="300"
              height="262"
              srcset="
                https://diversityjobs.com/imglib/employer-images/employer-member.png 300w,
                https://diversityjobs.com/imglib/employer-images/employer-member.png         686w
              "
              sizes="(max-width: 300px) 100vw, 300px"
          /></noscript>
        </p>
        <h3 style="padding-top: 40px" class="text">Where to display it?</h3>
        <p>You may use the badge freely on your:</p>
        <ul class="text">
          <li style="list-style-type: none">
            <ul>
              <li>websites</li>
              <li>social media properties</li>
              <li>printed materials</li>
              <li>email signatures</li>
            </ul>
          </li>
        </ul>
        <p class="text">
          <a
            href="https://circaworks.com/wp-content/uploads/2022/03/Employer_Member_Badge_Social.pdf"
            target="_blank"
            rel="noopener"
            data-wpel-link="internal"
            >Download</a
          >
          our social media post templates for Facebook, LinkedIn, and twitter so
          that you can easily share your new badge on social!
        </p>
        <h3 style="padding-top: 40px" class="text">Get your badge</h3>
        <p class="text">
          Copy the HTML markup below and paste it on your website. If you need
          any custom alterations or assistance, please
          <a
            href="https://www.diversityjobs.com/contact/"
            data-wpel-link="external"
            rel="nofollow external noopener noreferrer"
            >contact us</a
          >.
        </p>
        <div style="background-color: #e6e7e8; color: #000000; padding: 20px">
          <pre id="div1" style="white-space: pre-wrap">
&lt;!--DiversityJobs.com Badge --&gt;
&lt;a href="https://diversityjobs.com/employer/top-diversity-employers?utm_campaign=TopEmployer&amp;utm_medium=badge"&gt;
&lt;img src="https://diversityjobs.com/imglib/employer-images/employer-member.png" width="200" alt="DiversityJobs.com Employer Member" title="DiversityJobs.com Employer Member" /&gt;&lt;/a&gt;
&lt;!-- END DiversityJobs.com Badge --&gt;</pre>
        </div>
        <div style="padding-top: 20px">
          <table width="100%" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td align="left">
                  <table
                    style="border-collapse: separate !important"
                    border="0"
                    width="300"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            background-color: #4fd59e;
                            color: #ffffff;
                            font-size: 14px;
                            font-weight: bold;
                            padding: 10px 10px;
                            border-radius: 25px;
                            font-family: Arial, Helvetica, sans-serif;
                            text-align: center;
                            border: solid 1px #4fd59e;
                          "
                        >
                          <a
                            style="color: #ffffff; text-decoration: none"
                            href="#"
                            >COPY HTML TO CLIPBOARD</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 style="padding-top: 40px" class="text">
          What’s the difference between the Employer Member &amp; Top Diversity
          Employer Badge Requirements?
        </h3>
        <p class="text">
          The Employer Member Badge is for a client who has a Circa diversity or
          compliance powered subscription.
        </p>
        <p class="text">
          The Top Diversity Employer Badge is for a client who has a Circa
          diversity or compliance powered subscription, the subscription had
          been in force for 12+ months, and the client renews for a minimum of 1
          year agreement or greater beyond the initial 12 months.
        </p>
      </div>
    </div>
  </v-container>
</template>

  <script>
import { mapGetters } from "vuex";
export default {
  name: "EmployerBadge",
  computed: {
    ...mapGetters("auth", ["isTopEmployer"]),
  },
};
</script>
<style scoped>
h1 h1 {
  font-size: calc(1.375rem + 1.5vw);
}
h3 {
  font-size: 30px;
  font-weight: bolder;
  font-family: GothamMedium, Arial, Helvetica, sans-serif;
  color: gray;
  width: 100%;
}
ul li {
  font-size: 16px;
  font-family: GothamBook, Arial, Helvetica, sans-serif;
  /* font-weight: 400; */
  color: gray;
  list-style: disc;
  list-style-position: outside;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
}
p {
  font-size: 16px;
  display: inline-block;
  font-family: GothamBook, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: gray;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
.top_employer {
  text-align: left;
  margin-left: 40px;
  margin-right: 50px;
  margin-top: 20px;
}
.employer {
  text-align: left;
  margin-left: 40px;
  margin-right: 50px;
  margin-top: 20px;
}
</style>
