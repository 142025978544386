<template>
  <v-form v-show="!isLoading" v-model="formInfo.isValid">
    <v-container fluid>
      <v-row>
        <v-col cols="4" md="2" offset="3">
          <v-select
            v-model="selectedTags"
            :items="getTags"
            item-text="label"
            item-value="id"
            label="Select Tags"
            v-on:change="handleRetrieveResources()"
            multiple
            chips
            clearable
          >
            <template v-slot:selection="{ item, index }">
              <span v-show="selectedTags.length === 1">
                <v-chip>
                  <span>{{ item.label }}</span>
                </v-chip>
              </span>
              <span v-show="index === 1" class="grey--text" text-align="center">
                {{ selectedTags.length }} Selected
              </span>
            </template>
            <template v-slot:append-item>
              <span class="clearTags">
                <v-btn
                  text
                  color="primary"
                  class="clearTags"
                  @click="clearAllTags"
                >
                  Clear
                </v-btn>
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <from-to-date-input
            class="observancesFromToDate"
            v-model="datePickerFilter"
          /><br />
        </v-col>
        <v-col cols="2" md="3" xl="2">
          <v-switch id="saved-obs-switch" v-model="showSavedUserObservances">
            <template v-slot:label>Display Saved Observances</template>
          </v-switch>
        </v-col>
      </v-row>
      <br /><br />
    </v-container>
  </v-form>
</template>

<script>
import FromToDateInput from "@/components/form/FromToDateInput.vue";
import { mdiMagnify } from "@mdi/js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { isEmpty } from "lodash";

export default {
  components: { FromToDateInput },
  data: () => {
    const currentDate = new Date();
    return {
      isLoading: true,
      selectedTags: [],
      datePickerFilter: {
        fromDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1) //first day of current month
          .toISOString()
          .substr(0, 10),
        toDate: new Date(
          currentDate.getFullYear() + 1, //next year
          currentDate.getMonth(), // current month
          0 // minus a day (last day of last month)
        )
          .toISOString()
          .substr(0, 10),
        validationRules: {
          fromDateRules: [
            (v) => {
              return !isEmpty(v) || "From Date is required";
            },
          ],
          toDateRules: [
            (v) => {
              return !isEmpty(v) || "To Date is required";
            },
          ],
          setDateRangeBoundary: true,
          maxDaysAllowed: 365,
        },
      },
      formInfo: {
        isValid: true,
      },
      icons: {
        search: mdiMagnify,
      },
    };
  },
  watch: {
    "datePickerFilter.toDate"() {
      this.handleRetrieveResources();
    },
    "datePickerFilter.fromDate"() {
      this.handleRetrieveResources();
    },
  },
  computed: {
    ...mapGetters("auth", ["getSelectedCompany"]),
    ...mapGetters("abi-observances", [
      "getTags",
      "getShowUserSavedObservances",
    ]),
    showSavedUserObservances: {
      get() {
        return this.getShowUserSavedObservances;
      },
      set(value) {
        this.setShowUserSavedObservances(value);
      },
    },
  },
  methods: {
    ...mapMutations("abi-observances", [
      "setLoading",
      "setShowUserSavedObservances",
    ]),
    ...mapActions("abi-observances", [
      "fetchObservances",
      "fetchTags",
      "fetchUserObservances",
    ]),

    clearAllTags() {
      this.selectedTags = [];
      this.handleRetrieveResources();
    },
    async handleRetrieveResources() {
      const payload = {
        CompanyId: this.getSelectedCompany.id,
        StartDate: this.datePickerFilter.fromDate,
        EndDate: this.datePickerFilter.toDate,
        Tags: this.selectedTags,
      };
      await this.fetchObservances(payload);
      await this.fetchUserObservances();
    },
  },

  async created() {
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.datePickerFilter.fromDate = new Date(this.$route.query.startDate)
        .toISOString()
        .substr(0, 10);
      this.datePickerFilter.toDate = new Date(this.$route.query.endDate)
        .toISOString()
        .substr(0, 10);
    }

    await this.fetchTags();
    this.isLoading = true;
    await this.handleRetrieveResources();
    await this.fetchUserObservances();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.clearTags {
  position: sticky;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  /* color:white; */
}
</style>
