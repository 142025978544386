import { isProduction } from "@/config/app-config";
import notification from "@/api/notification";
import store from "@/store";

const state = () => ({
  preferences: [],
  loading: false,
});

const getters = {
  getPreferences(state) {
    return state.preferences;
  },
  getLoading(state) {
    return state.loading;
  },
};
const mutations = {
  setPreferences(state, value) {
    state.preferences = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
};

const actions = {
  async fetchNotificationPreferences({ commit, rootGetters }) {
    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
      userId: rootGetters["auth/getUserId"],
    };
    commit("setLoading", true);

    await notification.getPreferences(payload, {
      success: (data) => {
        if (data) {
          commit("setPreferences", data);
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        store.commit("app-messages/displayErrorMessage",
          "Error occurred while trying to retrieve notifications preferences.",
          { root: true }
        );
      },
    });
    commit("setLoading", false);
  },
  async updateNotificationPreference(
    { commit, rootGetters },
    { notificationTypeId, status }
  ) {
    let payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
      userId: rootGetters["auth/getUserId"],
      notificationTypeId,
    };
    commit("setLoading", true);

    const errorDelegate = (error) => {
      if (!isProduction) {
        console.log(error);
      }

      store.commit("app-messages/displayErrorMessage",
        "Error occurred while trying to retrieve notifications preferences.",
        { root: true }
      );
    };

    //if status is TRUE that means we are opting in
    //else opt out
    if (status) {
      await notification.setOptIn(payload, {
        error: errorDelegate,
      });
    } else {
      await notification.setOptOut(payload, {
        error: errorDelegate,
      });
    }
    commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
