<template>
  <v-container>
    <v-form
      v-show="!usesSSO && isCurrentUser"
      ref="form"
      :disabled="!(!usesSSO && isCurrentUser)"
    >
      <v-container>
        <v-row v-if="!resettingPassword"
          ><v-col>
            <v-btn color="primary" @click="resettingPassword = true">
              Change Password
            </v-btn>
          </v-col></v-row
        >
        <v-container v-else>
          <v-row>
            <v-col>
              <v-btn
                color="warning"
                @click="resettingPassword = false"
                class="mb-n3"
              >
                Cancel Password Update
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pt-1 pb-1">
              <v-text-field
                label="Current Password"
                v-model="user.currentPassword"
                :rules="currentPasswordValidation"
                :append-icon="
                  showCurrentPassword ? icons.notVisible : icons.visible
                "
                :type="showCurrentPassword ? 'text' : 'password'"
                @click:append="showCurrentPassword = !showCurrentPassword"
              ></v-text-field>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col class="pt-1 mb-0 pb-0">
              <v-text-field
                label="New Password"
                v-model="user.newPassword"
                :append-icon="
                  showNewPassword ? icons.notVisible : icons.visible
                "
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1">
              <v-text-field
                label="Confirm New Password"
                v-model="user.confirmPassword"
                :rules="passwordsMatch"
                validate-on-blur
                :append-icon="
                  showPasswordConfirm ? icons.notVisible : icons.visible
                "
                :type="showPasswordConfirm ? 'text' : 'password'"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-container class="text-left password-requirements pt-2 pb-2">
            <v-row>
              <v-col>
                <span><b>Password Requirements:</b></span>
                <ul>
                  <li>At least 10 characters</li>
                  <li style="white-space: nowrap">
                    Not a previously used Password
                  </li>
                </ul>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-container>
      </v-container>
    </v-form>
  </v-container>
</template>

<style scoped lang="scss"></style>

<script>
import { mapGetters } from "vuex";
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiEyeOff } from "@mdi/js";

export default {
  name: "PasswordComponent",
  props: {
    user: Object,
    isCurrentUser: Boolean,
    newUser: Boolean,
  },
  data() {
    return {
      showCurrentPassword: false,
      showNewPassword: false,
      showPasswordConfirm: false,
      resettingPassword: false,
      icons: {
        pass: mdiCheckCircle,
        fail: mdiCloseCircle,
        visible: mdiEye,
        notVisible: mdiEyeOff,
      },
    };
  },
  watch: {
    passwordIsValid: function (val) {
      this.$emit("passwordIsValid", val);
    },
    resettingPassword(newValue) {
      this.$emit("resettingPassword", newValue);
    },
  },
  computed: {
    ...mapGetters("auth", ["usesSSO"]),
    passwordsMatch() {
      return [
        (this.user.newPassword?.length > 0 &&
          this.user.newPassword == this.user.confirmPassword) ||
          "Passwords must match",
      ];
    },
    currentPasswordValidation() {
      return [
        this.user.currentPassword?.length > 0 || "Current Password is required",
      ];
    },
    passwordIsValid() {
      return (
        this.passwordsMatch[0] == true &&
        this.currentPasswordValidation[0] == true
      );
    },
  },
};
</script>
