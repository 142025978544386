<template>
  <v-row no-gutters>
    <v-col>
      <div class="d-sm-inline-flex">
        <v-menu
          :close-on-content-click="false"
          :nudge-right="5"
          :nudge-top="10"
          transition="scale-transition"
          offset-y
          min-width="auto"
          v-model="fromDateOpen"
          ref="fromDateOpen"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :class="{ 'py-5': true, 'mr-5': !$vuetify.breakpoint.xs }"
              label="From:"
              :prepend-inner-icon="icons.calendar"
              ref="fromDateRef"
              v-maska="'##/##/####'"
              v-on="on"
              v-bind="attrs"
              :rules="fromDateRules"
              hint="MM/DD/YYYY"
              persistent-hint
              @click:prepend-inner="toggleFromDate(true)"
              v-model="fromDateFormatted"
              @blur="updateFromValue(parseDate(fromDateFormatted))"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            locale="en-in"
            v-model="value.fromDate"
            @input="toggleFromDate(false)"
            no-title
            id="fromDatePicker"
            tabindex="0"
            :weekday-format="getDay"
          ></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          :nudge-left="10"
          :nudge-top="10"
          transition="scale-transition"
          offset-y
          v-model="toDateOpen"
          min-width="auto"
          :left="true"
          origin="top right"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="py-5"
              label="To:"
              :rules="toDateRules"
              :prepend-inner-icon="icons.calendar"
              ref="toDateRef"
              v-maska="'##/##/####'"
              v-on="on"
              v-bind="attrs"
              hint="MM/DD/YYYY"
              persistent-hint
              @click:prepend-inner="toggleToDate(true)"
              v-model="toDateFormatted"
              @blur="updateToValue(parseDate(toDateFormatted))"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            color="primary"
            v-model="value.toDate"
            @input="toggleToDate(false)"
            no-title
            id="toDatePicker"
            tabindex="0"
            :weekday-format="getDay"
          ></v-date-picker>
        </v-menu>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mdiCalendar } from "@mdi/js";
import moment from "moment";

export default {
  inheritAttrs: false,
  props: {
    value: Object,
  },
  data: () => ({
    icons: {
      calendar: mdiCalendar,
    },
    fromDateOpen: false,
    toDateOpen: false,
    fromDateFormatted: undefined,
    toDateFormatted: undefined,
    daysOfWeek: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  }),
  created() {
    this.fromDateFormatted = this.formatDate(this.value.fromDate);
    this.toDateFormatted = this.formatDate(this.value.toDate);
  },
  computed: {
    currentDate() {
      return moment().toISOString().substr(0, 10);
    },
    toDateRules() {
      const rules =
        this.value &&
        this.value.validationRules &&
        this.value.validationRules.toDateRules
          ? this.value.validationRules.toDateRules
          : [];
      rules.push((date) => {
        const toDate = new Date(date).setUTCHours(0, 0, 0, 0);
        const fromDateSelected = new Date(this.value.fromDate).setUTCHours(
          0,
          0,
          0,
          0
        );

        //if toDate is >= fromDate it's ok
        //else return error message
        return (
          fromDateSelected <= toDate || "To Date cannot be before the From Date"
        );
      });
      return rules;
    },
    fromDateRules() {
      const rules =
        this.value &&
        this.value.validationRules &&
        this.value.validationRules.fromDateRules
          ? this.value.validationRules.fromDateRules
          : [];
      rules.push((date) => {
        const fromDate = new Date(date).setUTCHours(0, 0, 0, 0);
        const toDateSelected = new Date(this.value.toDate).setUTCHours(
          0,
          0,
          0,
          0
        );

        //if toDateSelected is >= fromDate it's ok
        //else return error message
        return (
          toDateSelected >= fromDate || "From Date cannot be after the To Date"
        );
      });
      if (this.value.validationRules?.setDateRangeBoundary) {
        rules.push((date) => {
          const fromDate = new Date(date).setUTCHours(0, 0, 0, 0);
          const toDateSelected = new Date(this.value.toDate).setUTCHours(
            0,
            0,
            0,
            0
          );
          let diffInMs = Math.abs(toDateSelected - fromDate);
          let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          return (
            diffInDays <= this.value.validationRules.maxDaysAllowed ||
            "The To Date and From Date fields cannot exceed a year."
          );
        });
      }

      return rules;
    },
  },
  watch: {
    "value.toDate"(val) {
      this.validateField();
      this.toDateFormatted = this.formatDate(val);
    },
    "value.fromDate"(val) {
      this.validateField();
      this.fromDateFormatted = this.formatDate(val);
    },
    fromDateOpen(isOpen) {
      this.addHoverText(isOpen, "from");
    },
    toDateOpen(isOpen) {
      this.addHoverText(isOpen, "to");
    },
  },
  methods: {
    getDay(date) {
      let i = new Date(date).getDay(date);
      return this.daysOfWeek[i];
    },
    addHoverText(isOpen, toFrom) {
      if (isOpen) {
        //dynamically add titles
        //settimeout for that initial wait for the datepicker to open
        setTimeout(() => {
          const elemId = `${toFrom}-selected-date`;
          const selectedElement = document.getElementById(elemId);
          if (selectedElement) {
            selectedElement.removeAttribute("title");
            selectedElement.removeAttribute("id");
          }

          const selectedDate = document?.querySelector(
            `#${toFrom}DatePicker .v-btn--active`
          );

          if (selectedDate) {
            selectedDate.setAttribute("id", elemId);
            selectedDate.setAttribute("title", "Selected Day");
          }

          document
            .querySelector(`#${toFrom}DatePicker .v-date-picker-table__current`)
            ?.setAttribute("title", "Current Day");

          document
            .querySelector(
              `#${toFrom}DatePicker .v-date-picker-table__current.v-btn--active`
            )
            ?.setAttribute("title", "Current/Selected Day");

          document.querySelector(`#${toFrom}DatePicker`).focus();
        }, 100);
      } else {
        this.$refs[`${toFrom}DateRef`].focus();
      }
    },
    validateField() {
      this.$refs.toDateRef.validate();
      this.$refs.fromDateRef.validate();
    },
    formatDate(date) {
      //format date for text field
      if (!date) return null;
      return moment(date).format("MM/DD/YYYY");
    },
    parseDate(date) {
      //parse dates for date picker since iso format is what its expecting
      if (!date) return null;

      const m = moment(date, "MM/DD/YYYY");
      if (!m.isValid()) return null;

      return m.format("YYYY-MM-DD");
    },
    toggleFromDate(val) {
      this.fromDateOpen = val;
    },
    toggleToDate(val) {
      this.toDateOpen = val;
    },
    updateToValue(val) {
      this.value.toDate = val;
    },
    updateFromValue(val) {
      this.value.fromDate = val;
    },
  },
};
</script>
