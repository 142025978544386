import http from './employer-http';

const DO_NOTHING = () => {};

export default {
    getQLoginLink(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post('/api/QLogin', payload, {
            success,
            error
        });
    }
};
