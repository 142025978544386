<template>
  <v-container>
    <v-container class="d-flex align-center">
      <v-subheader class="notification-title font-weight-bold"
                   @click="handleTitleClick">
        Notifications
      </v-subheader>
      <v-container v-if="!dismissable"
                   class="d-flex align-center justify-end pr-4">
        <v-btn color="secondary"
               :disabled="this.selectedNotifications.filter((n) => n).length == 0"
               @click="handleMarkAsRead">Mark as Read</v-btn>
        <v-checkbox class="ml-5"
                    v-model="selectAll"
                    @change="handleSelectAll"></v-checkbox>
      </v-container>
    </v-container>
    <v-divider></v-divider>
    <v-list v-if="value.length > 0">
      <v-list-item two-line
                   v-for="(notification, index) in value"
                   :key="notification.id"
                   :class="{
            'unread-notification': !notification.isRead && highlightUnread,
            'read-notification': notification.isRead && highlightUnread,
          }"
                   class="pr-4">
        <span v-if="highlightUnread && !notification.isRead"
              class="unread-dot"></span>
        <v-list-item-content @click="handleNotificationClick(notification)" @keydown.enter="handleNotificationClick(notification)" tabindex="0">
          <v-list-item-subtitle class="text-left">
            {{ formatDate(notification.dateCreated) }}
          </v-list-item-subtitle>
          <v-list-item-title class="text-left">
            {{
              notification.description
            }}
          </v-list-item-title>
        </v-list-item-content>
        <v-btn v-if="dismissable"
               class="dismiss"
               icon
               x-small
               @click="dismissNotification(notification)">
          <v-icon>{{ dismissIcon }}</v-icon>
        </v-btn>
        <v-checkbox v-else v-model="selectedNotifications[index]"></v-checkbox>
      </v-list-item>
    </v-list>
    <v-subheader v-else class="justify-center">No notifications</v-subheader>
  </v-container>
</template>

<style scoped>
.dismiss {
  width: 30px;
  height: 30px;
}
.theme--light.v-subheader {
  color: rgba(0, 0, 0, 0.8);
}
.unread-notification {
  font-weight: 700;
  padding-left: 0;
}
.read-notification {
  padding-left: 50px;
}
.unread-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f44336;
  margin: 0 20px;
}
.notification-title {
  cursor: pointer;
}
</style>

<script>
import { mapActions } from "vuex";
import { mdiClose } from "@mdi/js";

export default {
  name: "NotificationsList",
  props: {
    value: Array,
    dismissable: Boolean,
    highlightUnread: Boolean,
  },
  data() {
    return {
      dismissIcon: mdiClose,
      selectedNotifications: [],
      selectAll: false,
    };
  },
  methods: {
    ...mapActions("notification", ["setNotificationsRead"]),
    async handleNotificationClick(notification) {
      this.$emit("notificationClicked");
      this.$router.push({ path: notification.link });
      await this.dismissNotification(notification);
    },
    async dismissNotification(notification) {
      if (!notification.isRead)
        await this.setNotificationsRead([notification.id]);
    },
    async handleMarkAsRead() {
      let notificationIdsToSetToRead = this.value
        .filter((n, i) => this.selectedNotifications[i])
        .map((n) => n.id);
      await this.setNotificationsRead(notificationIdsToSetToRead);
      this.selectedNotifications = [];
      this.selectAll = false;
    },
    handleSelectAll(value) {
      if (value) {
        let size = this.value.length;
        while (size--) this.selectedNotifications[size] = true;
      } else {
        this.selectedNotifications = [];
      }
    },
    handleTitleClick() {
      this.$emit("notificationClicked");
      this.$router.push({ path: "/notification/list" });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US");
    },
  },
};
</script>