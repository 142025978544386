<template>
  <v-container fluid>
    <loading-spinner v-if="this.getIsLoading"></loading-spinner>
    <div v-else>
      <v-row no-gutters class="mt-n8 mb-n3">
        <v-col align="left" align-self="center" cols="6">
          {{ getObservancesLength }} Observance(s) found
        </v-col>
        <v-col cols="6" class="calendarKey">
          <v-row>
            <v-col align="right" cols="2">
              <v-img src="~@/assets/calendar_day.png" class="calendar" alt="Single Day"> </v-img>
            </v-col>
            <v-col cols="2"> <p class="calendarText">Single Day</p></v-col>
            <v-col align="right" cols="2">
              <v-img
                src="~@/assets/calendar_multiple.png"
                class="calendar"
                padding-right="10px"
                alt="Multiple Days"
              ></v-img>
            </v-col>
            <v-col cols="2"
              ><p class="calendarText pl-0">&nbsp;&nbsp;Multiple Days</p>
            </v-col>
            <v-col align="right" cols="2">
              <v-img
                src="~@/assets/calendar_month.png"
                class="calendar"
                float="right"
                alt="Month Long"
              >
              </v-img>
            </v-col>
            <v-col cols="2"><p class="calendarText">Month Long</p></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider class="blue-divider"></v-divider>
        </v-col>
      </v-row>
      <div class="observances-list">
        <span v-for="(item, index) in observancesList" :key="item.id">
          <observances-list-item :observance="item"> </observances-list-item>
          <v-divider
            v-if="index < getObservances.length - 1"
            class="observance-divider"
          />
        </span>
      </div>
    </div>
    <observance-modal></observance-modal>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ObservancesListItem from "@/components/ABI/Observances/ObservancesListItem.vue";
import ObservanceModal from "@/components/ABI/Observances/modal/ObservanceModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    ObservancesListItem,
    LoadingSpinner,
    ObservanceModal,
  },
  data: () => ({
  }),
    methods: {
      ...mapMutations("app-messages", ["displaySuccessMessage"]),
    },
    watch: {
      observancesList() {
        this.displaySuccessMessage(`${this.getObservancesLength} observances found.`);
    }
  },
  computed: {
    ...mapGetters("abi-observances", [
      "getObservances",
      "getIsLoading",
      "getUserObservances",
      "getShowUserSavedObservances"
    ]),
    getObservancesLength() {
      return this.getShowUserSavedObservances
        ? this.getUserObservances.length
        : this.getObservances.length;
    },
    observancesList() {
      return this.getShowUserSavedObservances
        ? this.getUserObservances
        : this.getObservances;
    },
  },
};
</script>

<style scoped>
.observances-list {
  background-color: #fff;
  margin-top: 1rem;
}
.observance-divider {
  border-color: #939598;
  background-color: #939598;
}
.blue-divider {
  border-width: 1.3px;
  border-color: #66e9f1;
  background-color: #66e9f1;
}
div,
col {
  margin: 0%;
  padding: 0%;
}
.calendarKey {
  padding-left: 12%;
}
.calendar {
  height: 35px;
  width: 35px;
  min-width: 35px;
  min-height: 35px;
}
.calendarText {
  padding-top: 0.5rem;
  padding-left: 10px;
  white-space: nowrap;
  font-size: 13px;
}
</style>
