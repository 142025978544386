import { isProduction } from "@/config/app-config";
import ordersApi from "@/api/orders";

const state = () => ({
  versaPayUrl: null,
});

const getters = {
    getVersaPayUrl(state) {
    return state.versaPayUrl;
  },
};
const mutations = {
  setVersaPayUrl(state, url) {
    state.versaPayUrl = url;
  },
};

const actions = {
  async fetchVersaPayUrl({ commit, rootGetters }) {
    return ordersApi.getVersaPayUrl(rootGetters['auth/getSelectedCompany'].id, {
      success: (data) => {
        commit("setVersaPayUrl", data);
      },
      error: () => {
        commit("app-messages/displayErrorMessage", "Error occurred while trying to load orders.", { root: true });
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
