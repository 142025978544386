import { isProduction } from "@/config/app-config";
import company from "@/api/company";

const state = () => ({
});

const getters = {
};
const mutations = {
};

const actions = {
  updateDefaultCompany({dispatch, commit}, companyId) {
    return company.setDefaultCompany(companyId, {
      success: async () => {
        // fetch companies to reset the default
        await dispatch('auth/fetchCompanies', null, { root: true });
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit("app-messages/displayErrorMessage", "Error occurred while setting the default company.", { root: true });
      },
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
