<template>
  <local-sites-i-frame :key="getKey"></local-sites-i-frame>
</template>

<script>
import LocalSitesIFrame from "@/components/LocalSitesIFrame";
import { mapGetters } from "vuex";

export default {
  components: { LocalSitesIFrame },
  computed: {
    ...mapGetters("local-sites-iframe", ["getKey"]),
  }
};
</script>

<style scoped></style>
