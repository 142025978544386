import http from './employer-http';

const DO_NOTHING = () => {};

export default {
    getUserCompanies(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get('/api/companies', {userId: payload}, {
            success,
            error
        });
    },
    setDefaultCompany(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        if(!payload) payload = '';
        return http.post('/api/companies/default', {companyId: payload}, { success, error });
    },
    getCompanyInfo(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get('/api/companies/info', payload, {
            success,
            error
        });
    },
    updateCompanyInfo(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post(`/api/companies/update`, payload, {
          success,
          error,
        });
    },
    getStandardAddress(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post('/api/geolocation', payload, {
            success,
            error
        });
    },
};
