import { isProduction } from "@/config/app-config";
import reportsService from "@/api/reports";
import { resourcesForCurrentLanguage } from "@/lang-resources";
import XLSX from "xlsx";

const langResources = resourcesForCurrentLanguage.toastMessages;

const state = () => ({
  jobPerformanceByDate: null,
});

const getters = {
  getJobPerformanceByDate(state) {
    return state.jobPerformanceByDate ? state.jobPerformanceByDate : [];
  },
};

const mutations = {
  setJobPerformanceByDate(state, value) {
    state.jobPerformanceByDate = value;
  },
};

const actions = {
  async fetchJobPerformanceSummary(
    { commit },
    { companyId, startDate, endDate }
  ) {
    return await reportsService.getJobPerformanceSummary(
      {
        CompanyId: companyId,
        StartDate: startDate,
        EndDate: endDate,
      },
      {
        success: (data) => {
          return data;
        },
        error: (error) => {
          if (error.response && error.response.status == 401) {
            commit(
              "app-messages/displayErrorMessage",
              langResources.authenticationError,
              { root: true }
            );
          } else {
            commit(
              "app-messages/displayErrorMessage",
              langResources.genericToastrError,
              { root: true }
            );
          }
          return [];
        },
      }
    );
  },
  async fetchJobPerformanceDetails(
    { commit },
    { companyId, startDate, endDate }
  ) {
    return await reportsService.getJobPerformanceDetails(
      {
        CompanyId: companyId,
        StartDate: startDate,
        EndDate: endDate,
      },
      {
        success: (data) => {
          return data;
        },
        error: (error) => {
          if (error.response && error.response.status == 401) {
            commit(
              "app-messages/displayErrorMessage",
              langResources.authenticationError,
              { root: true }
            );
          } else {
            commit(
              "app-messages/displayErrorMessage",
              langResources.genericToastrError,
              { root: true }
            );
          }
          return [];
        },
      }
    );
  },
  async exportJobPerformanceDetailsToCsv(
    { getters, commit },
    { exportedFileName, companyId, startDate, endDate }
  ) {
    await reportsService.getJobPerformanceByDate(
      {
        CompanyId: companyId,
        StartDate: startDate,
        EndDate: endDate,
      },
      {
        success: (data) => {
          commit("setJobPerformanceByDate", data);
        },
        error: (error) => {
          if (error.response && error.response.status == 401) {
            commit(
              "app-messages/displayErrorMessage",
              langResources.authenticationError,
              { root: true }
            );
          } else {
            commit(
              "app-messages/displayErrorMessage",
              langResources.genericToastrError,
              { root: true }
            );
          }
        },
      }
    );

    var payloadToExport = getters.getJobPerformanceByDate.map((d) => ({
      "Activity Date": new Date(d.activityDate).toLocaleDateString(),
      "Job ID": d.jobId,
      "Job Title": d.jobTitle,
      Views: d.views,
      "Apply Clicks": d.clicks,
    }));
    const worksheet = XLSX.utils.json_to_sheet(payloadToExport);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${exportedFileName}.csv`);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
