<template>
  <div>
    <loading-spinner v-if="isLoading"></loading-spinner>
    <div v-else class="container--fluid">
      <span class="text-center"
        ><h1 tabindex="0" autofocus>{{ $t("dashboard.header") }}</h1></span
      >
      <div v-if="outreachServiceToken" :key="outreachServiceToken">
        <outreach-pipeline-data class="mb-5" />
        <outreach-breakdown-data class="mb-5" />
        <organization-engagement-summary-data />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-12 text-center">
            You are not authorized to view the Dashboard.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
h1 {
  margin-bottom: 2rem;
}
.container--fluid {
  background-color: #f5f5f5;
  padding: 2rem;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import OutreachPipelineData from "@/components/OutreachDashboard/outreach-pipeline-data";
import OutreachBreakdownData from "@/components/OutreachDashboard/outreach-breakdown-data";
import OrganizationEngagementSummaryData from "@/components/OutreachDashboard/organization-engagement-summary-data";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    OutreachPipelineData,
    OutreachBreakdownData,
    OrganizationEngagementSummaryData,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["outreachServiceToken"]),
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
  async created() {
    if (this.getSelectedCompany) {
      await this.fetchOutreachServiceToken(this.getSelectedCompany.id);
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions("dashboard", ["fetchOutreachServiceToken"]),
  },
  watch: {
    async getSelectedCompany() {
      await this.fetchOutreachServiceToken(this.getSelectedCompany.id);
    },
  },
  filters: {},
};
</script>
