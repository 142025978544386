<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              :append-icon="icons.search"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="select.items"
              :value="select.selected"
              item-text="filterName"
              item-value="filterValue"
              @change="handleSelect"
              label="User Status"
            >
            </v-select>
          </v-col>

          <v-col
            :cols="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 12 : 2"
          >
            <v-btn
              v-if="canManageUsers"
              class="mt-3"
              color="green"
              outlined
              tile
              block
              @click="handleAdd"
              >+ Add User</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items-per-page="10"
        :footer-props="footerProps"
        item-key="emailAddress"
        :search="search"
        :loading="isLoading"
        :items="companyUsers"
        :sort-by="['userRole', 'fullName']"
        :sort-desc="[false, false]"
        multi-sort
      >
        <template v-slot:[`item.userRole`]="item">
          <span>{{ item.value | formatType }} </span>
        </template>
        <template v-slot:[`item.isActiveForCompany`]="item">
          <v-switch
            :disabled="!item.item.actionEnabled"
            class="ml-10 d-inline-block"
            v-model="item.value"
            :label="item.value ? 'Active' : 'Inactive'"
            @change="
              handleUpdateStatus(
                item.item.userId,
                !item.item.isActiveForCompany
              )
            "
          >
          </v-switch>
        </template>
        <template v-slot:[`item.updateUser`]="row">
          <v-btn
            :disabled="
              !row.item.actionEnabled &&
              row.item.userId.toUpperCase() != getUserId.toUpperCase()
            "
            @click="showUpdateModalForUser(row.item)"
            >Update User</v-btn
          >
        </template>
        <template v-slot:[`item.lastLogin`]="row">
            <span>{{ (new Date(row.item.lastLogin).getFullYear()==0o01)? "Never":new Date(row.item.lastLogin).toLocaleDateString()}}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-spacer></v-spacer>
            <v-col cols="2">
              
     <v-btn
              v-if="canManageUsers"
              class="mt-3"
              color="blue"
              outlined
              tile
              small
              block
              @click="handleExportCSV"
              >Export<v-icon lass="export-icon" color="blue"></v-icon></v-btn
            >
          </v-col>
        </v-row>
    <user-modal
      v-model="showAddEditUserModal"
      :user="userToEdit"
      @close="showAddEditUserModal = false"
      @savedUser="loadUsers"
    >
    </user-modal>
    <user-stepper-modal
      v-model="showAddEditUserStepper"
      @close="showAddEditUserStepper = false"
    >
    </user-stepper-modal>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mdiMagnify, mdiPlus, mdiMinus} from "@mdi/js";
import UserModal from "@/components/Account/Users/UserModal.vue";
import UserStepperModal from "@/components/Account/Users/Stepper/UserStepperModal.vue";

export default {
  components: { UserModal, UserStepperModal },
  data: () => ({
    companyUsers: undefined,
    icons: {
      search: mdiMagnify,
      add: mdiPlus,
      remove: mdiMinus,
    },
    isLoading: true,
    search: "",
    headers: [
      { text: "User Type", value: "userRole", width: "20%", align: "center" },
      { text: "Name", value: "fullName", width: "20%" },
      { text: "Email Address", value: "emailAddress", width: "30%" },
      { text: "Last Login", value: "lastLogin", width: "12%"},
      {
        text: "Status",
        value: "isActiveForCompany",
        width: "8%",
        align: "center",
      },
      {
        text: "",
        value: "updateUser",
        width: "10%",
        align: "center",
        sortable: false,
      },
    ],
    select: {
      items: [
        { filterName: "All", filterValue: 2 },
        { filterName: "Active", filterValue: 1 },
        { filterName: "Inactive", filterValue: 0 },
      ],
      selected: 1,
    },
    footerProps: { "items-per-page-options": [10, 25, 100] },
    showAddEditUserModal: false,
    showAddEditUserStepper: false,
    userToEdit: undefined,
  }),
  computed: {
    ...mapGetters("auth", ["getSelectedCompany", "getUserRoleId", "getUserId"]),

    canManageUsers() {
      return [1, 2].includes(this.getUserRoleId);
    },
  },

  watch: {
    async getSelectedCompany() {
      await this.loadUsers();
    },
  },
  methods: {
    ...mapActions("users", ["updateStatus", "fetchUsers"]),
    ...mapMutations("app", ["setErrorSnackbarText", "setShowErrorSnackbar"]),
    ...mapGetters("users", ["getUsers"]),

    enableActionForUsers(users, loggedInUserId) {
      const currentUser = this.getUsers().find((u) => u.userId == loggedInUserId);
      //only enable actions if user is of type ADMIN or OWNER
      //at the same time keep owner and the current user's action disabled
      users.forEach((u) => {
        if (currentUser.userRole == 2 || currentUser.userRole == 1) {
          if (u.userRole != 1 && u.userId != loggedInUserId) {
            u.actionEnabled = true;
          }
        }
      });
    },
    filterUsersByStatus(selectedFilter) {
      if (selectedFilter == 2) {
        this.companyUsers = this.getUsers();
      } else {
        this.companyUsers = this.getUsers().filter(
          (u) => u.isActiveForCompany == selectedFilter
        );
      }
    },
    handleExportCSV() {
      const csvRows = [];
      const headerRow = this.headers.map(header => header.text).join(',');
      csvRows.push(headerRow.substring(0, headerRow.lastIndexOf(',')));
      const values = (this.companyUsers.map(user => ( user.userRole == 3? "user": user.userRole ==2? "administrator":"owner" ) + ","+ user.fullName+","+user.emailAddress + ","+ user.lastLogin+","+(user.isActiveForCompany? "active": "inactive")).join('\n'));
      csvRows.push("\n"+values);
      const blob = new Blob([csvRows], { type: 'text/csv' }); 
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a') 
      a.setAttribute('href', url) 
      a.setAttribute('download', 'AuthorizedUsers.csv');
      a.click();
    },
    handleSelect(selectedVal) {
      //filter list of users based on selected filter
      this.select.selected = selectedVal;
      this.filterUsersByStatus(selectedVal);
      this.enableActionForUsers(this.companyUsers, this.getUserId.toLowerCase());
    },
    async handleUpdateStatus(userId, status) {
      try {
        await this.updateStatus({ userId, status });
      } finally {
        await this.loadUsers();
      }
    },
    handleAdd() {
      this.userToEdit = null;
      this.showAddEditUserStepper = true;
    },
    async loadUsers() {
      this.isLoading = true;
      await this.fetchUsers();
      this.filterUsersByStatus(this.select.selected);
      this.enableActionForUsers(this.companyUsers, this.getUserId.toLowerCase());
      this.isLoading = false;
    },
    showUpdateModalForUser(item) {
      this.userToEdit = {
        id: item.userId,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.emailAddress,
        userRole: item.userRole,
      };
      this.showAddEditUserModal = true;
    },
  },
  filters: {
    formatStatus(status) {
      return status ? "Active" : "Inactive";
    },
    formatType(type) {
      switch (type) {
        case 1:
          return "Owner";
        case 2:
          return "Administrator";
        case 3:
          return "User";
      }
    },
  },
  async created() {
    await this.loadUsers();
  },
};
</script>

<style scoped lang="scss">
#user-list-banner {
  font-size: 14px;
  background-color: #d0e7f9;
}
</style>
