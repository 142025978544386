import { isProduction } from "@/config/app-config";
import company from "@/api/company";
import store from "@/store";

const state = () => ({
  companyInfo: {
    name: "",
    phoneNumber: "",
    website: "",
    isFederalContractor: false,
    isStaffProvider: false,
    industryId: -1,
    companySizeId: -1,
    street: "",
    street2: "",
    cityState: "",
    zipCode: "",
    locationId: "",
  },
  latitude: null,
  longitude: null,
  isLoading: false,
  standardAddress: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zipCode: "",
  },
  needAddressVerification: false,
});

const getters = {
  getCompanyName(state) {
    return state.companyInfo.name;
  },
  getPhoneNumber(state) {
    return state.companyInfo.phoneNumber;
  },
  getWebsite(state) {
    return state.companyInfo.website;
  },
  getIsFederalContractor(state) {
    return state.companyInfo.isFederalContractor;
  },
  getIsStaffProvider(state) {
    return state.companyInfo.isStaffProvider;
  },
  getIndustryId(state) {
    return state.companyInfo.industryId;
  },
  getCompanySizeId(state) {
    return state.companyInfo.companySizeId;
  },
  getStreet(state) {
    return state.companyInfo.street;
  },
  getStreet2(state) {
    return state.companyInfo.street2;
  },
  getCityState(state) {
    return state.companyInfo.cityState;
  },
  getZipCode(state) {
    return state.companyInfo.zipCode;
  },
  getLocationId(state) {
    return state.companyInfo.locationId;
  },
  getLatitude(state) {
    return state.latitude;
  },
  getLongitude(state) {
    return state.longitude;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getNeedAddressVerification(state) {
    return state.needAddressVerification;
  },
  getStandardAddress(state) {
    return state.standardAddress;
  },
};
const mutations = {
  setCompanyName(state, val) {
    state.companyInfo.name = val;
  },
  setPhoneNumber(state, val) {
    state.companyInfo.phoneNumber = val;
  },
  setWebsite(state, val) {
    state.companyInfo.website = val;
  },
  setIsFederalContractor(state, val) {
    state.companyInfo.isFederalContractor = val;
  },
  setIsStaffProvider(state, val) {
    state.companyInfo.isStaffProvider = val;
  },
  setIndustryId(state, val) {
    state.companyInfo.industryId = val;
  },
  setCompanySizeId(state, val) {
    state.companyInfo.companySizeId = val;
  },
  setStreet(state, val) {
    state.companyInfo.street = val;
  },
  setStreet2(state, val) {
    state.companyInfo.street2 = val;
  },
  setCityState(state, val) {
    state.companyInfo.cityState = val;
  },
  setZipCode(state, val) {
    state.companyInfo.zipCode = val;
  },
  setLocationId(state, val) {
    state.companyInfo.locationId = val;
  },
  setLatitude(state, val) {
    state.latitude = val;
  },
  setLongitude(state, val) {
    state.longitude = val;
  },
  setNeedAddressVerification(state, val) {
    state.addressVerificationStatus = val;
  },
  setIsLoading(state, val) {
    state.isLoading = val;
  },
  setStandardAddress(state, val) {
    state.standardAddress = {
      city: val.city,
      state: val.state,
      street: val.street,
      street2: val.street2,
      zipCode: val.zipCode,
    };
  },
};

const actions = {
  async fetchCompanyInfo({ commit, rootGetters }) {
    commit("setIsLoading", true);

    const payload = {
      companyId: rootGetters["auth/getSelectedCompany"].id,
    };

    await company.getCompanyInfo(payload, {
      success: (companyInfo) => {
        if (companyInfo) {
          commit("setCompanyName", companyInfo.name);
          commit(
            "setPhoneNumber",
            `${companyInfo.phoneAreaCode}-${companyInfo.phoneNumber}`
          );
          commit("setWebsite", companyInfo.website);
          commit("setIsFederalContractor", companyInfo.isFederalContractor);
          commit("setIsStaffProvider", companyInfo.isStaffProvider);
          commit("setIndustryId", companyInfo.industryId);
          commit("setCompanySizeId", companyInfo.companySizeId);
          commit("setStreet", companyInfo.street);
          commit("setStreet2", companyInfo.street2);
          commit(
            "setCityState",
            companyInfo.city + ", " + companyInfo.stateCode
          );
          commit("setZipCode", companyInfo.zipCode);
          commit("setLocationId", companyInfo.locationId);
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        store.commit(
          "app-messages/displayErrorMessage",
          "Error occurred while trying to load company information",
          { root: true }
        );
      },
    });

    commit("setIsLoading", false);
  },
  async standardizeAddress({ state, commit }) {
    var needsAddressVerification = false;

    const arCityState = state.companyInfo.cityState.split(",");
    if (!arCityState || arCityState.length != 2) {
      commit("app-messages/displayErrorMessage", "Invalid City, State", {
        root: true,
      });
      return;
    }

    var addressPayload = {
      street: state.companyInfo.street,
      street2: state.companyInfo.street2,
      city: arCityState[0],
      state: arCityState[1].trim(),
      zipCode: state.companyInfo.zipCode,
    };

    await company.getStandardAddress(addressPayload, {
      success: (data) => {
        if (data.status != 1) {
          commit(
            "app-messages/displayErrorMessage",
            "Error occurred while standardizing address",
            {
              root: true,
            }
          );
          return;
        }
        if (
          addressPayload.city != data.city ||
          addressPayload.state != data.state ||
          addressPayload.street != data.street ||
          addressPayload.street2 != data.street2 ||
          addressPayload.zipCode != data.zipCode
        ) {
          commit("setStandardAddress", data);
          needsAddressVerification = true;
        } else {
          needsAddressVerification = false;
        }
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while standardizing address",
          {
            root: true,
          }
        );
        throw "Error";
      },
    });

    return needsAddressVerification;
  },
  async updateInfo(
    { rootGetters, state, commit },
    { useStandardAddress, addressVerificationStatus }
  ) {
    commit("setIsLoading", true);

    const companyId = rootGetters["auth/getSelectedCompany"].id;
    const companyLegacyId = rootGetters["auth/getSelectedCompany"].legacyId;
    const oAuthToken = rootGetters["auth/getUser"].id_token;
    const userId = rootGetters["auth/getUserId"];

    // need to get the small end of the range for each CompanySizeId(per legacy rules)
    var numEmployees = 0;
    switch (state.companyInfo.companySizeId) {
      case 1130:
        numEmployees = 1;
        break;
      case 1131:
        numEmployees = 1;
        break;
      case 1132:
        numEmployees = 11;
        break;
      case 1133:
        numEmployees = 51;
        break;
      case 1134:
        numEmployees = 201;
        break;
      case 1135:
        numEmployees = 501;
        break;
      case 1136:
        numEmployees = 1001;
        break;
      case 1137:
        numEmployees = 5001;
        break;
      case 1138:
        numEmployees = 10001;
        break;
      default:
        numEmployees = 0;
    }

    const arCityState = state.companyInfo.cityState.split(",");
    if (!arCityState || arCityState.length != 2) {
      commit("app-messages/displayErrorMessage", "Invalid City, State", {
        root: true,
      });
      commit("setIsLoading", false);
      return;
    }

    const arPhoneNumber = state.companyInfo.phoneNumber.split("-");
    if (!arPhoneNumber || arPhoneNumber.length != 3) {
      commit(
        "app-messages/displayErrorMessage",
        "Invalid Phone Number Format",
        {
          root: true,
        }
      );
      commit("setIsLoading", false);
      return;
    }
    if (
      arPhoneNumber[0].length != 3 ||
      arPhoneNumber[1].length != 3 ||
      arPhoneNumber[2].length != 4
    ) {
      commit(
        "app-messages/displayErrorMessage",
        "Invalid Phone Number Format",
        {
          root: true,
        }
      );
      commit("setIsLoading", false);
      return;
    }

    const payload = {
      oAuthToken: oAuthToken,
      companyId: companyId,
      legacyId: companyLegacyId,
      userId: userId,
      name: state.companyInfo.name,
      industryId: state.companyInfo.industryId,
      isFederalContractor: state.companyInfo.isFederalContractor,
      isStaffProvider: state.companyInfo.isStaffProvider,
      companySizeId: state.companyInfo.companySizeId,
      phoneAreaCode: arPhoneNumber[0],
      phoneNumber: `${arPhoneNumber[1]}-${arPhoneNumber[2]}`,
      website: state.companyInfo.website,
      latitude: state.latitude,
      longitude: state.longitude,
      addressVerificationStatus: addressVerificationStatus,
      numberOfEmployees: numEmployees,
      locationId: state.companyInfo.locationId
    };

    if (useStandardAddress) {
      payload.street = state.standardAddress.street;
      payload.street2 = state.standardAddress.street2;
      payload.city = state.standardAddress.city;
      payload.state = state.standardAddress.state.trim();
      payload.zip = state.standardAddress.zipCode;
    } else {
      payload.street = state.companyInfo.street;
      payload.street2 = state.companyInfo.street2;
      payload.city = arCityState[0];
      payload.state = arCityState[1].trim();
      payload.zip = state.companyInfo.zipCode;
    }

    await company.updateCompanyInfo(payload, {
      success: () => {
        commit(
          "app-messages/displaySuccessMessage",
          "Company Information updated successfully!",
          {
            root: true,
          }
        );
        commit("auth/setSelectedCompanyName", payload.name, { root: true });
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit(
          "app-messages/displayErrorMessage",
          "Error occurred while updating company information",
          {
            root: true,
          }
        );
      },
    });

    commit("setIsLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
