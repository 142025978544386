<template>
  <v-dialog v-model="value" max-width="50rem" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <h3>Verify Address</h3>
      </v-card-title>
      <v-card-subtitle class="pt-2">
        <span> The address was standardized, please verify your address </span>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-btn
              small
              outlined
              color="primary"
              class="mb-2"
              @click="handleUseOriginal"
            >
              Use Original Address
            </v-btn>

            <div class="d-flex justify-center">{{ this.getStreet }}</div>
            <div class="d-flex justify-center" v-if="this.getStreet2">
              {{ this.getStreet2 }}
            </div>
            <div class="d-flex justify-center">{{ this.getCityState }}</div>
            <div class="d-flex justify-center">{{ this.getZipCode }}</div>
          </v-col>
          <v-col>
            <v-divider vertical class="grey"></v-divider>
          </v-col>

          <v-col cols="5">
            <v-btn
              small
              outlined
              color="primary"
              class="mb-2"
              @click="handleUseStandard"
            >
              Use Standard Address
            </v-btn>

            <div class="d-flex justify-center">
              {{ this.getStandardAddress.street }}
            </div>
            <div
              class="d-flex justify-center"
              v-if="this.getStandardAddress.street2"
            >
              {{ this.getStree2 }}
            </div>
            <div class="d-flex justify-center">
              {{ this.getStandardAddress.city }},
              {{ this.getStandardAddress.state }}
            </div>
            <div class="d-flex justify-center">
              {{ this.getStandardAddress.zipCode }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
          <v-col>
            <v-btn small depressed color="warning" @click="handleEditAddress">
              Edit Address</v-btn
            >
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck } from "@mdi/js";
import { mapGetters } from "vuex";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      icons: {
        check: mdiCheck,
      },
      isOpen: true,
    };
  },
  methods: {
    handleEditAddress() {
      this.$emit("close");
    },
    handleUseStandard() {
      this.$emit("submitAddress", true);
    },
    handleUseOriginal() {
      this.$emit("submitAddress", false);
    },
  },
  computed: {
    ...mapGetters("company-info", [
      "getStreet",
      "getStreet2",
      "getCityState",
      "getZipCode",
      "getStandardAddress",
    ]),
  },
};
</script>

<style>
</style>