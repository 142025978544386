<template>
  <v-container fluid class="authorized-users">
    <span class="text-center"><h1 class="mb-6">Authorized Users</h1></span>
    <user-list />
  </v-container>
</template>

<script>
import UserList from "@/components/Account/Users/UserList";

export default {
  components: { UserList },
};
</script>

<style scoped lang="scss">
.authorized-users {
  padding: 2rem 4rem;
}
</style>