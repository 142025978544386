import { isProduction } from "@/config/app-config";
import documentCenter from "@/api/document-center/documents";
import DocumentTypes from "@/api/document-center/document-types";

const state = () => ({
  screenshots: [],
  isLoading: false,
});

const getters = {
  getScreenshots(state) {
    return state.screenshots;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};
const mutations = {
  setScreenshots(state, screenshots) {
    state.screenshots = screenshots;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

const actions = {
  async retrieveScreenshots({ commit }, payload) {
    commit("setLoading", true);
    return documentCenter.getJobScreenshots(payload, {
      success: (data) => {
        commit("setScreenshots", data);
        commit("setLoading", false);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit("setLoading", false);
        commit("app-messages/displayErrorMessage", "Error occurred while trying to retrieve screenshots.", { root: true });
      },
    });
  },
  async retrieveScreenshotUrl({ rootGetters, commit }, payload) {
    return documentCenter.getDocumentUrl(
      {
        Id: payload.ScreenshotId,
        Type: DocumentTypes.Screenshot,
        CompanyId: rootGetters['auth/getSelectedCompany'].id
      }, {
      success: (url) => {
        return url;
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        commit("app-messages/displayErrorMessage", "Error occurred while trying show screenshot.", { root: true });
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  strict: !isProduction,
};
