import http from '@/api/employer-http';

const DO_NOTHING = () => {};

export default {
    getUnread(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get(`/api/Notifications/company/${payload.companyId}/user/${payload.userId}/3`, {}, {
            success,
            error
        });
    },
    getAll(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get(`/api/Notifications/company/${payload.companyId}/user/${payload.userId}/1`, {}, {
            success,
            error
        });
    },
    setRead(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.put(`/api/Notifications/read`, payload, {
            success,
            error
        });
    },
    getPreferences(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.get(`/api/Notifications/preferences`, payload, {
            success,
            error
        });
    },
    setOptIn(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post(`/api/Notifications/preferences/optin`, payload, {
            success,
            error
        });
    },
    setOptOut(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post(`/api/Notifications/preferences/optout`, payload, {
            success,
            error
        });
    }
};
