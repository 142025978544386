<template>
  <div>
    <div class="container--fluid">
      <span class="text-center"><h1 tabindex="0" autofocus>Job Performance</h1></span>
      <v-card class="pa-3">
        <v-row
          ><v-col>
            <v-form
              ref="filters"
              lazy-validation
              v-model="filtersAreValid"
              class="mb-n7"
            >
              <v-row no-gutters justify="end">
                <v-col xs="12" sm="8" md="5" lg="4" xl="3"
                  ><div class="d-flex pt-2 mb-n3 text-subtitle-1" tabindex="0">
                    Search by Activity Date
                  </div></v-col
                >
              </v-row>
              <v-row no-gutters justify="end">
                <v-col xs="12" sm="8" md="5" lg="5" xl="3" class="d-flex">
                  <date-input v-model="dateFilterModel" />
                  <div class="pl-5 py-5">
                    <v-btn
                      @click="loadData"
                      class="mr-4"
                      :disabled="!filtersAreValid"
                      color="primary"
                      left
                      small
                      v-show="!$vuetify.breakpoint.xs"
                    >
                      <v-icon>{{ icons.search }}</v-icon>
                      Search
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="$vuetify.breakpoint.xs">
                <v-col cols="12" justify="center">
                  <v-btn
                    class="mb-5"
                    @click="loadData"
                    :disabled="!filtersAreValid"
                    color="primary"
                    large
                    text
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col></v-row
        >
        <v-row>
          <v-col>
            <v-row>
              <v-col xs="12" sm="5" md="3" lg="3" xl="2">
                <v-select
                  v-if="!isSummaryLoading"
                  class="ml-14"
                  @change="loadChartData"
                  v-model="selectedOption"
                  :items="selectOptions"
                  label="Job Activities"
                  dense
                  tabindex="-1"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <job-performance-chart
              class="mt-n4"
              v-if="!isSummaryLoading"
              :chart-data="chartData"
            />
            <loading-spinner v-else class="ma-10"></loading-spinner>
          </v-col>
        </v-row>
      </v-card>
      <v-data-table
        :headers="detailsTableHeaders"
        :items="jobPerformanceDetails"
        item-key="jobId"
        class="elevation-1 mt-5"
        :loading="isDetailsLoading"
        loading-text="Loading... Please wait"
        :sort-by.sync="detailsTableSortBy"
        :sort-desc.sync="detailsTableSortDesc"
        tabindex="0"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="flex-grow-1"></div>
            <v-btn
              :disabled="jobPerformanceDetails.length === 0"
              class="mb-2"
              :loading="loadingExportData"
              @click="exportTable"
              >Export<v-icon right class="export-icon" color="#1D6F42">{{
                icons.export
              }}</v-icon>
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="25"
                ></v-progress-circular
              ></template>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          <span aria-live="polite" aria-atomic="true">{{pageStart}}-{{pageStop}} of {{itemsLength}}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style scoped lang="scss">
h1 {
  margin-bottom: 2rem;
}
.container--fluid {
  padding: 2rem;
}
</style>

<script>
import { mdiFileSearchOutline, mdiFileExport } from "@mdi/js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import JobPerformanceChart from "@/components/Reports/JobPerformance/job-performance-chart";
import LoadingSpinner from "@/components/LoadingSpinner";
import DateInput from "@/components/form/FromToDateInput";
import moment from "moment";
import { isEmpty } from "lodash";

export default {
  components: {
    JobPerformanceChart,
    DateInput,
    LoadingSpinner,
  },

  data() {
    return {
      loadingExportData: false,
      selectOptions: [
        { text: "Apply Clicks", value: 0 },
        { text: "Views", value: 1 },
      ],
      selectedOption: 0,
      isSummaryLoading: true,
      isDetailsLoading: true,
      detailsTableSortBy: "views",
      detailsTableSortDesc: true,
      detailsTableHeaders: [
        {
          value: "jobId",
          text: "Job ID",
        },
        {
          value: "jobTitle",
          text: "Job Title",
        },
        {
          value: "views",
          text: "Views",
        },
        {
          value: "clicks",
          text: "Apply Clicks",
        },
      ],
      dateFilterModel: {
        fromDate: moment().subtract(3, "months").toISOString().substr(0, 10),
        toDate: moment().toISOString().substr(0, 10),
        validationRules: {
          fromDateRules: [
            (v) => {
              return !isEmpty(v) || "From Date is required";
            },
            (v) => {
              let dateSelected = moment(v, "MM/DD/YYYY").startOf("day");
              return (
                dateSelected >= moment().subtract(2, "years").startOf("day") ||
                "Selected date cannot be more than 2 years in the past."
              );
            },
            (v) => {
              let dateSelected = moment(v, "MM/DD/YYYY").startOf("day");
              let mindate = new Date(2021, 9, 1);
              return (
                dateSelected >= moment(mindate, "MM/DD/YYYY").startOf("day") ||
                "Minimum date cannot be before October 1st 2021"
              );
            },
          ],
          toDateRules: [
            (v) => {
              return !isEmpty(v) || "To Date is required";
            },
            (v) => {
              let dateSelected = moment(v, "MM/DD/YYYY").startOf("day");
              return (
                dateSelected >= moment().subtract(2, "years").startOf("day") ||
                "Selected date cannot be more than 2 years in the past."
              );
            },
          ],
        },
      },
      filtersAreValid: true,
      icons: {
        search: mdiFileSearchOutline,
        export: mdiFileExport,
      },
      jobPerformanceSummary: [],
      jobPerformanceDetails: [],
    };
  },
  computed: {
    ...mapGetters("job-performance", ["getJobPerformanceByDate"]),
    ...mapGetters("auth", ["getCompanies", "getSelectedCompany"]),
  },
  async created() {
    await this.loadData();
  },
  mounted() {
      const sortableColumns = this.$el.querySelectorAll('th.sortable > span:not(.v-icon)');

      sortableColumns.forEach(col => {
        col.setAttribute('tabindex', '0')

        col.addEventListener('keyup', this.columnKeyUp)
      });
    },
  methods: {
    columnKeyUp(event) {
      if (event.key !== " " && event.key !== "Enter") return

      event.target.click()
    },
    ...mapActions("job-performance", [
      "fetchJobPerformanceSummary",
      "fetchJobPerformanceDetails",
      "exportJobPerformanceDetailsToCsv",
    ]),
    ...mapMutations("app-messages", ["displaySuccessMessage"]),
    loadChartData() {
      this.selectedOption === 0 ? this.fillClickData() : this.fillViewData();
    },
    fillViewData() {
      this.chartData = {
        labels: this.jobPerformanceSummary.map((e) =>
          new Date(e.date).toLocaleDateString("en-US")
        ),
        datasets: [
          {
            label: "Views",
            borderColor: "#004a98",
            backgroundColor: "#004a98",
            data: this.jobPerformanceSummary.map((e) => e.views),
            fill: false,
          },
        ],
      };
    },
    fillClickData() {
      this.chartData = {
        labels: this.jobPerformanceSummary.map((e) =>
          new Date(e.date).toLocaleDateString("en-US")
        ),
        datasets: [
          {
            label: "Apply Clicks",
            borderColor: "#00bc70",
            backgroundColor: "#00bc70",
            data: this.jobPerformanceSummary.map((e) => e.clicks),
            fill: false,
          },
        ],
      };
    },
    async loadData() {
      await this.loadSummary();
      await this.loadDetails();
    },
    async loadSummary() {
      this.isSummaryLoading = true;
      this.jobPerformanceSummary = [];

      this.jobPerformanceSummary = await this.fetchJobPerformanceSummary({
        companyId: this.getSelectedCompany.id,
        startDate: this.dateFilterModel.fromDate,
        endDate: this.dateFilterModel.toDate,
      });
      this.loadChartData();
      this.isSummaryLoading = false;
    },
    async loadDetails() {
      this.isDetailsLoading = true;
      this.jobPerformanceDetails = [];

      this.jobPerformanceDetails = await this.fetchJobPerformanceDetails({
        companyId: this.getSelectedCompany.id,
        startDate: this.dateFilterModel.fromDate,
        endDate: this.dateFilterModel.toDate,
      });

      this.displaySuccessMessage(`${this.jobPerformanceDetails.length} jobs found.`);

      this.isDetailsLoading = false;
    },
    async exportTable() {
      this.loadingExportData = true;
      let formattedFromDate = new Date(
        this.dateFilterModel.fromDate
      ).toLocaleDateString("en-US", { timeZone: "UTC" });
      let formattedToDate = new Date(
        this.dateFilterModel.toDate
      ).toLocaleDateString("en-US", { timeZone: "UTC" });

      await this.exportJobPerformanceDetailsToCsv({
        exportedFileName: `Job Performance ${formattedFromDate} - ${formattedToDate}`,
        companyId: this.getSelectedCompany.id,
        startDate: this.dateFilterModel.fromDate,
        endDate: this.dateFilterModel.toDate,
      });
      this.loadingExportData = false;
    },
  },
  watch: {
    getSelectedCompany: "loadData",
  },
};
</script>

<style scoped>
.v-text-field.v-input {
  width: 11rem;
}
</style>
