<template>
  <v-container>
    <v-card class="notifications">
      <notifications-list highlight-unread v-model="getAllNotifications" />
    </v-card>
  </v-container>
</template>

<style scoped>
.notifications {
  max-width: 1000px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationsList from "@/components/Notifications/NotificationsList";

export default {
  name: "Notifications",
  components: { NotificationsList },
  props: {
    value: Object,
  },
  computed: {
    ...mapGetters("notification", ["getAllNotifications"]),
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
  methods: {
    ...mapActions("notification", ["fetchAllNotifications"]),
  },
  async created() {
    await this.fetchAllNotifications();
  },
  watch: {
    async getSelectedCompany() {
      await this.fetchAllNotifications();
    },
  },
};
</script>