import { isProduction } from "@/config/app-config";
import outreachService from "@/api/outreach-service";
import dayjs from 'dayjs';
import { resourcesForCurrentLanguage } from '@/lang-resources';

const translatedComponentResources = resourcesForCurrentLanguage.outreachBreakdownData;

const actionTypeMapping = {
    jobsSent: translatedComponentResources.jobLinksSent,
    jobViews: translatedComponentResources.jobViews,
    applicants: translatedComponentResources.applicants,
};

const reducer = (accumulator, currentValue) => accumulator + currentValue;

const getActionPercentage = (outreachBreakdownData, numberActions, actionType) => {
    let totalNumberActions = !outreachBreakdownData ? 0 : Object.values(outreachBreakdownData[actionType]).reduce(reducer);
    if (totalNumberActions === 0) return 0;
    return parseInt((numberActions * 100 / totalNumberActions).toFixed(0));
};

const state = () => ({
    currentActionType: 'jobsSent',
    outreachBreakdownData: null,
    isLoadingError: false,
});

const getters = {
    allZeroesMessage(state) {
        return translatedComponentResources.noActionsMessage[state.currentActionType];
    },
    currentActionType(state) {
        return state.currentActionType;
    },
    isCurrentActionAllZeroes(state) {
        if (state.outreachBreakdownData) {
            const currentActionTypeData = state.outreachBreakdownData[state.currentActionType];
            return Object.values(currentActionTypeData).reduce(
                function (acc, curr) { return acc && curr === 0 }
                , true);
        }
        else return false;
    },
    isLoadingError(state) {
        return state.isLoadingError;
    },
    currentActionTypeName(state) {
        return actionTypeMapping[state.currentActionType];
    },
    orderedChartOptions: (state) => (actionType) => {
        const options = [
            { label: translatedComponentResources.minorities, activityPercent: !state.outreachBreakdownData ? 0 : getActionPercentage(state.outreachBreakdownData, state.outreachBreakdownData[actionType].minorities, actionType) },
            { label: translatedComponentResources.veterans, activityPercent: !state.outreachBreakdownData ? 0 : getActionPercentage(state.outreachBreakdownData, state.outreachBreakdownData[actionType].veterans, actionType) },
            { label: translatedComponentResources.iwd, activityPercent: !state.outreachBreakdownData ? 0 : getActionPercentage(state.outreachBreakdownData, state.outreachBreakdownData[actionType].iwd, actionType) },
            { label: translatedComponentResources.women, activityPercent: !state.outreachBreakdownData ? 0 : getActionPercentage(state.outreachBreakdownData, state.outreachBreakdownData[actionType].women, actionType) },
            { label: translatedComponentResources.stateWorkforce, activityPercent: !state.outreachBreakdownData ? 0 : getActionPercentage(state.outreachBreakdownData, state.outreachBreakdownData[actionType].stateWorkforce, actionType) },
            { label: translatedComponentResources.other, activityPercent: !state.outreachBreakdownData ? 0 : getActionPercentage(state.outreachBreakdownData, state.outreachBreakdownData[actionType].other, actionType) },
        ];

        return options.slice().sort((a, b) => b.activityPercent - a.activityPercent);

    },
    options(state, getters) {
        return {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var currentValue = dataset.data[tooltipItem.index];
                        return currentValue + '%';
                    }
                }
            },
            legend: {
                display: false,
            },
            plugins: {
                datalabels: {
                    formatter: function (value, context) {
                        return context.chart.data.labels[context.dataIndex] + ': ' + context.chart.data.datasets[0].data[context.dataIndex] + '%';
                    },
                    color: () => getters.orderedChartOptions(getters.currentActionType).map(opt => opt.activityPercent < 50 ? 'black' : 'white'),
                    align: () => getters.orderedChartOptions(getters.currentActionType).map(opt => opt.activityPercent < 50 ? 'right' : 'left')
                }
            },
            layout: {
                padding: {
                    top: 12,
                    left: 12,
                    bottom: 12
                }
            },
            scales: {
                xAxes: [
                    {
                        display: false,
                        gridLines: {
                            display: false,
                            tickMarkLength: 0,
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            beginAtZero: true,
                            padding: 5,
                            callback: function (value) {
                                return '' + value + '%';
                            }
                        },
                        scaleLabel: {
                            display: false,
                        }
                    }
                ],
                yAxes: [
                    {
                        ticks: {
                            mirror: true,
                            fontColor: "white",
                            callback: function (value) {
                                return '' + value + '%';
                            },
                            display: false
                        },
                        gridLines: {
                            display: false,
                            tickMarkLength: 0,
                        }
                    }
                ]
            },

        }
    },
    outreachBreakdownData(state, getters) {
        const actionType = state.currentActionType;
        if(!state.outreachBreakdownData) {
            return undefined;
        }
        
        return {
            labels: getters.orderedChartOptions(actionType).map(opt => opt.label),
            datasets: [
                {
                    borderColor: 'rgb(0,0,0)',
                    backgroundColor: '#599c30',
                    data: getters.orderedChartOptions(actionType).map(opt => opt.activityPercent),
                    barPercentage: 1,
                    borderWidth: 0,
                    datalabels: {
                        anchor: 'end',
                        font: function (context) {
                            var width = context.chart.width;
                            var size = Math.round(width / 64);
                            if (size < 12) size = 12;
                            else if (size > 20) size = 20;
                            return {
                                size: size,
                                weight: 550
                            };
                        }
                    }
                }
            ]
        };
    },
    outreachBreakdownCategoryPercentage: (getters) => (actionType, category) => {
        if(!getters.outreachBreakdownData) {
            return '';
        }
        return getActionPercentage(getters.outreachBreakdownData, getters.outreachBreakdownData[actionType][category], actionType);
    },
    outreachBreakdownDataSummary(state, getters) {
        return `People of Color: ${getters.outreachBreakdownCategoryPercentage(state.currentActionType, 'minorities')}%, ` +
            `Women: ${getters.outreachBreakdownCategoryPercentage(state.currentActionType, 'women')}%, ` +
            `Veterans: ${getters.outreachBreakdownCategoryPercentage(state.currentActionType, 'veterans')}%, ` +
            `I.W.D.: ${getters.outreachBreakdownCategoryPercentage(state.currentActionType, 'iwd')}%, ` +
            `State Workforce: ${getters.outreachBreakdownCategoryPercentage(state.currentActionType, 'stateWorkforce')}% ` +
            `Other: ${getters.outreachBreakdownCategoryPercentage(state.currentActionType, 'other')}%`;
    }
}

const mutations = {
    setOutreachBreakdownData(state, val) {
        state.outreachBreakdownData = val;
    },
    setCurrentActionType(state, val) {
        state.currentActionType = val;
        state.allZeroesMessage = translatedComponentResources.noActionsMessage[val];
    },
    setIsLoadingError(state, val) {
        state.isLoadingError = val;
    }
}

const actions = {
    fetchOutreachBreakdownData({ commit }) {
        commit("setIsLoadingError", false);
        commit("setOutreachBreakdownData", null);
        return outreachService.getOutreachBreakdownData({
            "StartDate": dayjs().add(-1, 'month').format('MM/DD/YYYY'),
            "EndDate": dayjs().format('MM/DD/YYYY')
        }, {
            success: (data) => {
                commit("setIsLoadingError", false);
                commit("setOutreachBreakdownData", data);
                commit("setCurrentActionType", 'jobsSent');
            },
            error: (error) => {
                commit("setIsLoadingError", true);
                if (error.response && error.response.status == 401) {
                    commit("app-messages/displayErrorMessage", translatedComponentResources.authenticationerror, { root: true });
                }
                else {
                    commit("app-messages/displayErrorMessage", translatedComponentResources.genericToastrError, { root: true });
                }
            }
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    strict: !isProduction,
};
