import { isProduction } from "@/config/app-config";

const state = () => ({
  displaySuccessMessage: false,
  displayErrorMessage: false,
  successMessageText: '',
  errorMessageText: ''
});

const getters = {
    getDisplaySuccessMessage(state) {
        return state.displaySuccessMessage;
    },
    getDisplayErrorMessage(state) {
        return state.displayErrorMessage;
    },
    getSuccessMessageText(state) {
        return state.successMessageText;
    },
    getErrorMessageText(state) {
        return state.errorMessageText;
    }
};

const mutations = {
    displaySuccessMessage(state, value) {
        state.successMessageText = value;
        state.displaySuccessMessage = true;
    },
    displayErrorMessage(state, value) {
        state.errorMessageText = value;
        state.displayErrorMessage = true;
    },
    setDisplaySuccessMessage(state, value) {
        state.displaySuccessMessage = value;
    },
    setDisplayErrorMessage(state, value) {
        state.displayErrorMessage = value;
    }
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
