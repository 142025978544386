import { mixins, HorizontalBar } from "vue-chartjs";
const { reactiveProp } = mixins;
let options = {
  responsive: true,
  title: {
    display: true,
    text: "Diversity Category Breakdown",
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        return (
          data["labels"][tooltipItem["index"]] +
          ": " +
          data["datasets"][0]["data"][tooltipItem["index"]] +
          "%"
        );
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          fontStyle: "bold",
          stepSize: 10,
          beginAtZero: true,
          callback: function(value) {
            return value + "%";
          },
          max: 100,
          precision: 2,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontStyle: "bold",
        },
      },
    ],
  },
};

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, options);
  },
  watch: {
    //add watcher to tear down chart because options
    //is not reactive (otherwise we'd have to declare it in state )
    chartData() {
      this.renderChart(this.chartData, options);
    },
  },
};
