import { isProduction } from "@/config/app-config";
import documentCenter from "@/api/document-center/documents";
import DocumentTypes from "@/api/document-center/document-types";

const state = () => ({
  documents: [],
});

const getters = {
  getDocuments(state) {
    return state.documents;
  },
};

const mutations = {
  setDocuments(state, val) {
    state.documents = val;
  },
};

const actions = {
  fetchDocuments({ commit }, payload) {
    return documentCenter.getReportCards(payload, {
      success: (data) => {
        commit("setDocuments", data);
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }
        commit("app-messages/displayErrorMessage", "Error occurred while trying to retrieve documents.", { root: true });
      },
    });
  },
  async retrieveReportCardUrl({ rootGetters, commit }, payload) {
    return documentCenter.getDocumentUrl({
      Id: payload.ReportCardId,
      Type: DocumentTypes.ReportCard,
      CompanyId: rootGetters['auth/getSelectedCompany'].id
    }, {
      success: (url) => {
        return url;
      },
      error: (error) => {
        if (!isProduction) {
          console.log(error);
        }

        commit("app-messages/displayErrorMessage", "Error occurred while trying show Report Card.", { root: true });
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: !isProduction,
};
