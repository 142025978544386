import router from "@/router";
const productAuthorizations = {
  verifySubscription: (company) => {
    let {
      options: { routes },
    } = router;

    let diversityRoute = getRoute(routes, "path", "/diversity");
    let diversityRouteUnauth = getRoute(
      routes,
      "path",
      "/diversity/unauthorized"
    );
    if (getProductStatus(company, "OMS")) {
      if (company && companyHasAnyOFCCPFeature(company)) {
        getRoute(
          diversityRoute.children,
          "path",
          "account-managment"
        ).meta.show = true;
        diversityRoute.name = "OFCCP Compliance";
        diversityRoute.meta.show = true;
        diversityRouteUnauth.meta.show = false;
      } else {
        diversityRoute.name = "Diversity Recruiting";
        diversityRoute.meta.show = true;
        diversityRouteUnauth.meta.show = false;
      }
    } else {
      if (company && companyHasAnyOFCCPFeature(company)) {
        diversityRouteUnauth.name = "OFCCP Compliance";
      } else {
        diversityRouteUnauth.name = "Diversity Recruiting";
      }
    }

    //ljnjp views
    if (company && company.hadPriorProdAuths["LJNJP"]) {
      getRoute(routes, "path", "/jobs").meta.show = true;
      getRoute(routes, "path", "/applicant-tracking").meta.show = true;
      getRoute(routes, "path", "/reports").children.find((children) => {
        return children.path === "reports-all";
      }).meta.show = true;
      getRoute(routes, "path", "/reports").children.find((children) => {
        return children.path === "reports-center";
      }).meta.show = true;
    }

    const hasAICM = getProductStatus(company, "AICM");
    company.hasAICM = hasAICM;

    let resumeMenuItem = getRoute(routes, "path", "/resumes");
    getRoute(resumeMenuItem.children, "path", "diversity-sourcing").meta.show =
      hasAICM;
    getRoute(
      resumeMenuItem.children,
      "path",
      "diversity-sourcing/unauthorized"
    ).meta.show = !hasAICM;

    const accountMenu = getRoute(routes, "path", "/account");
    let brandingMenuOption = getRoute(accountMenu.children, "path", "branding");
    let profileMenuOption = getRoute(accountMenu.children, "path", "profile");

    if (getProductStatus(company, "BRAND")) {
      brandingMenuOption.meta.show = true;
      profileMenuOption.meta.show = false;
    } else {
      brandingMenuOption.meta.show = false;
      profileMenuOption.meta.show = true;
    }

    if (getProductStatus(company, "ABI")) {
      let abiMenuOption = getRoute(routes, "path", "/abi");
      let abiUnauthMenuOption = getRoute(routes, "path", "/abi/unauthorized");
      abiMenuOption.meta.show = true;
      abiUnauthMenuOption.meta.show = false;
    }
  },
};

function getProductStatus(company, product) {
  let authorization = company.productAuthorizations.find(
    (x) => x.name == product
  );
  return authorization ? authorization.status : false;
}

function companyHasAnyOFCCPFeature(company) {
  return company.productAuthorizations.some((p) => {
    return p.name.indexOf("OFCCP") >= 0 && p.status === true;
  });
}

function getRoute(routes, key, value) {
  return routes.find((r) => r[key] === value);
}

export default productAuthorizations;
