import { isProduction } from "@/config/app-config";
import outreachService from "@/api/outreach-service";
import dayjs from 'dayjs';
import { resourcesForCurrentLanguage } from '@/lang-resources';

const langResources = resourcesForCurrentLanguage.organizationEngagementSummary;

const state = () => ({
    organizationEngagementSummaryData: undefined,
    currentPage: 1,
    numberRows: 0
});

const getters = {
    currentPage(state) {
        return state.currentPage;
    },
    numberRows(state) {
        return state.numberRows;
    },
    organizationEngagementSummaryData(state) {
        return state.organizationEngagementSummaryData;
    }
}

const mutations = {
    setOrganizationEngagementSummaryData(state, data) {
        data.forEach((element, index) => {
            element.id = index;
            element.channel = mapAgentGroupTypes(element.agentGroupTypes);
            element.recipientEngagement.forEach(recipient => recipient.channel = mapAgentGroupTypes(recipient.agentGroupTypes));
        });
        state.organizationEngagementSummaryData = data;
        state.numberRows = data.length;
    }
}

const actions = {
    fetchOrganizationEngagementSummaryData({ commit }) {
        outreachService.getOrganizationEngagementSummaryData({
            "StartDate": dayjs().add(-1, 'month').format('MM/DD/YYYY'),
            "EndDate": dayjs().format('MM/DD/YYYY')
        }, {
            success: (data) => { commit("setOrganizationEngagementSummaryData", data); },
            error: (error) => {
                if (error.response && error.response.status == 401) {
                    commit("app-messages/displayErrorMessage", langResources.authenticationError, { root: true });
                }
                else {
                    commit("app-messages/displayErrorMessage", langResources.genericToastrError, { root: true });
                }
            }
        });
    },
}

function mapAgentGroupTypes(agentGroupTypes) {
    if (agentGroupTypes.length > 1) {
        return langResources.multipleChannel;
    }
    else {
        switch (agentGroupTypes[0]) {
            case 2: return langResources.automaticOutreachChannel
            case 4: return langResources.linkageChannel
            case 6: return langResources.vetConnectChannel
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    strict: !isProduction,
};