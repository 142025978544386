<template>
  <v-container>
    <v-dialog
      v-model="value"
      persistent
      max-width="50rem"
      @keydown.enter="save"
    >
      <v-card>
        <v-card-title v-show="userIsCurrentUser" class="text-h5 justify-center"
          >My Profile</v-card-title
        >
        <v-card-title v-show="!userIsCurrentUser" class="text-h5 justify-center"
          >Edit User</v-card-title
        >
        <v-card-text>
          <v-overlay :value="isLoading" :absolute="true">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
          <v-form ref="form" v-model="isFormValid">
            <v-row>
              <v-col cols="6">
                <v-select
                  label="User Type"
                  :disabled="userIsCurrentUser"
                  max-width="25rem"
                  item-text="text"
                  item-value="value"
                  v-model="mod_user.userRole"
                  :items="userRoles"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="mod_user.email"
                  label="Email"
                  :rules="emailValidation"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              ><v-col class="pt-1 pb-1">
                <v-text-field
                  v-model="mod_user.firstName"
                  label="First Name"
                  :rules="firstNameValidation"
                  :disabled="!userIsCurrentUser"
                ></v-text-field> </v-col
              ><v-col class="pt-1 pb-1">
                <v-text-field
                  v-model="mod_user.lastName"
                  label="Last Name"
                  :rules="lastNameValidation"
                  :disabled="!userIsCurrentUser"
                ></v-text-field> </v-col
            ></v-row>
            <password-component
              :isCurrentUser="userIsCurrentUser"
              :newUser="user == null"
              :user="mod_user"
              ref="password"
              @passwordIsValid="(value) => (passwordIsValid = value)"
              @resettingPassword="(value) => resetPasswordHandler(value)"
            ></password-component>
            <user-permissions-component
              :isCurrentUser="userIsCurrentUser == true"
              :user="mod_user"
              :value="value"
            ></user-permissions-component>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="close"> Cancel </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="save"
            :loading="isLoading"
            :disabled="isLoading || !formIsValid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped lang="scss"></style>

<script>
import { mapGetters, mapActions } from "vuex";
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiEyeOff } from "@mdi/js";
import UserPermissionsComponent from "@/components/Account/Users/UserPermissionsComponent.vue";
import PasswordComponent from "@/components/Account/Users/PasswordComponent.vue";
//import userAPI from "@/api/users.js";

var pattern =
  '(([a-zA-Z0-9_.?"\\-]+)([a-zA-Z0-9<>(),;:+".+<>[]?\\]*)@((([0-9]{3}.[0-9]{3}.[0-9]{3}.[0-9]{3}){1})|(([a-zA-Z0-9.\\[\\]]+)([a-zA-Z0-9.\\[\\]-]*)([a-zA-Z0-9.\\[\\]]+)([.{1}])([a-zA-Z.\\[\\]-]+)))$)';
const emailRegex = new RegExp(pattern);

export default {
  components: {
    UserPermissionsComponent,
    PasswordComponent,
  },
  name: "UserModal",
  props: {
    value: Boolean,
    user: Object,
    currentUser: Boolean,
  },
  data() {
    return {
      icons: {
        pass: mdiCheckCircle,
        fail: mdiCloseCircle,
        visible: mdiEye,
        notVisible: mdiEyeOff,
      },
      isFormValid: true,
      isLoading: false,
      mod_user: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        userRole: 3,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        accessJobs: false,
        accessResumes: false,
        accessApplicants: false,
        accessOFCCPAccountManagement: false,
        accessReports: false,
        editAllJobPostings: false,
        accessDiversitySourcing: false,
      },
      passwordIsValid: false,
      resettingPassword: false,
      userRoles: [
        { text: "User", value: 3 },
        { text: "Administrator", value: 2 },
        { text: "Owner", value: 1 },
      ],
    };
  },
  watch: {
    user(newValue) {
      //use to initialize
      this.reset();
      if (newValue == null) {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      } else if (newValue) {
        this.initializeModalWithUser(newValue);
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }
    },
  },
  mounted() {
    if (this.user) {
      this.initializeModalWithUser(this.user);
    }
  },
  methods: {
    ...mapActions("users", ["fetchUserPermissions", "saveUser", "fetchUsers"]),
    ...mapActions("auth", ["signIn", "clearUserStore"]),

    async save() {
      if (this.formIsValid) {
        this.isLoading = true;
        if (await this.saveUser(this.mod_user)) {
          this.$emit("savedUser");
          this.close();
        }
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close");
      this.reset();
    },
    async initializeModalWithUser(user) {
      this.mod_user.id = user.id;
      this.mod_user.firstName = user.firstName;
      this.mod_user.lastName = user.lastName;
      this.mod_user.email = user.email;
      this.mod_user.userRole = user.userRole;
      this.mod_user.newPassword = "";
      this.mod_user.currentPassword = "";
      this.mod_user.confirmPassword = "";
      var permissions = await this.fetchUserPermissions(user.id);
      this.mod_user.accessJobs = permissions.accessJobs;
      this.mod_user.accessResumes = permissions.accessResumes;
      this.mod_user.accessApplicants = permissions.accessApplicants;
      this.mod_user.accessOFCCPAccountManagement =
        permissions.accessOFCCPAccountManagement;
      this.mod_user.accessReports = permissions.accessReports;
      this.mod_user.editAllJobPostings = permissions.editAllJobPostings;
      this.mod_user.accessDiversitySourcing =
        permissions.accessDiversitySourcing;
      this.fetchUsers();
      //adds or removes owner type depending on logged in user's role
      const ownerUserRoleId = 1;
      const ownerIndex = this.userRoles.findIndex(
        (r) => r.value == ownerUserRoleId
      );
      if (this.userIsCurrentUser && user.userRole == ownerUserRoleId) {
        if (ownerIndex < 0) {
          this.userRoles.push({ text: "Owner", value: 1 });
        }
      } else {
        if (ownerIndex >= 0) {
          this.userRoles.splice(ownerIndex, 1);
        }
      }
    },

    reset() {
      this.mod_user.id = "";
      this.mod_user.firstName = "";
      this.mod_user.lastName = "";
      this.mod_user.email = "";
      this.mod_user.newPassword = "";
      this.mod_user.currentPassword = "";
      this.mod_user.userRole = 3;
      this.mod_user.accessJobs = false;
      this.mod_user.accessResumes = false;
      this.mod_user.accessApplicants = false;
      this.mod_user.accessOFCCPAccountManagement = false;
      this.mod_user.accessReports = false;
      this.mod_user.editAllJobPostings = false;
      this.mod_user.accessDiversitySourcing = false;
    },
    resetPasswordHandler(newValue) {
      this.resettingPassword = newValue;
      if (!this.resettingPassword) {
        this.mod_user.newPassword = "";
        this.mod_user.currentPassword = "";
        this.mod_user.confirmPassword = "";
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["usesSSO", "getUserId"]),
    ...mapGetters("users", ["getUsers"]),

    userIsCurrentUser() {
      return this.getUserId.toUpperCase() == this.mod_user.id.toUpperCase();
    },
    firstNameValidation() {
      return [
        (name) => {
          return name.length > 0 || "First name is required";
        },
        (name) => {
          return name.length <= 40 || "Too many characters";
        },
      ];
    },
    lastNameValidation() {
      return [
        (name) => {
          return name.length > 0 || "Last name is required";
        },
        (name) => {
          return name.length <= 50 || "Too many characters";
        },
      ];
    },
    emailValidation() {
      return [
        (email) => {
          return email.length > 0 || "Email is required";
        },
        (email) => {
          return email.length <= 80 || "Too many characters";
        },
        (email) => {
          return emailRegex.test(email) || "Email address is invalid";
        },
        (email) => {
          var temp = email.toLocaleLowerCase().toString();

          if (
            temp.includes("..") ||
            temp.includes(".@") ||
            temp.startsWith(".") ||
            temp.startsWith("?") ||
            temp.endsWith(".web") ||
            temp.indexOf(" ") != -1
          ) {
            return "Email address is invalid";
          }

          return true;
        },
        (email) => {
          if (emailRegex.test(email)) {
            var filteredCompanyUsers = this.getUsers.filter(
              (cu) => cu.emailAddress !== this.user.email
            );
            const userExistsInCurrCompany = filteredCompanyUsers.some(
              (u) => u.emailAddress == email
            );
            return (
              !userExistsInCurrCompany || "User already exists in the company"
            );
          } else {
            return true;
          }
        },
      ];
    },

    formIsValid() {
      if (this.isFormValid) {
        if (this.userIsCurrentUser && this.resettingPassword) {
          return this.passwordIsValid;
        }
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
