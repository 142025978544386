<template>
  <v-container fluid class="company-info">
    <span class="text-center"><h1 class="mb-6">Company Info</h1></span>
    <company-info />
  </v-container>
</template>

<script>
import CompanyInfo from "@/components/Account/Info/CompanyInfo.vue";

export default {
  components: { CompanyInfo },
};
</script>

<style scoped lang="scss">
.company-info {
  padding: 2rem 4rem;
}
</style>