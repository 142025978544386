<template>
  <v-form
    ref="applicantDemographicsFilters"
    lazy-validation
    v-model="filtersAreValid"
    class="mb-n7"
  >
    <v-row no-gutters justify="end">
      
      <v-col cols="12" md="4">
        <date-input v-model="filters.dates" />
      </v-col>
      <v-col cols="12" md="1">
        <v-btn
          @click="emitSubmitEvent"
          :disabled="!filtersAreValid"
          color="primary"
          large
          text
          v-if="$vuetify.breakpoint.xs"
          class="mobile-search-btn"
        >
          Search
        </v-btn>
        <v-btn
          @click="emitSubmitEvent"
          :disabled="!filtersAreValid"
          color="primary"
          fab
          small
          v-else
          class="my-5"
        >
          <v-icon>{{ icons.search }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import DateInput from "@/components/form/FromToDateInput";
import { mdiFileSearchOutline } from "@mdi/js";
import { mapGetters } from "vuex";

export default {
  components: { DateInput },
  model: {
    prop: "filters",
    event: "change",
  },
  props: {
    value: Object,
    filters: Object,
  },
  data() {
    return {
      filtersAreValid: true,
      icons: {
        search: mdiFileSearchOutline,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getSelectedCompany"]),
  },
  methods: {
    emitSubmitEvent() {
      this.$emit("submit");
    },
  },
};
</script>
<style scoped>
.mobile-search-btn {
  margin-bottom: 3rem;
}
</style>