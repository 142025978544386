import http from "@/api/employer-http";

const DO_NOTHING = () => {};

export default {
  getUsers(companyId, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get(
      `/api/users`,
      { companyId: companyId },
      {
        success,
        error,
      }
    );
  },
  getUser(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get(
      `/api/users`,
      { companyId: payload.companyId, userId: payload.userId },
      {
        success,
        error,
      }
    );
  },
  getUserByEmail(email, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get(
      `/api/users/byemail`, {email}, 
      {
        success,
        error,
      }
    );
  },
  getUserPermissions(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.get(
      `/api/users/userPermissions`,
      { companyId: payload.companyId, userId: payload.userId },
      {
        success,
        error,
      }
    );
  },
  toggleUserStatus(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.post(`/api/users/status`, payload, {
      success,
      error,
    });
  },
  addUser(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.post(`/api/users`, payload, {
      success,
      error,
    });
  },
  updateUser(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.put(`/api/users`, payload, {
      success,
      error,
    });
  },
  updateCompanyUser(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.put(`/api/users/companyuser`, payload, {
      success,
      error,
    });
  },
  sendConfirmationEmail(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
    return http.put(`/api/users/sendConfirmationEmail`, payload, {
      success,
      error,
    });
  },
};
