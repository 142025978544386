import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { isProduction } from "@/config/app-config";

const hotModules = [
  {
    name: "auth",
    path: "./modules/auth",
  },
  {
    name: "local-sites-iframe",
    path: "./modules/local-sites-iframe",
  },
  {
    name: "dashboard",
    path: "./modules/OutreachDashboard/dashboard",
  },
  {
    name: "organization-engagement-summary-data",
    path: "./modules/OutreachDashboard/organization-engagement-summary-data",
  },
  {
    name: "outreach-breakdown-data",
    path: "./modules/OutreachDashboard/outreach-breakdown-data",
  },
  {
    name: "outreach-pipeline-data",
    path: "./modules/OutreachDashboard/outreach-pipeline-data",
  },
  {
    name: "change-company",
    path: "./modules/modals/change-company",
  },
  {
    name: "document-center",
    path: "./modules/DocumentCenter/document-center",
  },
  {
    name: "job-performance",
    path: "./modules/Reports/job-performance",
  },
  {
    name: "job-screenshots-data",
    path: "./modules/DocumentCenter/job-screenshots-data",
  },
  {
    name: "orders",
    path: "./modules/Account/orders",
  },
  {
    name: "applicant-demographics",
    path: "./modules/Reports/applicant-demographics",
  },
  {
    name: "notification",
    path: "./modules/notification",
  },
  {
    name: "notification-preferences",
    path: "./modules/notificationpreferences",
  },
  {
    name: "abi-observances",
    path: "./modules/ABI/observances",
  },
  {
    name: "abi-content",
    path: "./modules/ABI/content",
  },
  {
    name: "app-messages",
    path: "./modules/app-messages",
  },
  {
    name: "users",
    path: "./modules/users",
  },
  {
    name: "user-stepper",
    path: "./modules/userstepper",
  },
  {
    name: "company-info",
    path: "./modules/company-info",
  },
];

const getModules = () =>
  hotModules.reduce((modules, hm) => {
    modules[hm.name] = require(`${hm.path}`).default;
    return modules;
  }, {});

Vue.use(Vuex);

//persisted states
const storeState = createPersistedState({
  paths: ["auth.selectedCompany", "auth.impersonating"],
});

const store = new Vuex.Store({
  modules: getModules(),
  strict: !isProduction,
  plugins: [storeState],
});

if (module.hot) {
  module.hot.accept(hotModules, () => {
    store.hotUpdate({
      modules: getModules(),
    });
  });
}

export default store;
