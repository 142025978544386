<template>
  <v-container class="container--fluid">
    <v-data-table
      :headers="headers"
      :items-per-page="25"
      item-key="jobId"
      :loading="getIsLoading"
      :footer-props="footerProps"
      :items="screenshots"
      :sort-by="['dateCreated']"
      :sort-desc="['dateCreated']"
      loading-text="Loading... Please wait"
      tabindex="0"
    >
      <template v-slot:[`item.jobId`]="item">
        <v-btn text @click="viewScreenshot(item)" color="primary">
          {{ item.value }}</v-btn
        >
      </template>
      <template v-slot:[`item.dateCreated`]="item">
        <span>{{ item.value | formatDateToLocaleString }} </span>
      </template>
      <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
        <span aria-live="polite" aria-atomic="true">{{pageStart}}-{{pageStop}} of {{itemsLength}}</span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import { mdiEye } from "@mdi/js";

export default {
  data: () => ({
    footerProps: { "items-per-page-options": [25, 50, 75, 100] },
    isValid: true,
    headers: [
      {
        text: "Job ID",
        align: "center",
        filterable: true,
        value: "jobId",
        width: "15%",
      },
      { text: "Title", value: "jobTitle", width: "60%" },
      { text: "Date Created", value: "dateCreated", width: "10%" },
    ],
    icons: {
      view: mdiEye,
    },
  }),
  mounted() {
      const sortableColumns = this.$el.querySelectorAll('th.sortable > span:not(.v-icon)');

      sortableColumns.forEach(col => {
        col.setAttribute('tabindex', '0')

        col.addEventListener('keyup', this.columnKeyUp)
      });
    },
  methods: {
    columnKeyUp(event) {
      if (event.key !== " " && event.key !== "Enter") return

      event.target.click()
    },
    ...mapActions("job-screenshots-data", ["retrieveScreenshotUrl"]),
    ...mapMutations("app-messages", ["displaySuccessMessage"]),
    viewScreenshot(item) {
      this.retrieveScreenshotUrl({ ScreenshotId: item.item.screenshotId }).then(
        (url) => {
          if (url) {
            window.open(url, "_blank").focus();
          }
        }
      );
    },
  },
  computed: {
    ...mapGetters("job-screenshots-data", ["getIsLoading", "getScreenshots"]),
    ...mapState("job-screenshots-data", ["screenshots"]),
  },
  filters: {
    formatDateToLocaleString(date) {
      return new Date(date).toLocaleDateString();
    },
  },
  watch: {
    screenshots() {
      this.displaySuccessMessage(`${this.screenshots.length} screenshots found.`);
    }
  }
};
</script>