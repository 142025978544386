//static industry types for form
const IndustryTypes = [
  { text: "Advertising/Marketing/PR", value: 15 },
  { text: "Aerospace/Military/Defense", value: 16 },
  { text: "Agriculture/Forestry/Fisheries", value: 17 },
  { text: "Architecture/Engineering/Construction", value: 18 },
  { text: "Business Consulting Services", value: 19 },
  { text: "Channel (VAR/Distributor/Sys Int.)", value: 20 },
  { text: "Computer/Networking Equipment Mfg.", value: 21 },
  { text: "Consultant (Non-Internet/Computer)", value: 22 },
  { text: "Education/Training", value: 23 },
  { text: "Entertainment (Film, Music, the Arts)", value: 24 },
  { text: "Finance/Banking/Accounting", value: 25 },
  { text: "Government", value: 26 },
  { text: "Healthcare/Pharmaceuticals/Medical", value: 27 },
  { text: "Hospitality/Travel", value: 28 },
  { text: "Insurance", value: 29 },
  { text: "Legal Services", value: 30 },
  { text: "Real Estate", value: 31 },
  { text: "ISP", value: 32 },
  { text: "Information Technology", value: 33 },
  { text: "Manufacturing/R&D (non-computer)", value: 34 },
  { text: "Media (Publishing, Broadcast, Cable)", value: 35 },
  { text: "Mining/Construction", value: 36 },
  { text: "Non-Profit", value: 37 },
  { text: "Software Developer/Publisher", value: 38 },
  { text: "Telecommunications", value: 39 },
  { text: "Transportation", value: 40 },
  { text: "Utilities/Petroleum/Gas/Energy", value: 41 },
  { text: "Web Hosting/Site Development", value: 42 },
  { text: "Web Site/Online Information Service", value: 43 },
  { text: "Wholesale/Retail Trade (non-computer)", value: 44 },
  { text: "Employment Services", value: 45 },
  { text: "Internet/E-Commerce", value: 46 },
  { text: "Environmental Consulting/Laboratories", value: 47 },
  { text: "Service Industry", value: 48 },
  { text: "Furniture Manufacturing/Wholesalers", value: 49 },
  { text: "Associations", value: 50 },
];
Object.freeze(IndustryTypes.sort((a, b) => (a.text > b.text ? 1 : -1)));
export default IndustryTypes;
