<script>
import { mapGetters } from "vuex";

import { oms_base_url } from "@/config/app-config.js";

export default {
  name: "outreach-management",
  data: () => ({
    messageChannel: undefined,
    isLoading: true,
    omsBaseUrl: oms_base_url,
  }),
  computed: {
    ...mapGetters("auth", ["getAccessToken", "getSelectedCompany", "getUserId", "getUser"]),
    appWindow: (vm) => vm.$refs.iframe.contentWindow,
    appRoute: (vm) => vm.$route.path.split(/\/outreach/)[1],
  },
  watch: {
    appRoute() {
      /* by setting its src only once, the iframe will only load once which ensures it will maintain its SPA functionality 
         but that means we need to "manually" inform OMS of any route changes */
      this.sendChannelMessage({
        type: "route_change",
        payload: this.appRoute,
      });
    },
    getSelectedCompany(oldCompany, newCompany) {
      if (this.isLoading == false) {
        this.sendChannelMessage({
          type: "selected_company_change",
          payload: newCompany.id
        });
      }
    },
  },
  methods: {
    onIframeLoad() {
      this.initializeMessageChannel();
      /* 'omsBaseUrl' will be interpreted as a route of '/' by OMS when first loaded, 
          so we need to send it the intended first route */
      this.sendChannelMessage({ type: "route_change", payload: this.appRoute });
      this.sendChannelMessage({ type: "selected_company_change", payload: this.getSelectedCompany.id })
      this.sendChannelMessage({ type: "user_id", payload: this.getUserId.toLowerCase() })
      this.sendChannelMessage({ type: "user_info", payload: this.getUser })
      this.isLoading = false;
    },
    initializeMessageChannel() {
      this.messageChannel = new MessageChannel();
      this.messageChannel.port1.onmessage = this.onChannelMessage;
      this.appWindow.postMessage({ type: "port_transfer" }, oms_base_url, [
        this.messageChannel.port2,
      ]);
    },
    sendChannelMessage(message) {
      this.messageChannel.port1.postMessage(message);
    },
    onChannelMessage(message) {
      switch (message.data.type) {
        case "access_token_request":
          return this.onAccessTokenRequestMessage(message);
        case "route_change":
          return this.onRouteChangeMessage(message);
      }
    },
    onAccessTokenRequestMessage() {
      this.sendChannelMessage({
        type: "access_token",
        payload: this.getAccessToken,
      });
    },
    onRouteChangeMessage(message) {
      const path = `/outreach${message.data.payload}`;
      if (path === "/outreach/") {
        /* any time an iframe src is set, it gets added as navigation in the host page's history
          if OMS sends '/' as a route change, the back button was clicked and the browser is navigating to when the iframe's src was first set
          in such a case, the user is actually trying to go back to the page before hitting an OMS page */
        this.$router.go(-1);
      } else if (path !== this.$route.path) {
        /* using 'replace' instead of 'push' here ensures that history is maintained when a navigation is initiated from within OMS */
        this.$router.replace(path);
      }
    },
  },
  mounted() {
    /* we don't want two scrollbars; we should let the iframe handle scrolling */
    document.querySelector("html").style.setProperty("overflow", "hidden");
  },
  destroyed() {
    document.querySelector("html").style.removeProperty("overflow");
  },
};
</script>

<template>
  <section class="outreach-wrapper">
    <v-progress-circular v-if="isLoading" indeterminate />
    <iframe v-show="!isLoading" :src="omsBaseUrl" ref="iframe" @load="onIframeLoad"></iframe>
  </section>
</template>

<style lang="scss">
.outreach-wrapper {
  height: 100%;

  .v-progress-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  iframe {
    min-height: 100%;
    width: 100%;
    border: none;
  }
}
</style>
