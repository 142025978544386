<style scoped lang="scss">
</style>

<template>
  <v-container>
    <v-dialog v-model="value" persistent max-width="30rem">
      <v-card>
        <v-card-title class="text-h5 justify-center"
          >Choose a Company</v-card-title
        >
        <v-card-text>
          <v-select
            v-model="companySelected"
            :items="getCompanies"
            item-text="name"
            item-value="legacyId"
            return-object
          >
          </v-select>
          <v-checkbox
            dense
            v-model="defaultCheckbox"
            :label="`Set as Default?`"
            class="my-0"
          ></v-checkbox>
          <div class="font-italic text-left">
            Your Default company will be selected when you log in.
          </div>
          <div v-if="getDefaultCompany" class="font-italic text-left">
            Current Default: <b>{{ getDefaultCompany.name }}</b>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="$emit('close')">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="handleCompanySelected()"
            :loading="isLoading"
            :disabled="isLoading"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChangeCompanyModal",
  props: {
    value: Boolean,
  },
  data() {
    return {
      isLoading: false,
      companySelected: undefined,
      selectedDefault: undefined,
      originalSelectedDefault: undefined,
    };
  },
  created() {
    this.companySelected = this.getSelectedCompany;

    if (this.getDefaultCompany) {
      this.selectedDefault = this.getDefaultCompany.id;
      this.originalSelectedDefault = this.selectedDefault;
    }
  },
  methods: {
    ...mapActions("change-company", [
      "updateDefaultCompany",
    ]),
    ...mapActions("auth", ["handleSelectCompany"]),
    async handleCompanySelected() {
      this.isLoading = true;
      await this.handleSelectCompany(this.companySelected);
      if (this.selectedDefault !== this.originalSelectedDefault)
        await this.updateDefaultCompany(this.selectedDefault);
      this.$emit("close");
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters("auth", [
      "getCompanies",
      "getSelectedCompany",
      "getDefaultCompany",
    ]),
    defaultCheckbox: {
      get() {
        return (
          this.selectedDefault &&
          this.companySelected.id == this.selectedDefault
        );
      },
      set(value) {
        if (value) {
          this.selectedDefault = this.companySelected.id;
        } else if (this.companySelected.id == this.selectedDefault) {
          this.selectedDefault = undefined;
        }
      },
    },
  },
};
</script>

