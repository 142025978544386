import http from '../employer-http';

const DO_NOTHING = () => {};

export default {
    postUserActivity(payload, { success = DO_NOTHING, error = DO_NOTHING }) {
        return http.post('/api/UserActivities', payload, {
            success,
            error
        });
    }
};
