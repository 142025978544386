<template>
  <v-dialog v-model="value" max-width="50rem" persistent>
    <v-stepper v-model="stage">
      <v-overlay :value="isLoading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <v-stepper-header>
        <v-stepper-step :complete="stage > 1" step="1">
          Enter Email
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="stage > 2" step="2">
          Enter User Details
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row class="mb-2">
            <v-col cols="9" offset="3">
              <v-form
                ref="emailForm"
                v-model="isEmailFormValid"
                @submit.prevent="handleSubmitEmailForm"
              >
                <v-text-field
                  class="email-textfield"
                  label="User Email"
                  v-model="user.email"
                  :rules="emailValidation"
                >
                </v-text-field>
              </v-form>
            </v-col>
          </v-row>

          <v-btn text @click="handleClose" color="red"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="handleEmailContinue"
            :disabled="!isEmailFormValid"
            :loading="isLoading"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div v-if="!addUserFailed">
            <v-row>
              <v-col>
                <user-form
                  :user="user"
                  :userExists="userToAddExists"
                  :stage="stage"
                  @close="closeStepper"
                  @enter="handleAdd"
                  @isFormValid="(value) => (isFormValid = value)"
                >
                </user-form>
              </v-col>
            </v-row>

            <v-btn text @click="handleClose" class="float-left" color="red">
              Cancel
            </v-btn>
            <span class="float-right">
              <v-btn text @click="handleBack"> Back </v-btn>
              <v-btn
                color="primary"
                :disabled="isAddDisabled"
                @click="handleAdd"
                :loading="isLoading"
              >
                Add User
              </v-btn>
            </span>
          </div>
          <div v-else>
            <p>
              Error occurred while adding user. Please contact Customer Service
              at
              <a href="mailto:customerservice@circaworks.com"
                >customerservice@circaworks.com</a
              >
              or <a href="tel:800-984-3775">800-984-3775</a>.
            </p>
            <v-btn text @click="handleClose" class="float-right" color="red">
              Close
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import UserForm from "@/components/Account/Users/Stepper/UserForm.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import userAPI from "@/api/users.js";
import { isProduction } from "@/config/app-config";

export default {
  components: { UserForm },

  props: {
    value: Boolean,
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        userRole: 3,
        accessJobs: false,
        accessResumes: false,
        accessApplicants: false,
        accessOFCCPAccountManagement: false,
        accessReports: false,
        editAllJobPostings: false,
        accessDiversitySourcing: false,
      },
      stepperUser: null,
      isFormValid: false,
      stage: 1,
      userToAddExists: false,
      isLoading: false,
      isEmailFormValid: false,
      addUserFailed: false,
    };
  },
  watch: {},
  computed: {
    isAddDisabled() {
      if (
        (this.isFormValid && !this.isLoading)
      ) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters("auth", ["usesSSO", ]),
    ...mapGetters("users", ["getUsers", "getData"]),

    emailValidation() {
      var pattern =
        '(([a-zA-Z0-9_.?"\\-]+)([a-zA-Z0-9<>(),;:+".+<>[]?\\]*)@((([0-9]{3}.[0-9]{3}.[0-9]{3}.[0-9]{3}){1})|(([a-zA-Z0-9.\\[\\]]+)([a-zA-Z0-9.\\[\\]-]*)([a-zA-Z0-9.\\[\\]]+)([.{1}])([a-zA-Z.\\[\\]-]+)))$)';
      const emailRegex = new RegExp(pattern);
      return [
        (email) => {
          return email.length > 0 || "Email is required";
        },
        (email) => {
          return email.length <= 80 || "Too many characters";
        },
        (email) => {
          return emailRegex.test(email) || "Email address is invalid";
        },
        (email) => {
          var temp = email.toLocaleLowerCase().toString();

          if (
            temp.includes("..") ||
            temp.includes(".@") ||
            temp.startsWith(".") ||
            temp.startsWith("?") ||
            temp.endsWith(".web") ||
            temp.indexOf(" ") != -1
          ) {
            return "Email address is invalid";
          }
          return true;
        },
        (email) => {
          if (emailRegex.test(email)) {
            const userExistsInCurrCompany = this.getUsers.some(
              (u) => u.emailAddress == email
            );
            return (
              !userExistsInCurrCompany || "User already exists in the company"
            );
          } else {
            return true;
          }
        },
      ];
    },
  },

  methods: {
    ...mapMutations("user-stepper", [
      "setStage",
      "setStepperUser",
      "setLoading",
    ]),
    ...mapActions("user-stepper", ["fetchAndSetUserByEmail"]),
    ...mapActions("users", ["addNewUser", "setAddUserFailed"]),

    closeStepper() {
      this.$emit("close");
      this.stage = 1;
      this.user.id = "";
      this.user.firstName = "";
      this.user.lastName = "";
      this.user.email = "";
      this.user.userRole = 3;
      this.userToAddExists = false;
      this.isEmailFormValid = false;
      this.stepperUser = null;
      this.formIsValid = false;
      this.$refs.emailForm.resetValidation();
    },

    reset(clickedBack) {
      this.user.firstName = "";
      this.user.lastName = "";
      if (!clickedBack) {
        this.user.email = "";
      }
      this.user.userRole = 3;
      this.user.accessJobs = false;
      this.user.accessResumes = false;
      this.user.accessApplicants = false;
      this.user.accessOFCCPAccountManagement = false;
      this.user.accessReports = false;
      this.user.editAllJobPostings = false;
      this.user.accessDiversitySourcing = false;
    },

    async handleAdd() {
      if (
        (this.isFormValid && !this.isLoading) ||
        (!this.isLoading && this.userToAddExists) ||
        (this.usesSSO && this.isFormValid && !this.isLoading)
      ) {
        this.isLoading = true;
        try {
          if (await this.addNewUser(this.user)) {
            this.$parent.loadUsers();
            this.handleClose();
          }
        } catch (error) {
          console.error(error);
          this.addUserFailed = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleBack() {
      this.reset(true);
      this.stage = 1;
    },
    handleClose() {
      this.reset(false);
      this.closeStepper();
    },
    async handleEmailContinue() {
      this.isLoading = true;
      if (this.user.email.length > 0) {
        const userExistsInCurrCompany = this.getUsers.some(
          (u) => u.emailAddress == this.user.email
        );
        if (!userExistsInCurrCompany) {
          await userAPI.getUserByEmail(this.user.email, {
            success: (data) => {
              if (data) {
                this.user.firstName = data.firstName;
                this.user.lastName = data.lastName;
                this.userToAddExists = true;
              }

              this.stage = 2;
              this.isLoading = false;
            },
            error: (error) => {
              if (!isProduction) {
                console.log(error);
              }

              this.loading = true;
            },
          });
        }
      } else {
        this.isEmailFormValid = false;
      }
    },

    handleSubmitEmailForm() {
      if (this.isEmailFormValid) {
        this.handleEmailContinue();
      }
    },
    handleSubmitForm() {
      if (!this.isAddDisabled) {
        this.handleAdd();
      }
    },
  },
};
</script>

<style>
.email-textfield {
  max-width: 24em;
}
</style>
