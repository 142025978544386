<template>
  <v-container class="container--fluid observances">
    <span class="text-center"><h1>Observances</h1></span>
    <observances-filter class="observances-filter"></observances-filter>
    <observances-list></observances-list>
  </v-container>
</template>
<script>
import ObservancesFilter from "@/components/ABI/Observances/ObservancesFilter.vue";
import ObservancesList from "@/components/ABI/Observances/ObservancesList.vue";
import { mapActions } from "vuex";

export default {
  props: ["selectedObservanceId"],
  components: { ObservancesFilter, ObservancesList},
  data: () => {
    const currentDate = new Date();
    return {
      datePickerFilter: {
        fromDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1) //first day of current month
          .toISOString()
          .substr(0, 10),
        toDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1, //last day of current month
          0
        )
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  async created() {
    if(this.selectedObservanceId) {
      await this.fetchSelectedObservance(this.selectedObservanceId);
    }
  },
  methods : {
    ...mapActions("abi-observances", [
      "fetchSelectedObservance",
    ]),
  }
};
</script>
<style scoped lang="scss">
.observances {
  padding: 2rem 2rem;
}
.observances-filter {
  padding: .25rem 0 0 0;
}
</style>