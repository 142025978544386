
<template>
  <div>Redirecting</div>
</template>

<script>
import { mapActions } from "vuex";

// @ is an alias to /src

export default {
  name: "SilentRefresh",
  created() {
    this.completeSignInRefresh();
  },
  methods: {
    ...mapActions("auth", ["completeSignInRefresh"]),
  },
};
</script>
